import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid, Typography} from "@mui/material";
import BooleanComponent from "../../comp/BooleanComponent";
import RangeComponent from "../../comp/RangeComponent";


const RangeTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h6" gutterBottom>
                        Range system setting
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Enable defense training"
                    jsonName="rangeEnableDefenseTraining"
                    defaultValue={settings.rangeEnableDefenseTraining}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Buy all item at one time"
                    jsonName="rangeBuyAllItemAtOneTime"
                    defaultValue={settings.rangeBuyAllItemAtOneTime}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Range time (seconds)"
                    jsonName="rangeTimeRange"
                    defaultValue={settings.rangeTimeRange}
                    changeState={updateSetting}
                />
            </Grid>

            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h6" gutterBottom>
                    </Typography>
                </Grid>
            </Grid>

            <Grid item>
                <RangeComponent
                    label="Food range"
                    jsonName="rangeFoodRange"
                    defaultValue={settings.rangeFoodRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Iron arrow range"
                    jsonName="rangeIronArrowRange"
                    defaultValue={settings.rangeIronArrowRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Steel arrow range"
                    jsonName="rangeSteelArrowRange"
                    defaultValue={settings.rangeSteelArrowRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Mithril arrow range"
                    jsonName="rangeMithrilArrowRange"
                    defaultValue={settings.rangeMithrilArrowRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Bury bones"
                    jsonName="rangeEnableBuryBones"
                    defaultValue={settings.rangeEnableBuryBones}
                    changeState={updateSetting}
                />
            </Grid>



        </Grid>
    )
}

export default RangeTab;
