import {
    FETCH_NOTES_API_ENDED,
    FETCH_NOTES_FAILURE,
    FETCH_NOTES_STARTED,
    FETCH_NOTES_SUCCESS,
    UPDATE_NOTE_TEXT
} from "./actions";
import {UPDATE_MANAGER_STATE} from "../manager/actions";
import {NoteDTO} from "../manager/types";

export type NoteState = {
    loading: boolean,
    error: boolean | null,
    notes: NoteDTO[] | []
}


const initialState: NoteState = {
    loading: false,
    error: null,
    notes: []
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_NOTES_STARTED:
            return {
                ...state,
                loading: true
            };
        case FETCH_NOTES_SUCCESS:
            return {
                ...state,
                loading: false,
                notes: action.payload,
            };
        case FETCH_NOTES_API_ENDED:
            return {
                ...state,
                loading: false
            }
        case FETCH_NOTES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case UPDATE_MANAGER_STATE:
            return {
                ...state,
                [action.payload.property]: action.payload.value
            };
        case UPDATE_NOTE_TEXT:
            return {
                ...state,
                notes: state.notes.map((note) => {
                    if (note.noteKey === action.payload.noteKey) {
                        return {...note, text: action.payload.text};
                    }
                    return note;
                }),
            };
        default:
            return state;
    }
};

export default reducer;