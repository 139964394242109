import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import ReactGA from "react-ga4";

const activateTracking =
    !window.location.hostname.includes("localhost") &&
    !window.location.hostname.includes("dev.infinityfarmer.com");

if (activateTracking) {
    // Initialize GA
    ReactGA.initialize("G-7H90XYJ7XD");
}

function GoogleAnalyticsManager() {
    const location = useLocation();

    useEffect(() => {
        if (activateTracking) {
            // Send the page view
            ReactGA.send({
                hitType: "pageview",
                page: location.pathname,
            });
        }
    }, [location]);  // Dependency array with location - will trigger on every route change

    return null;  // This component doesn't render anything
}

export default GoogleAnalyticsManager;