import {useState} from "react";
import {ActionPlanDTO} from "../../../store/manager/types";

export const useTableSelectAll = () => {

    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelect = (key: string) => {
        if (selectedRows.includes(key)) {
            setSelectedRows(selectedRows.filter((row) => row !== key));
        } else {
            setSelectedRows([...selectedRows, key]);
        }
        setSelectAll(false);
    };

    const handleSelectAllClicked = (searchFilteredActionPlans: ActionPlanDTO[]) => {
        if (selectAll) {
            setSelectedRows([])
        } else {
            setSelectedRows(searchFilteredActionPlans.map((item) => item.actionPlanKey))
        }
        setSelectAll(!selectAll);
    }

    return {
        handleSelect,
        handleSelectAllClicked,
        selectedRows,
        setSelectedRows,
        selectAll,
        setSelectAll,
    }
}