import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import RangeComponent from "../../comp/RangeComponent";


const FishingTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <RangeComponent
                    label="Feather range"
                    jsonName="fishingFeatherRange"
                    defaultValue={settings.fishingFeatherRange}
                    changeState={updateSetting}
                />
            </Grid>

            <Grid item>
                <RangeComponent
                    label="Fishing level(seconds) "
                    jsonName="fishingTimeRange"
                    defaultValue={settings.fishingTimeRange}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default FishingTab;
