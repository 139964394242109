import * as React from 'react';
import {PlayerTrackerDTO, QuestEnum} from "../../store/manager/types";
import {Box, Card, CircularProgress, Grid, List, ListItem, Modal, styled, Typography} from "@mui/material";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};

const StyledIcon = styled('img')({
    width: '16px',
    margin: '3px 2px',
    height: '16px',
});


function getIconData(player?: PlayerTrackerDTO) {
    let total = 0;

    // Summing up each skill value
    total += player?.attackLevel || 0;
    total += player?.strengthLevel || 0;
    total += player?.defenseLevel || 0;
    total += player?.rangeLevel || 0;
    total += player?.magicLevel || 0;
    total += player?.firemakingLevel || 0;
    total += player?.hitpointsLevel || 0;
    total += player?.agilityLevel || 0;
    total += player?.herbloreLevel || 0;
    total += player?.thievingLevel || 0;
    total += player?.craftingLevel || 0;
    total += player?.woodcuttingLevel || 0;
    total += player?.hunterLevel || 0;
    total += player?.miningLevel || 0;
    total += player?.smithingLevel || 0;
    total += player?.fishingLevel || 0;
    total += player?.cookingLevel || 0;
    total += player?.farmingLevel || 0;
    total += player?.runecraftingLevel || 0;
    total += player?.constructionLevel || 0;
    total += player?.prayerLevel || 0;
    total += player?.fletchingLevel || 0;
    total += player?.slayerLevel || 0;

    return [
        {
            iconimage: "/assets/icons/skill_icon_Attack.png",
            values: player?.attackLevel || 0,
            exp: player?.attackExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Strength.png",
            values: player?.strengthLevel || 0,
            exp: player?.strengthExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Defence.png",
            values: player?.defenseLevel || 0,
            exp: player?.defenseExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Ranged.png",
            values: player?.rangeLevel || 0,
            exp: player?.rangeExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Magic.png",
            values: player?.magicLevel || 0,
            exp: player?.magicExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Firemaking.png",
            values: player?.firemakingLevel || 0,
            exp: player?.firemakingExp || 0
        },
        // 2nd row
        {
            iconimage: "/assets/icons/skill_icon_Hitpoints.png",
            values: player?.hitpointsLevel || 0,
            exp: player?.hitpointsExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Agility.png",
            values: player?.agilityLevel || 0,
            exp: player?.agilityExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Herblore.png",
            values: player?.herbloreLevel || 0,
            exp: player?.herbloreExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Thieving.png",
            values: player?.thievingLevel || 0,
            exp: player?.thievingExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Crafting.png",
            values: player?.craftingLevel || 0,
            exp: player?.craftingExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Woodcutting.png",
            values: player?.woodcuttingLevel || 0,
            exp: player?.woodcuttingExp || 0
        },
        // 3rd row
        {
            iconimage: "/assets/icons/skill_icon_Hunter.png",
            values: player?.hunterLevel || 0,
            exp: player?.hunterExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Mining.png",
            values: player?.miningLevel || 0,
            exp: player?.miningExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Smithing.png",
            values: player?.smithingLevel || 0,
            exp: player?.smithingExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Fishing.png",
            values: player?.fishingLevel || 0,
            exp: player?.fishingExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Cooking.png",
            values: player?.cookingLevel || 0,
            exp: player?.cookingExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Farming.png",
            values: player?.farmingLevel || 0,
            exp: player?.farmingExp || 0
        },
        // 4th
        {
            iconimage: "/assets/icons/skill_icon_Runecraft.png",
            values: player?.runecraftingLevel || 0,
            exp: player?.runecraftingExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Construction.png",
            values: player?.constructionLevel || 0,
            exp: player?.constructionExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Prayer.png",
            values: player?.prayerLevel || 0,
            exp: player?.prayerExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Fletching.png",
            values: player?.fletchingLevel || 0,
            exp: player?.fletchingExp || 0
        },
        {
            iconimage: "/assets/icons/skill_icon_Slayer.png",
            values: player?.slayerLevel || 0,
            exp: player?.slayerExp || 0
        },
        {
            iconimage: "/assets/icons/Quests_Points.webp",
            values: player?.questPoints || 0,
            exp: -1

        },
        {
            iconimage: "/assets/icons/Stats_icon.webp",
            values: total,
            exp: -1
        },
    ]
};

type Props = {
    open: boolean,
    closeModal: () => void,
    playerTrackerDTO: PlayerTrackerDTO | undefined
}

const PlayerTrackerModal = (props: Props) => {
    const {open, closeModal, playerTrackerDTO} = props;

    type Props = {
        quests?: QuestEnum[];
    }

    const QuestList: React.FC<Props> = ({quests = []}) => {
        if (quests.length === 0) {
            return (
                <List style={{border: '1px solid black', maxHeight: '128px', overflow: 'auto'}}>
                    <ListItem style={{borderBottom: '1px solid black'}}>
                        <Typography>No quests completed</Typography>
                    </ListItem>
                </List>
            );
        }

        return (
            <List style={{border: '1px solid black', maxHeight: '128px', overflow: 'auto'}}>
                {quests.map((quest, index) => (
                    <ListItem key={index} style={{padding: "0px"}}>
                        {quest}
                    </ListItem>
                ))}
            </List>
        );
    }

    function getMinutesSinceModified(modifiedDateString?: string): number | null {
        if (!modifiedDateString) {
            return null;
        }

        // Ensure the date string is in UTC
        const utcString = modifiedDateString + 'Z';
        const modifiedDate = new Date(utcString);

        // Validate if the date was successfully parsed
        if (isNaN(modifiedDate.getTime())) {
            return null;
        }

        const now = new Date();
        const differenceInMilliseconds = now.getTime() - modifiedDate.getTime();
        return Math.floor(differenceInMilliseconds / 60000);
    }

    function xpProgressPercentage(xpValues: number[], currentXp: number): number {
        let currentLevel = 0;

        for (let i = 0; i < xpValues.length; i++) {
            if (currentXp < xpValues[i]) {
                currentLevel = i;
                break;
            }
        }

        if (currentLevel === 0) {
            // Adjust this based on your needs, either return a default value or handle it outside this function
            return 100;
        }

        const lowerBound = xpValues[currentLevel - 1];
        const upperBound = xpValues[currentLevel];

        return ((currentXp - lowerBound) / (upperBound - lowerBound)) * 100;
    }

    const xpValues: number[] = [0, 83, 174, 276, 388, 512, 650, 801, 969, 1154, 1358, 1584,
        1833, 2107, 2411, 2746, 3115, 3523, 3973, 4470, 5018, 5624, 6291, 7028, 7842, 8740,
        9730, 10824, 12031, 13363, 14833, 16456, 18247, 20224, 22406, 24815, 27473, 30408,
        33648, 37224, 41171, 45529, 50339, 55649, 61512, 67983, 75127, 83014, 91721, 101333,
        111945, 123660, 136594, 150872, 166636, 184040, 203254, 224466, 247886, 273742, 302288,
        333804, 368599, 407015, 449428, 496254, 547953, 605032, 668051, 737627, 814445, 899257,
        992895, 1096278, 1210421, 1336443, 1475581, 1629200, 1798808, 1986068, 2192818, 2421087,
        2673114, 2951373, 3258594, 3597792, 3972294, 4385776, 4842295, 5346332, 5902831, 6517253,
        7195629, 7944614, 8771558, 9684577, 10692629, 11805606, 13034431];


    return (
        <>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        <Grid
                            container
                            columns={{xs: 6, sm: 6, md: 12}}
                            style={{textAlign: "center", marginTop: "-14px"}}
                        >
                            {getIconData(playerTrackerDTO).map((item, index) => {
                                return (
                                    <Grid style={{
                                        display: "flex",
                                        paddingTop: "10px",
                                        gap: "3px",
                                        alignItems: 'center'
                                    }} item xs={1} md={2} key={index}>
                                        <StyledIcon
                                            alt={item.values.toString()}
                                            src={item.iconimage}
                                        />
                                        <Typography sx={{marginLeft: "3px"}}>
                                            <b>{item.values}</b>
                                        </Typography>
                                        {item.exp != -1 &&
                                            <Box sx={{position: 'relative', display: 'inline-flex'}}>
                                                <CircularProgress variant="determinate"
                                                                  value={xpProgressPercentage(xpValues, item.exp)}
                                                                  size="30px"/>
                                                <Box
                                                    sx={{
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                        position: 'absolute',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="caption"
                                                        component="div"
                                                        color="text.secondary"
                                                    >{`${Math.round(xpProgressPercentage(xpValues, item.exp))}%`}</Typography>
                                                </Box>
                                            </Box>
                                        }
                                    </Grid>
                                );
                            })}

                        </Grid>
                    </Box>
                    <Typography>
                        Quests
                    </Typography>

                    <QuestList quests={playerTrackerDTO?.quests}/>
                    <Box>
                        {playerTrackerDTO && playerTrackerDTO.modifiedDate ?
                            `Last checkin time ${getMinutesSinceModified(playerTrackerDTO.modifiedDate) ?? "unknown time"} minutes ago`
                            : null}
                    </Box>

                </Box>
            </Modal>
        </>
    )
}

export default PlayerTrackerModal;