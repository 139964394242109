import axios from "axios";


const baseUrl_Production = "https://infinityfarmer.com/mybotfarm";
const baseUrl_Develop = "https://dev.infinityfarmer.com/mybotfarm";

const baseURL =
    window.location.hostname.includes("dev.infinityfarmer.com") ||
    window.location.hostname.includes("localhost")
        ? baseUrl_Develop
        : baseUrl_Production;

const instance = axios.create({
    baseURL: baseURL,
    // baseURL: "http://localhost:8080/mybotfarm",
    timeout: 30000,
});

export {instance as default};
