import {removeItem, setItem} from "../../utils/localStorage";
import {Dispatch} from "redux";
import {makeRequest} from "../utils/api";

export const FETCH_LOGIN_STARTED = 'FETCH_LOGIN_STARTED';
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS';
export const FETCH_LOGIN_FAILURE = 'FETCH_LOGIN_FAILURE';

export const fetchLoginStarted = () => ({
    type: FETCH_LOGIN_STARTED,
});

export const fetchLoginSuccess = (login: LoginResponse) => ({
    type: FETCH_LOGIN_SUCCESS,
    payload: login,
});

export const fetchLoginFailure = () => ({
    type: FETCH_LOGIN_FAILURE
});

const payload = (apiFarmKey: string) => {
    return ({
        apiFarmKey: apiFarmKey
    })
};

type LoginResponse = {
    success: boolean
}

export const validateLoginApi = (apiFarmKey: string): any => {
    return async (dispatch: Dispatch) => {
        removeItem("apiFarmKey");
        dispatch(fetchLoginStarted());
        try {
            const response = await makeRequest("post", "/api/v1/farm/validate", payload(apiFarmKey));
            if (response.data.success === false) {
                dispatch(fetchLoginFailure());
                return;
            }
            setItem("apiFarmKey", apiFarmKey);
            dispatch(fetchLoginSuccess(response.data));
        } catch (error) {
            dispatch(fetchLoginFailure());
        }
    };
};