import React from 'react';
import {FormLabel, Box, Switch} from '@mui/material';
import {Settings} from "../../hooks/settingContext";

interface BooleanComponentProps {
    label: string;
    jsonName: keyof Settings;
    defaultValue: boolean; // Changed to boolean
    changeState: (name: string, value: boolean) => void; // Change value type to boolean
}

const BooleanComponent: React.FC<BooleanComponentProps> = ({label, jsonName, defaultValue, changeState}) => {

    const handleReflectionChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        changeState(jsonName, checked);
    };

    return (

        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="10px"
        >
            <FormLabel id="local-label"
                       style={{fontSize: '15px', color: 'black', fontWeight: 'normal'}}>{label}</FormLabel>
            <Switch
                checked={defaultValue}
                onChange={handleReflectionChange}
                name={jsonName}
                inputProps={{'aria-label': 'Reflection Switch'}}
            />
        </Box>
    );
};

export default BooleanComponent;
