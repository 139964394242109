import * as React from 'react';
import {ReactNode, useContext} from 'react';
import {Box, Container, Typography} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import {SettingContext} from "../hooks/settingContext";
import LevelTab from "../minitabs/levels";
import SystemTab from "../minitabs/system";
import CraftingTab from "../minitabs/crafting";
import FarmingTab from "../minitabs/farming";
import MiningTab from "../minitabs/mining";
import CookingTab from "../minitabs/cooking";
import FishingTab from "../minitabs/fishing";
import FiremakingTab from "../minitabs/firemaking";
import WoodcuttingTab from "../minitabs/woodcutting";
import BreakingTab from "../minitabs/breaking";
import QuestTab from "../minitabs/quests";
import ThievingTab from "../minitabs/thieving";
import MeleeTab from "../minitabs/combat";
import RangeTab from "../minitabs/range";


interface TabPanelProps {
    children: ReactNode;
    value: number;
    index: number;

    [key: string]: any;
}


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,

    };
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 1}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </Box>
    );
}


const P2pAioEditor = () => {
    const [tabLocation, setTabLocation] = React.useState(0);
    const {settings, saveSettings, updateSetting} = useContext(SettingContext);


    const handleChange = (event: any, newValue: number) => {
        setTabLocation(newValue);
    };

    return (
        <Container sx={{p: 4}}>
            <Box display="flex" gap="20px" flexDirection="column">
                <Button variant="contained" color="primary" fullWidth onClick={saveSettings}>
                    Save
                </Button>
                <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                    <Tabs
                        textColor="inherit"
                        // variant="fullWidth"
                        value={tabLocation}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{sx: {display: 'none'}}}
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                flexWrap: 'wrap',
                            },
                            '& .MuiTab-root.Mui-selected': { // Targeting the selected tab
                                bgcolor: 'primary.dark', // Or any color you prefer for the background
                                color: 'white', // If you want a lighter text on a dark background
                                // Add any other styles you want for the selected tab
                            },
                        }}
                    >
                        <Tab label="Levels" {...a11yProps(0)} />
                        <Tab label="System" {...a11yProps(1)} />
                        <Tab label="Breaking" {...a11yProps(2)} />
                        <Tab label="Crafting" {...a11yProps(3)} />
                        <Tab label="Farming" {...a11yProps(4)} />
                        <Tab label="Mining" {...a11yProps(5)} />
                        <Tab label="Cooking" {...a11yProps(6)} />
                        <Tab label="Fishing" {...a11yProps(7)} />
                        <Tab label="Firemaking" {...a11yProps(8)} />
                        <Tab label="Melee" {...a11yProps(9)} />
                        <Tab label="Woodcutting" {...a11yProps(10)} />
                        <Tab label="Quests" {...a11yProps(11)} />
                        <Tab label="Thieving" {...a11yProps(12)} />
                        <Tab label="Range" {...a11yProps(13)} />


                    </Tabs>
                </Box>

                <TabPanel value={tabLocation} index={0}>
                    <LevelTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={1}>
                    <SystemTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={2}>
                    <BreakingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={3}>
                    <CraftingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={4}>
                    <FarmingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={5}>
                    <MiningTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={6}>
                    <CookingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={7}>
                    <FishingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={8}>
                    <FiremakingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={9}>
                    <MeleeTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={10}>
                    <WoodcuttingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={11}>
                    <QuestTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={12}>
                    <ThievingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={13}>
                    <RangeTab/>
                </TabPanel>
            </Box>
        </Container>

    );

}

export default P2pAioEditor;