import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../store/reducers";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Paper,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {ActionPlanDTO, BulkActionPlanDTO, PlanDTO, ProxyDTO} from "../../../../store/manager/types";
import {ActionPlanContext} from "../hooks/actionPlanContext";
import {makeRequest} from "../../../../store/utils/api";
import {getAllActionPlans, getAllProxys} from "../../../../store/manager/actions";
import {toast} from "react-toastify";

type LocalState = {
    reflection: boolean,
    fps: number,
    isMirror: boolean,
    lowCpu: boolean,
    lowResource: boolean,
    memory: number,
    newMouse: boolean,
    noInterface: boolean,
    noRandoms: boolean,
    noRender: boolean,
    world: number,
    randomF2pWorld: boolean,
    randomP2pWorld: boolean,
};


const ActionPlanBulkChange = () => {
    const dispatch = useDispatch();

    const [planEnabled, setPlanEnabled] = useState<boolean>(false);
    const [plan, setPlan] = useState<PlanDTO | null>(null);
    const [proxyEnabled, setProxyEnabled] = useState<boolean>(false);
    const [proxyChainingEnabled, setProxyChainingEnabled] = useState<boolean>(false);
    const [proxy, setProxy] = useState<ProxyDTO | null>(null);
    const [proxyChainingAmount, setProxyChainingAmount] = useState<number>(1);
    const [otherLaunchAttributesEnabled, setOtherLaunchAttributesEnabled] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);


    const [tempProxyList, setTempProxyList] = useState<ProxyDTO[] | []>([]);


    const [state, setState] = React.useState<LocalState>({
        reflection: false,
        fps: 20,
        isMirror: false,
        lowCpu: false,
        lowResource: false,
        memory: 512,
        newMouse: false,
        noInterface: false,
        noRandoms: false,
        noRender: false,
        world: 336,
        randomF2pWorld: false,
        randomP2pWorld: false,
    });

    const {planList, proxyList, managerKey} = useSelector((state: State) => ({
        planList: state.manager.plans,
        proxyList: state.manager.proxys,
        managerKey: state.manager.managerKey,
    }))

    const context = useContext(ActionPlanContext);
    const {bulkUpdateActionPlans} = context;


    const handlePlanChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPlanEnabled(event.target.checked);
    };

    const handleProxyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProxyEnabled(event.target.checked);
    };
    const handleProxyChainingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProxyChainingEnabled(event.target.checked);
    };

    const handleOtherLaunchAttributesEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOtherLaunchAttributesEnabled(event.target.checked);
    };

    const handleSaveNonChaining = () => {
        const tempActionPlans: ActionPlanDTO[] = bulkUpdateActionPlans.map(i => {
            let item: ActionPlanDTO = {...i};  // Allow all properties to be optional

            if (planEnabled) {
                if (plan) {
                    item.plan = plan;
                } else {
                    const {plan, ...rest} = item; // plan is extracted and not included in rest
                    item = rest;
                }
            }

            if (proxyEnabled) {
                if (proxy) {
                    item.proxy = proxy;
                } else {
                    const {proxy, ...rest} = item; // plan is extracted and not included in rest
                    item = rest;
                }
            }

            if (otherLaunchAttributesEnabled) {
                item = {...item, ...state}
            }


            return item as ActionPlanDTO;
        });

        const bulkUpdateDTO: BulkActionPlanDTO = {
            actionPlans: tempActionPlans
        }

        handleSubmit(bulkUpdateDTO);
    }

    const handleSaveChaining = () => {
        const tempActionPlans: ActionPlanDTO[] = bulkUpdateActionPlans.map(i => {
            let item: ActionPlanDTO = {...i};  // Allow all properties to be optional

            if (planEnabled) {
                if (plan) {
                    item.plan = plan;
                } else {
                    const {plan, ...rest} = item; // plan is extracted and not included in rest
                    item = rest;
                }
            }

            if (otherLaunchAttributesEnabled) {
                item = {...item, ...state}
            }


            return item as ActionPlanDTO;
        });

        const bulkUpdateDTO: BulkActionPlanDTO = {
            actionPlans: tempActionPlans,
            proxys: tempProxyList,
            numberOfAccountPerProxy: proxyChainingAmount,
        }


        handleSubmit(bulkUpdateDTO);

    };

    const changeState = (key: string, value: any) => {
        setState(prevState => ({
            ...prevState,
            [key]: value,
        }));
    };


    const handleSubmit = async (action: BulkActionPlanDTO) => {
        dispatch({type: 'FETCH_DATA_START'});
        const response =
            await makeRequest("put",
                "/api/v1/actionplan/bulk",
                action
            );
        if (response.status !== 200) {
            dispatch({type: 'FETCH_DATA_ERROR'});
            return
        } else {
            dispatch(getAllActionPlans(managerKey));
            dispatch(getAllProxys(managerKey))
        }
        dispatch({type: 'FETCH_DATA_END'});

        toast.success("Successfully updated action plan!", {
            position: toast.POSITION.TOP_CENTER
        });
    }

    useEffect(() => {
        if (!proxyChainingEnabled && tempProxyList.length !== 0) {
            setTempProxyList([])
        }
    }, [proxyChainingEnabled, tempProxyList])

    useEffect(() => {
        if (tempProxyList.length > 0) {
            // Check if the proxyKeys match and update totalConnections
            const updatedTempProxyList = tempProxyList.map((tempProxy) => {
                const matchingProxy = proxyList.find((proxy) => proxy.proxyKey === tempProxy.proxyKey);
                if (matchingProxy) {
                    return {
                        ...tempProxy,
                        totalConnections: matchingProxy.totalConnections
                    };
                }
                return tempProxy;
            });

            // Update tempProxyList with the updatedTempProxyList
            setTempProxyList(updatedTempProxyList);
        }
    }, [proxyList]);


    return (
        <Container sx={{p: {xs: 0, md: 4}}}>
            <Typography
                sx={{marginTop: {xs: "1rem", md: "0rem"}}}
                variant="h6"
                textAlign={"center"}
                mb={2}
            >
                Bulk Change
            </Typography>
            <Container component={Paper} sx={{p: 4}}>
                <FormControlLabel
                    control={<Switch checked={planEnabled} onChange={handlePlanChange}/>}
                    label={planEnabled ? "Plan Enabled" : "Plan Disabled"}
                />
                <FormControl sx={{width: '100%'}}>
                    <Autocomplete
                        id='plan-autocomplete'
                        options={planList}
                        getOptionLabel={(option) => option.name}
                        value={plan}
                        onChange={(event, newValue) => {
                            setPlan(newValue)
                            // Your onChange code for the newValue
                        }}
                        isOptionEqualToValue={(option, value) => option.planKey === value.planKey} // Add this prop
                        renderInput={(params) => <TextField {...params} label='Plan'/>}
                        disabled={!planEnabled}
                    />
                </FormControl>

                <FormControlLabel
                    control={<Switch checked={proxyEnabled} onChange={handleProxyChange}/>}
                    label={proxyEnabled ? "Proxy Enabled" : "Proxy Disabled"}
                />
                <FormControlLabel
                    control={<Switch checked={proxyChainingEnabled} onChange={handleProxyChainingChange}/>}
                    disabled={!proxyEnabled}
                    label={proxyChainingEnabled ? "Proxy chaining Enabled" : "Proxy chaining Disabled"}
                />
                <FormControl sx={{width: '100%'}}>
                    {proxyChainingEnabled &&
                        <Autocomplete
                            id='plan-autocomplete'
                            options={proxyList}
                            getOptionLabel={(option) => option.alias + " - " + option.totalConnections}
                            value={tempProxyList}
                            onChange={(event, newValue) => {
                                if (Array.isArray(newValue)) {
                                    setTempProxyList(newValue);
                                } else if (newValue !== null) {
                                    setTempProxyList([newValue]);
                                } else {
                                    setTempProxyList([]);
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option.proxyKey === value.proxyKey} // Add this prop
                            renderInput={(params) => <TextField {...params} label='Proxy'/>}
                            disabled={!proxyEnabled}
                            multiple={proxyChainingEnabled}
                            onOpen={() => setOpen(true)}
                            onBlur={() => setOpen(false)}
                            open={open}
                        />
                    }
                    {!proxyChainingEnabled &&
                        <Autocomplete
                            id='plan-autocomplete'
                            options={proxyList}
                            getOptionLabel={(option) => option.alias + " - " + option.totalConnections}
                            value={proxy}
                            onChange={(event, newValue) => {
                                setProxy(newValue);
                            }}
                            isOptionEqualToValue={(option, value) => option.proxyKey === value.proxyKey} // Add this prop
                            renderInput={(params) => <TextField {...params} label='Proxy'/>}
                            disabled={!proxyEnabled}
                        />
                    }
                </FormControl>
                <TextField
                    sx={{width: "100%", marginTop: "8px"}}
                    type="number"
                    name="Action plans per proxy"
                    id="proxyChainingAmount"
                    label="Action plans per proxy"
                    disabled={!proxyChainingEnabled}
                    variant="standard"
                    value={proxyChainingAmount}
                    onChange={(e) => setProxyChainingAmount(Number(e.target.value))}
                />

                <FormControlLabel
                    sx={{marginTop: "8px"}}
                    control={<Switch checked={otherLaunchAttributesEnabled}
                                     onChange={handleOtherLaunchAttributesEnabledChange}/>}
                    label={otherLaunchAttributesEnabled ? "Other attributes Enabled" : "Other attributes Disabled"}
                />


                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                        gap: "15px",
                    }}
                >
                    <Box sx={{width: "50%"}}>
                        <FormLabel id="local-label">reflection</FormLabel>
                        <Switch
                            inputProps={{"aria-label": "controlled"}}
                            checked={state.reflection}
                            disabled={!otherLaunchAttributesEnabled}
                            name="reflection"
                            onChange={(e) => changeState(e.target.name, e.target.checked)}
                        />
                    </Box>
                    <Box sx={{width: "50%"}}>
                        <FormLabel id="local-label">lowCpu</FormLabel>
                        <Switch
                            inputProps={{"aria-label": "controlled"}}
                            checked={state.lowCpu}
                            disabled={!otherLaunchAttributesEnabled}
                            name="lowCpu"
                            onChange={(e) => changeState(e.target.name, e.target.checked)}
                        />
                    </Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                        gap: "15px",
                    }}
                >
                    <Box sx={{width: "50%"}}>
                        <FormLabel id="local-label">lowResource</FormLabel>
                        <Switch
                            inputProps={{"aria-label": "controlled"}}
                            checked={state.lowResource}
                            disabled={true}
                            name="lowResource"
                            onChange={(e) => changeState(e.target.name, e.target.checked)}
                        />
                    </Box>
                    <Box sx={{width: "50%"}}>
                        <FormLabel id="local-label">noRandoms</FormLabel>
                        <Switch
                            inputProps={{"aria-label": "controlled"}}
                            checked={state.noRandoms}
                            disabled={true}
                            name="noRandoms"
                            onChange={(e) => changeState(e.target.name, e.target.checked)}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                        gap: "15px",
                    }}
                >
                    <Box sx={{width: "50%"}}>
                        <FormLabel id="local-label">noInterface</FormLabel>
                        <Switch
                            inputProps={{"aria-label": "controlled"}}
                            checked={state.noInterface}
                            disabled={true}
                            name="noInterface"
                            onChange={(e) => changeState(e.target.name, e.target.checked)}
                        />
                    </Box>
                    <Box sx={{width: "50%"}}>
                        <FormLabel id="local-label">noRender</FormLabel>
                        <Switch
                            inputProps={{"aria-label": "controlled"}}
                            checked={state.noRender}
                            disabled={!otherLaunchAttributesEnabled}
                            name="noRender"
                            onChange={(e) => changeState(e.target.name, e.target.checked)}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                        gap: "15px",
                    }}
                >
                    <Box sx={{width: "50%"}}>
                        <FormLabel id="local-label">isMirror</FormLabel>
                        <Switch
                            inputProps={{"aria-label": "controlled"}}
                            checked={state.isMirror}
                            disabled={!otherLaunchAttributesEnabled}
                            name="isMirror"
                            onChange={(e) => changeState(e.target.name, e.target.checked)}
                        />
                    </Box>
                    <Box sx={{width: "50%"}}>
                        <FormLabel id="local-label">newMouse</FormLabel>
                        <Switch
                            inputProps={{"aria-label": "controlled"}}
                            checked={state.newMouse}
                            disabled={!otherLaunchAttributesEnabled}
                            name="newMouse"
                            onChange={(e) => changeState(e.target.name, e.target.checked)}
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                        gap: "15px",
                    }}
                >
                    <TextField
                        sx={{width: "50%"}}
                        type="number"
                        name="fps"
                        id="fps"
                        label="FPS"
                        variant="standard"
                        disabled={!otherLaunchAttributesEnabled}
                        value={state.fps}
                        onChange={(e) => changeState(e.target.name, e.target.value)}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "1rem",
                            gap: "15px",
                        }}
                    >

                        <TextField
                            sx={{width: "50%"}}
                            type="number"
                            name="world"
                            id="world"
                            label="World"
                            variant="standard"
                            value={state.world}
                            onChange={(e) => changeState(e.target.name, e.target.value)}
                            disabled={state.randomF2pWorld || state.randomP2pWorld || !otherLaunchAttributesEnabled}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.randomF2pWorld}
                                    disabled={!otherLaunchAttributesEnabled}
                                    onChange={(e) => changeState("randomF2pWorld", e.target.checked)}
                                    inputProps={{'aria-label': 'controlled'}}
                                />
                            }
                            label="F2P"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.randomP2pWorld}
                                    disabled={!otherLaunchAttributesEnabled}
                                    onChange={(e) => changeState("randomP2pWorld", e.target.checked)}
                                    inputProps={{'aria-label': 'controlled'}}
                                />
                            }
                            label="P2P"
                        />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                        gap: "15px",
                    }}
                >
                    <TextField
                        sx={{width: "50%"}}
                        type="number"
                        name="memory"
                        id="memory"
                        label="Memory"
                        disabled={!otherLaunchAttributesEnabled}
                        variant="standard"
                        value={state.memory}
                        onChange={(e) => changeState(e.target.name, e.target.value)}
                    />
                </Box>

                <Button fullWidth variant="contained"
                        sx={{marginTop: "10px"}}
                        onClick={proxyChainingEnabled ? handleSaveChaining : handleSaveNonChaining}>
                    Save
                </Button>
            </Container>
        </Container>
    )
}

export default ActionPlanBulkChange;