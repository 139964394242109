import {
    FETCH_PROFILES_API_ENDED,
    FETCH_PROFILES_FAILURE,
    FETCH_PROFILES_STARTED,
    FETCH_PROFILES_SUCCESS,
    UPDATE_PROFILES_TEXT,
} from "./actions";
import {UPDATE_MANAGER_STATE} from "../manager/actions";
import {ProfileDTO} from "../manager/types";

export type ProfileState = {
    loading: boolean,
    error: boolean | null,
    profiles: ProfileDTO[] | []
}


const initialState: ProfileState = {
    loading: false,
    error: null,
    profiles: []
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_PROFILES_STARTED:
            return {
                ...state,
                loading: true
            };
        case FETCH_PROFILES_SUCCESS:
            return {
                ...state,
                loading: false,
                profiles: action.payload,
            };
        case FETCH_PROFILES_API_ENDED:
            return {
                ...state,
                loading: false
            }
        case FETCH_PROFILES_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case UPDATE_MANAGER_STATE:
            return {
                ...state,
                [action.payload.property]: action.payload.value
            };
        case UPDATE_PROFILES_TEXT:
            return {
                ...state,
                notes: state.profiles.map((profile) => {
                    if (profile.profileKey === action.payload.profileKey) {
                        return {...profile, text: action.payload.text};
                    }
                    return profile;
                }),
            };
        default:
            return state;
    }
};

export default reducer;