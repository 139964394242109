import {Box, styled} from "@mui/material";
import * as React from "react";

export const StyledLoadingScreen = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})

export const LoadingImage = () => {
    return (
        <img src={"/assets/loader/spinner.gif"} alt="loading..."/>
    )
}

