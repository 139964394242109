import React, {useState} from 'react';
import {FormLabel, TextField, Box} from '@mui/material';
import {Settings} from '../../hooks/settingContext';

interface NumberComponentProps {
    label: string;
    jsonName: keyof Settings;
    defaultValue: number;
    changeState: (name: string, value: number) => void;
}

const NumberComponent: React.FC<NumberComponentProps> = ({
                                                             label,
                                                             jsonName,
                                                             defaultValue,
                                                             changeState,
                                                         }) => {
    const [inputValue, setInputValue] = useState(defaultValue.toString());

    const handleBlur = () => {
        // When the input loses focus, validate the value and convert it to a number
        const newValue = parseFloat(inputValue);
        if (!isNaN(newValue)) {
            changeState(jsonName as string, newValue);
            setInputValue(newValue.toString());
        } else {
            // Handle invalid input, e.g., by resetting it to the default value
            setInputValue(defaultValue.toString());
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Allow only numeric input
        const numericInput = event.target.value.replace(/[^0-9.-]/g, '');
        setInputValue(numericInput);
    };

    return (
        <>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="10px"
            >
                <FormLabel
                    id="local-label"
                    style={{
                        fontSize: '15px',
                        color: 'black',
                        fontWeight: 'normal',
                    }}
                >
                    {label}
                </FormLabel>
                <TextField
                    sx={{
                        margin: '0px',
                    }}
                    size="small"
                    type="text"
                    name={jsonName as string}
                    aria-labelledby="local-label"
                    value={inputValue}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{
                        'aria-label': 'Reflection',
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        style: {textAlign: 'center'},
                    }}
                />
            </Box>
        </>
    );
};

export default NumberComponent;
