import * as React from 'react';
import {useContext, useEffect, useMemo, useState} from 'react';
import ActionPlanCard from "../actionCard";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../store/reducers";
import {
    getAllActionPlans,
    getAllPlans,
    getAllProxys,
    turnArchiveActionPlan,
    turnUnArchiveActionPlan
} from "../../../../store/manager/actions";
import ActionCardEditable from "../actionCardEditable";
import {Box} from "@mui/material";
import ActionPlanBulkChange from "../actionPlanBulkChange";
import {ActionPlanContext} from "../hooks/actionPlanContext";

const ActionPlanTab = () => {
    const dispatch = useDispatch();
    const context = useContext(ActionPlanContext);
    const {archivedActionPlanCardEnabled, setArchivedActionPlanCard, bulkUpdateEnabled} = context;


    const [selectedActionPlanKey, setSelectedActionPlanKey] = useState<string>('');
    const {actionPlans, managerKey} = useSelector((state: State) => ({
        actionPlans: state.manager.actionPlans,
        managerKey: state.manager.managerKey

    }))

    useEffect(() => {
        if (bulkUpdateEnabled) {
            setSelectedActionPlanKey('')
        }
    }, [bulkUpdateEnabled, setSelectedActionPlanKey])

    const handleSelectActionPlan = (key: string) => {
        setSelectedActionPlanKey(key)
    }
    const handleSwapActionPlanType = () => {
        setArchivedActionPlanCard(!archivedActionPlanCardEnabled);
        setSelectedActionPlanKey('');

    }
    const handleArchiveTypeClick = (actionPlanKeys: string[]) => {
        if (archivedActionPlanCardEnabled) {
            dispatch(turnUnArchiveActionPlan(actionPlanKeys, managerKey));
        } else {
            dispatch(turnArchiveActionPlan(actionPlanKeys, managerKey));
        }
    }

    useEffect(() => {
        if (managerKey != null && managerKey.length > 0) {
            // make api call to get all action plans
            dispatch(getAllActionPlans(managerKey));
            dispatch(getAllProxys(managerKey))
            dispatch(getAllPlans(managerKey))
        }
    }, [managerKey, dispatch])


    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: {xs: "column", md: "row"},
                justifyContent: "space-around",
                p: 2,
                m: 2,
            }}
        >
            {archivedActionPlanCardEnabled && <ActionPlanCard
                selectedActionPlanKey={selectedActionPlanKey}
                title={"Archived Action Plans"}
                actionPlanList={actionPlans}
                onSwapClick={handleSwapActionPlanType}
                handleArchiveTypeClick={handleArchiveTypeClick}
                onClick={handleSelectActionPlan}/>}

            {!archivedActionPlanCardEnabled && <ActionPlanCard
                selectedActionPlanKey={selectedActionPlanKey}
                title={"Action Plans"}
                actionPlanList={actionPlans}
                onSwapClick={handleSwapActionPlanType}
                handleArchiveTypeClick={handleArchiveTypeClick}
                onClick={handleSelectActionPlan}/>}

            {bulkUpdateEnabled && <ActionPlanBulkChange/>}


            {selectedActionPlanKey && !bulkUpdateEnabled && <ActionCardEditable actionPlanKey={selectedActionPlanKey}/>}
        </Box>
    );
}

export default ActionPlanTab;