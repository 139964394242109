import {combineReducers} from 'redux';
import loginReducer, {LoginState} from '../login/reducer';
import overviewReducer, {OverviewState} from '../overview/reducer';
import managerReducer, {ManagerState} from '../manager/reducer';
import noteReducer, {NoteState} from "../notes/reducer";
import profileReducer, {ProfileState} from "../profiles/reducer";

export interface State {
    login: LoginState;
    overview: OverviewState;
    manager: ManagerState;
    notes: NoteState;
    profiles: ProfileState;
}

const rootReducer = combineReducers<State>({
    login: loginReducer,
    overview: overviewReducer,
    manager: managerReducer,
    notes: noteReducer,
    profiles: profileReducer
});

export default rootReducer;