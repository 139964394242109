import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../store/reducers";
import {getAllActionPlans, turnOffActionPlan, turnOnActionPlan} from "../../../../store/manager/actions";
import DashboardCard from "../dashboardCard";
import {Box} from "@mui/material";
import {LoadingImage, StyledLoadingScreen} from "../../../loading/LoadingImage";
import {ActionPlanDTO} from "../../../../store/manager/types";


const DashboardTab = () => {
    const dispatch = useDispatch();
    const {managerKey, actionPlans, loading} = useSelector((state: State) => ({
        managerKey: state.manager.managerKey,
        actionPlans: state.manager.actionPlans,
        loading: state.manager.loading,

    }))
    const [selectedActionPlan, setSelectedActionPlan] = useState('');

    const [activeActionPlans, setActiveActionPlans] = useState<ActionPlanDTO[]>([]);
    const [inactiveActionPlans, setInactiveActionPlans] = useState<ActionPlanDTO[]>([]);

    useEffect(() => {
        if (managerKey != null && managerKey.length > 0) {
            // make api call to get all action plans
            dispatch(getAllActionPlans(managerKey));
        }
    }, [managerKey, dispatch])

    useEffect(() => {
        const activeActionPlans: ActionPlanDTO[] = [];
        const inactiveActionPlans: ActionPlanDTO[] = [];

        actionPlans.map((item, index) => {
            if (item.shouldBeRunning && !item.archived) {
                activeActionPlans.push(item);
            } else if (!item.shouldBeRunning && !item.archived) {
                inactiveActionPlans.push(item);
            }
        });
        setActiveActionPlans(activeActionPlans);
        setInactiveActionPlans(inactiveActionPlans)
    }, [actionPlans])

    const handleActivatingActionPlan = (actionPlanKey: string[]) => {
        dispatch(turnOnActionPlan(actionPlanKey, managerKey));

    }
    const handleInActivatingActionPlan = (actionPlanKey: string[]) => {
        dispatch(turnOffActionPlan(actionPlanKey, managerKey));
    }

    const handleActionCardSelected = (key: string) => {
        setSelectedActionPlan(key);
    }


    if (loading) {
        return (
            <StyledLoadingScreen>
                <LoadingImage/>
            </StyledLoadingScreen>)
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: {xs: "column", md: "row"},
            justifyContent: 'space-around', p: 2,
            m: 2,
        }}>
            <DashboardCard actionPlans={inactiveActionPlans}
                           title={"Inactive action plans"}
                           onClick={handleActionCardSelected}
                           selectedRow={selectedActionPlan}
                           onActionPlanActionClick={handleActivatingActionPlan}
                           activeDashboardCard={false}/>
            <DashboardCard actionPlans={activeActionPlans}
                           title={"Active action plans"}
                           onClick={handleActionCardSelected}
                           selectedRow={selectedActionPlan}
                           onActionPlanActionClick={handleInActivatingActionPlan}
                           activeDashboardCard={true}/>
        </Box>)
}

export default DashboardTab;