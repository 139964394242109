export const getItem = (key: string) => {
    try {
        const value = localStorage.getItem(key);
        return value;
    } catch (error) {
        return error;
    }
};

export const setItem = (key: string, value: any) => {
    try {
        localStorage.setItem(key, value);
    } catch (error) {
        return error;
    }
};

export const removeItem = (key: string) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        return error;
    }
};

export const clearStorage = () => {
    try {
        localStorage.clear();
    } catch (error) {
        return error;
    }
};