import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, TextField} from '@mui/material';
import {toast} from "react-toastify";
import {makeRequest} from "../../../../store/utils/api";
import {useDispatch} from "react-redux";
import {LoadingImage, StyledLoadingScreen} from "../../../loading/LoadingImage";
import {ProfileDTO} from "../../../../store/manager/types";
import {updateProfile} from "../../../../store/profiles/actions";
import Button from "@mui/material/Button";

const getProfile = async (profileKey: string | undefined, loadingSetter: any, profileSetter: any) => {
    if (profileKey == null) {
        return;
    }
    loadingSetter(true);
    const response = await makeRequest(
        'post',
        '/api/v1/profile/get', {
            "profileKey": profileKey
        }
    );
    if (response.status === 200) {
        profileSetter(response.data)
    }
    loadingSetter(false)
}

type Props = {
    profileKey?: string
}

const ProfilesEditableSection = (props: Props) => {
    const {profileKey} = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [profile, setProfiles] = useState<ProfileDTO | null>(null);
    const [profileText, setProfileText] = useState<string>("");
    const dispatch = useDispatch();

    useEffect(() => {
        if (!profileKey) {
            return;
        }
        getProfile(profileKey, setLoading, setProfiles);

    }, [profileKey, setLoading, setProfiles])

    useEffect(() => {
        setProfileText(profile?.text ?? "");
    }, [profile]);


    const handleSave = (data: string) => {
        dispatch(updateProfile({
            "profileKey": profileKey ?? "",
            "name": profile?.name ?? "",
            "text": data
        }));
        toast.success('Successful save data', {
            position: toast.POSITION.TOP_CENTER
        });
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setProfileText(event.target.value);
    };
    if (loading) {
        return (
            <StyledLoadingScreen>
                <LoadingImage/>
            </StyledLoadingScreen>)
    }

    if (!profile) {
        return (
            <>
            </>
        );
    }
    return (
        <>
            <Box sx={{p: 4}}>
                <Box display="flex" gap="20px" flexDirection="column">
                    <Button variant="contained" color="primary" fullWidth onClick={() => handleSave(profileText)}>
                        Save
                    </Button>
                    <TextField
                        multiline
                        minRows={10}
                        variant="outlined"
                        value={profileText}
                        onChange={handleChange}
                        fullWidth
                        style={{backgroundColor: 'white'}}
                        InputProps={{
                            style: {backgroundColor: 'white'}
                        }}
                    />
                </Box>
            </Box>
        </>
    );
}
export default ProfilesEditableSection;