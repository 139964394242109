import "./App.css";
import {BrowserRouter as Router, Route, Routes, useLocation} from "react-router-dom";
import Home from "./pages/home/Home";
import NavBar from "./components/navbar/NavBar";
import Overview from "./pages/overview/Overview";
import Manager from "./pages/manager/Manager";
import Notes from "./pages/notes";
import GoogleAnalyticsManager from "./GoogleAnalyticsManager";

function App() {

    return (
        <Router>
            <GoogleAnalyticsManager/>
            <div>
                <NavBar/>
                <Routes>
                    <Route path="" element={<Home/>}/>
                    <Route path="/overview" element={<Overview/>}/>
                    <Route path="/manager/:managerName" element={<Manager/>}/>
                    <Route path="/manager/:managerName/:tab" element={<Manager/>}/>
                    <Route path="/notes" element={<Notes/>}/>
                </Routes>
            </div>
        </Router>
    );
}

export default App;
