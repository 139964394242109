import * as React from 'react';
import {useState} from 'react';
import {
    Box,
    Button,
    Card,
    Container, FormControlLabel,
    IconButton, Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Swal from "sweetalert2";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../store/reducers";
import {createPlan, deletePlan} from "../../../../store/manager/actions";
import {LoadingImage, StyledLoadingScreen} from "../../../loading/LoadingImage";
import Switch from "@mui/material/Switch";
import {useTablePagination} from "../../../hooks/tablePaginationHook";
import {PlanDTO} from "../../../../store/manager/types";


type Props = {
    selectedPlanKey?: string,
    title: string,
    planList: PlanDTO[],
    onClick: (key: string) => void
}


const PlanCard = (props: Props) => {
    const {title, planList, onClick, selectedPlanKey} = props;
    const theme = useTheme();
    const [searchTerm, setSearchTerm] = useState<string>('');
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [planName, setPlanName] = useState<string>('');
    const [loop, setLoop] = useState<boolean>(false);

    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTablePagination();


    const searchFilteredActionPlans = planList.filter((item) => {
        return item.name
            .toLowerCase()
            .startsWith(searchTerm.toLowerCase());
    })

    const {managerKey, loading} = useSelector((state: State) => ({
        managerKey: state.manager.managerKey,
        loading: state.manager.loading

    }))

    const handleSweetAlert = (key: string) => {
        Swal.fire({
            title: "Are you sure to delete this plan",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deletePlan(key, managerKey));
            }
        });
    }

    const handleCreatePlanModelClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        dispatch(createPlan(planName, managerKey, loop));
        setOpen(false);
        setLoop(false);
        setPlanName("");
    };


    if (loading) {
        return (
            <StyledLoadingScreen>
                <LoadingImage/>
            </StyledLoadingScreen>)
    }

    return (
        <Container sx={{p: {xs: 0, md: 4}}}>
            <Typography
                sx={{marginTop: {xs: "1rem", md: "0rem"}}}
                variant="h6"
                textAlign={"center"}
                mb={2}
            >
                {title}{" "}
            </Typography>
            <Card sx={{p: 2}}>
                <Box display="flex" gap="20px" justifyContent="space-between">
                    <TextField
                        sx={{fontSize: 14, width: "80%"}}
                        id="outlined-basic"
                        value={searchTerm}
                        label={<SearchIcon/>}
                        variant="outlined"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Button variant="contained" onClick={() => setOpen(true)} disabled={!managerKey}>
                        Create Plan
                    </Button>
                </Box>

                <TableContainer>
                    <Table aria-label="a dense table">
                        <TableHead sx={{th: {fontWeight: "700"}}}>
                            <TableRow>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">Loop?</TableCell>
                                <TableCell align="center">delete</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {searchFilteredActionPlans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index: number) => {
                                return (
                                    <TableRow
                                        key={item.planKey}
                                        onClick={() => {
                                            onClick(item.planKey);
                                        }}
                                        sx={{
                                            cursor: "pointer",
                                            backgroundColor:
                                                selectedPlanKey === item.planKey
                                                    ? theme.palette.primary.main
                                                    : "#fff",
                                            td: {
                                                color:
                                                    selectedPlanKey === item.planKey
                                                        ? "#fff"
                                                        : "#060229",
                                            },
                                        }}
                                    >
                                        <TableCell align="center" scope="row" sx={{padding: 1}}>
                                            {item.name}
                                        </TableCell>
                                        <TableCell align="center" scope="row" sx={{padding: 1}}>
                                            {item.loop ? "true" : "false"}
                                        </TableCell>
                                        <TableCell align="center"
                                                   sx={{padding: 1}}>
                                            <IconButton
                                                onClick={() => handleSweetAlert(item.planKey)}
                                                sx={{padding: 0}}
                                            >
                                                <DeleteOutlineIcon/>
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={searchFilteredActionPlans.length} // Total number of data
                    rowsPerPage={rowsPerPage} // Number of rows per page
                    page={page} // Current page
                    onPageChange={handleChangePage} // Function to handle page changes
                    onRowsPerPageChange={handleChangeRowsPerPage} // Function to handle changes in rows per page
                    labelRowsPerPage="Rows per page" // Description text for "rows per page" selector
                />
            </Card>

            <Modal
                open={open}
                onClose={handleCreatePlanModelClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <TextField
                        label="Plan Name"
                        value={planName}
                        onChange={(e) => setPlanName(e.target.value)}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={loop}
                                onChange={(e) => setLoop(e.target.checked)}
                            />
                        }
                        label="Loop"
                    />
                    <Button variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
}

export default PlanCard;