import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid, Typography} from "@mui/material";
import BooleanComponent from "../../comp/BooleanComponent";
import RangeComponent from "../../comp/RangeComponent";


const MiningTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);


    return (
        <Grid container spacing={3}>
            <Grid item>
                <BooleanComponent
                    label="Skip early mining level using quest"
                    jsonName="useQuestToSkipEarlyMiningLevels"
                    defaultValue={settings.useQuestToSkipEarlyMiningLevels}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Buy all pickaxes at one time"
                    jsonName="miningBuyAllPickaxesAtOneTime"
                    defaultValue={settings.miningBuyAllPickaxesAtOneTime}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Mining time (seconds)"
                    jsonName="miningTimeRange"
                    defaultValue={settings.miningTimeRange}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}


export default MiningTab;
