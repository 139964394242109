import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid, Typography} from "@mui/material";
import BooleanComponent from "../../comp/BooleanComponent";
import RangeComponent from "../../comp/RangeComponent";


const RangeTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h6" gutterBottom>
                        Range system setting
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Enable defense training"
                    jsonName="rangeEnableDefenseTraining"
                    defaultValue={settings.rangeEnableDefenseTraining}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Range time (seconds)"
                    jsonName="rangeTimeRange"
                    defaultValue={settings.rangeTimeRange}
                    changeState={updateSetting}
                />
            </Grid>

            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h6" gutterBottom>
                        Low level range
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Buy all item at one time"
                    jsonName="rangeLowLevelBuyAllItemAtOneTime"
                    defaultValue={settings.rangeLowLevelBuyAllItemAtOneTime}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Food range"
                    jsonName="rangeLowLevelFoodRange"
                    defaultValue={settings.rangeLowLevelFoodRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Iron arrow range"
                    jsonName="rangeLowLevelIronArrowRange"
                    defaultValue={settings.rangeLowLevelIronArrowRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Steel arrow range"
                    jsonName="rangeLowLevelSteelArrowRange"
                    defaultValue={settings.rangeLowLevelSteelArrowRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Mithril arrow range"
                    jsonName="rangeLowLevelMithrilArrowRange"
                    defaultValue={settings.rangeLowLevelMithrilArrowRange}
                    changeState={updateSetting}
                />
            </Grid>


            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h6" gutterBottom>
                        Mid level range
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Food range"
                    jsonName="rangeMidLevelFoodRange"
                    defaultValue={settings.rangeMidLevelFoodRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Bone bolt range"
                    jsonName="rangeMidLevelBoneBoltsRange"
                    defaultValue={settings.rangeMidLevelBoneBoltsRange}
                    changeState={updateSetting}
                />
            </Grid>

        </Grid>
    )
}

export default RangeTab;
