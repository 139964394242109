import * as React from 'react';
import {useState} from 'react';
import {
    Box,
    Button,
    Card,
    Container,
    IconButton,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Swal from "sweetalert2";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../store/reducers";
import {LoadingImage, StyledLoadingScreen} from "../../../loading/LoadingImage";
import {createNewNote, deleteNote} from "../../../../store/notes/actions";
import {NoteDTO} from "../../../../store/manager/types";


type Props = {
    selectedNoteKey?: string,
    noteList: NoteDTO[],
    onClick: (key: string) => void
}


const NoteCardList = (props: Props) => {
    const {noteList, onClick, selectedNoteKey} = props;

    const theme = useTheme();
    const dispatch = useDispatch();

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [open, setOpen] = useState(false);
    const [noteName, setNoteName] = useState<string>('');


    const searchFilteredNoteList = noteList.filter((item) => {
        return item.name
            .toLowerCase()
            .startsWith(searchTerm.toLowerCase());
    })

    const {loading} = useSelector((state: State) => ({
        loading: state.manager.loading
    }))

    const handleSweetAlert = (key: string) => {
        Swal.fire({
            title: "Are you sure to delete this plan",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteNote(key));
            }

        });
    }

    const handleCreatePlanModelClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        dispatch(createNewNote(noteName));
        setOpen(false);
        setNoteName("");
    };


    if (loading) {
        return (
            <StyledLoadingScreen>
                <LoadingImage/>
            </StyledLoadingScreen>)
    }

    return (
        <Container sx={{p: {xs: 0, md: 4}}}>
            <Card sx={{p: 2}}>
                <Box display="flex" gap="20px" justifyContent="space-between">
                    <TextField
                        sx={{fontSize: 14, width: "80%"}}
                        id="outlined-basic"
                        value={searchTerm}
                        label={<SearchIcon/>}
                        variant="outlined"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Button variant="contained" onClick={() => setOpen(true)}>
                        Create Note
                    </Button>
                </Box>

                <TableContainer>
                    <Table aria-label="a dense table">
                        <TableHead sx={{th: {fontWeight: "700"}}}>
                            <TableRow>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">delete</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {searchFilteredNoteList.map((item, index) => {
                                return (
                                    <TableRow
                                        key={item.noteKey}
                                        onClick={() => {
                                            onClick(item.noteKey);
                                        }}
                                        sx={{
                                            cursor: "pointer",
                                            backgroundColor:
                                                selectedNoteKey === item.noteKey
                                                    ? theme.palette.primary.main
                                                    : "#fff",
                                            td: {
                                                color:
                                                    selectedNoteKey === item.noteKey
                                                        ? "#fff"
                                                        : "#060229",
                                            },
                                        }}
                                    >
                                        <TableCell align="center" scope="row">
                                            {item.name}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                onClick={() => handleSweetAlert(item.noteKey)}
                                                sx={{padding: 0}}
                                            >
                                                <DeleteOutlineIcon/>
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>

            <Modal
                open={open}
                onClose={handleCreatePlanModelClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <TextField
                        label="Note Name"
                        value={noteName}
                        onChange={(e) => setNoteName(e.target.value)}
                    />
                    <Button variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
}

export default NoteCardList;