import {Box, Button, TextField} from '@mui/material';
import {styled} from '@mui/system';
import * as React from 'react';
import {ChangeEvent} from 'react';


const StyledLoginButton = styled(Button)(({theme}) => ({
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
    marginLeft: "18px",
    width: "151px",
    height: "55px",
}));

const StyledLoginContainer = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "space-around"
}));

const StyledTextField = styled(TextField)(({theme}) => ({
    width: "356px",
    height: "20px"
}));

interface LoginSectionProps {
    inputValue: string;
    handleInputValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleSubmitButton: () => void;
}

const LoginSection = ({inputValue, handleInputValueChange, handleSubmitButton}: LoginSectionProps) => {
    return (
        <StyledLoginContainer>
            <StyledTextField
                required
                id="outlined-required"
                label="Enter your apiFarmKey"
                autoComplete='off'
                onChange={handleInputValueChange}
                value={inputValue}
            />
            <StyledLoginButton
                variant="contained"
                onClick={handleSubmitButton}
            >
                Submit
            </StyledLoginButton>
        </StyledLoginContainer>

    )
}

export default LoginSection;