import {
    FETCH_ALL_ACTION_PLANS_SUCCESS,
    FETCH_ALL_MANAGERS_SUCCESS,
    FETCH_ALL_OSBOT_SCRIPT_SUCCESS, FETCH_ALL_OVERVIEW_PLAYER_CARDS_SUCCESS,
    FETCH_ALL_PLANS_SUCCESS,
    FETCH_ALL_PROXYS_SUCCESS,
    FETCH_API_ENDED,
    FETCH_API_FAILURE,
    FETCH_API_STARTED,
    UPDATE_MANAGER_STATE
} from "./actions";
import {ActionPlanDTO, ManagerDTO, OsbotSdnScriptDTO, PlanDTO, ProxyDTO} from "./types";
import {OverViewSkillProps} from "../../components/commonProps";

export type ManagerState = {
    loading: boolean,
    error: boolean,
    managerKey: string
    managers: ManagerDTO[],
    actionPlans: ActionPlanDTO[],
    plans: PlanDTO[],
    proxys: ProxyDTO[],
    osbotScripts: OsbotSdnScriptDTO[],
    overviewPlayerCards: OverViewSkillProps[]

}


const initialState: ManagerState = {
    loading: false,
    error: false,
    managerKey: "",
    managers: [],
    actionPlans: [],
    plans: [],
    proxys: [],
    osbotScripts: [],
    overviewPlayerCards: [],

};
const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_API_STARTED:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case FETCH_API_ENDED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case FETCH_ALL_MANAGERS_SUCCESS:
            return {
                ...state,
                error: false,
                managers: action.payload,
            };
        case FETCH_ALL_ACTION_PLANS_SUCCESS:
            return {
                ...state,
                error: false,
                actionPlans: action.payload,
            };
        case FETCH_ALL_PLANS_SUCCESS:
            return {
                ...state,
                error: false,
                plans: action.payload,
            };
        case FETCH_ALL_PROXYS_SUCCESS:
            return {
                ...state,
                error: false,
                proxys: action.payload,
            };
        case FETCH_ALL_OSBOT_SCRIPT_SUCCESS:
            return {
                ...state,
                error: false,
                osbotScripts: action.payload,
            };
        case FETCH_ALL_OVERVIEW_PLAYER_CARDS_SUCCESS:
            return {
                ...state,
                error: false,
                overviewPlayerCards: action.payload,
            };
        case FETCH_API_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case UPDATE_MANAGER_STATE:
            return {
                ...state,
                [action.payload.property]: action.payload.value
            };
        default:
            return state;
    }
};

export default reducer;
