import * as React from 'react';
import {
    Box,
    Button,
    Card,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import ExpandIcon from '@mui/icons-material/Expand';
import {styled} from "@mui/system";
import ActionCardHeader from "./header";
import {useActionPlanCard} from "./hooks";
import {useTablePagination} from "../../../hooks/tablePaginationHook";
import {useTableSelectAll} from "../../../hooks/tableSelectAllHook";
import {ActionPlanDTO, PlayerTrackerDTO} from "../../../../store/manager/types";
import {useContext, useEffect, useState} from "react";
import {ActionPlanContext} from "../hooks/actionPlanContext";
import PlayerTrackerModal from "../../../playerTrackerModal";
import InfoIcon from "@mui/icons-material/Info";

interface StyledCheckboxProps {
    isChecked: boolean;
}

const StyledIconButton = styled(IconButton)(({theme}) => ({
    padding: 0.5,
}));

const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>(({theme, isChecked}) => ({
    padding: 0.5,
    '&.Mui-checked': {
        color: isChecked ? "#fff" : theme.palette.primary.main,
    },
}));

type Props = {
    selectedActionPlanKey?: string,
    title: string,
    actionPlanList: ActionPlanDTO[],
    onClick: (key: string) => void,
    onSwapClick: () => void,
    handleArchiveTypeClick: (key: string[]) => void,

}

const ActionPlanCard = (props: Props) => {
    const {
        title,
        actionPlanList,
        onClick,
        selectedActionPlanKey,
        onSwapClick,
        handleArchiveTypeClick
    } = props;

    const theme = useTheme();

    const context = useContext(ActionPlanContext);
    const {archivedActionPlanCardEnabled, bulkUpdateEnabled, setBulkUpdateActionPlans} = context;

    const [openPlayerTrackerModel, setOpenPlayerTrackerModel] = useState(false);
    const [selectedPlayerTracker, setSelectedPlayerTracker] = useState<PlayerTrackerDTO | undefined>(undefined);


    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTablePagination();

    const {
        searchTerm,
        setSearchTerm,
        sortColumnValue,
        sortConfig,
        requestColumnSort,
        handleSortColumnChange
    } = useActionPlanCard();

    const {
        handleSelect,
        handleSelectAllClicked,
        selectedRows,
        selectAll,
    } = useTableSelectAll();


    const handleButtonArchiveOrUnarchive = () => {
        handleArchiveTypeClick(selectedRows);
    };

    useEffect(() => {
        if (bulkUpdateEnabled) {
            const actionPlans: ActionPlanDTO[] = actionPlanList.filter(plan => selectedRows.includes(plan.actionPlanKey));
            setBulkUpdateActionPlans(actionPlans);
        }
    }, [selectedRows, bulkUpdateEnabled])


    const searchFilteredActionPlans = actionPlanList.filter((item) => {
        switch (sortColumnValue) {
            case 1:
                return item.player.alias
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase());
            case 2:
                if (item.proxy == null) {
                    return false
                }
                return item.proxy.alias
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
            case 3:
                if (item.plan == null) {
                    return false
                }
                return item.plan.name
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase());
            case 4:
                if (item.message == null) {
                    return false
                }
                return item.message
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase());
        }
    })


    let sortedActionPlans = React.useMemo(() => {
        let sortableItems = [...searchFilteredActionPlans];
        if (sortConfig !== null) {
            sortableItems.sort((a: ActionPlanDTO, b: ActionPlanDTO) => {
                const aValue = sortConfig.key.split('.').reduce((o: any, i: string) => o ? o[i] : null, a);
                const bValue = sortConfig.key.split('.').reduce((o: any, i: string) => o ? o[i] : null, b);
                if (aValue < bValue || bValue === null) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue || aValue === null) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [searchFilteredActionPlans, sortConfig]);


    sortedActionPlans = sortedActionPlans.filter((item: ActionPlanDTO) => {
        return item.archived === archivedActionPlanCardEnabled;
    });

    return (
        <Container sx={{p: {xs: 0, md: 4}}}>
            <Typography
                sx={{marginTop: {xs: "1rem", md: "0rem"}}}
                variant="h6"
                textAlign={"center"}
                mb={2}
            >
                <Box display="flex" flexDirection="row" gap="8px" justifyContent="center">
                    <Button id="outlined-basic"
                            disabled={bulkUpdateEnabled}
                            startIcon={<CachedIcon/>}
                            onClick={onSwapClick}
                            variant="contained">
                        {archivedActionPlanCardEnabled ? "Active" : "Archived"}
                    </Button>
                    {title}
                </Box>

            </Typography>
            <Card sx={{p: 2}}>
                <ActionCardHeader
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    sortColumnValue={sortColumnValue}
                    handleChangeOnSortColumn={handleSortColumnChange}
                    handleButtonArchiveOrUnarchive={handleButtonArchiveOrUnarchive}
                    archivedPlan={archivedActionPlanCardEnabled}
                />

                <TableContainer>
                    <Table aria-label="a dense table">
                        <TableHead sx={{th: {fontWeight: "700"}}}>
                            <TableRow>
                                <TableCell align="center">
                                    <IconButton onClick={() => handleSelectAllClicked(sortedActionPlans)}
                                                sx={{padding: .5}}>
                                        <Checkbox checked={selectAll}
                                                  sx={{padding: .5}}/>
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center" onClick={() => requestColumnSort('player.alias')}
                                           sx={{cursor: "pointer"}}>
                                    Name
                                    {sortConfig && sortConfig.key === 'player.alias' && (sortConfig.direction === 'ascending' ? ' 🔽' : ' 🔼')}
                                </TableCell>
                                <TableCell align="center" onClick={() => requestColumnSort('proxy.alias')}
                                           sx={{cursor: "pointer"}}>
                                    Proxy Name
                                    {sortConfig && sortConfig.key === 'proxy.alias' && (sortConfig.direction === 'ascending' ? ' 🔽' : ' 🔼')}
                                </TableCell>
                                <TableCell align="center" onClick={() => requestColumnSort('plan.name')}
                                           sx={{cursor: "pointer"}}>
                                    Plan Name
                                    {sortConfig && sortConfig.key === 'plan.name' && (sortConfig.direction === 'ascending' ? ' 🔽' : ' 🔼')}
                                </TableCell>

                                <TableCell align="center"
                                           onClick={() => requestColumnSort('message')}
                                           sx={{cursor: "pointer"}}>
                                    message
                                    {sortConfig && sortConfig.key === 'message' && (sortConfig.direction === 'ascending' ? ' 🔽' : ' 🔼')}
                                </TableCell>

                                {!bulkUpdateEnabled &&
                                    <TableCell align="center">
                                        Select
                                    </TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedActionPlans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index: number) => {
                                return (
                                    <TableRow
                                        key={item.actionPlanKey}
                                        sx={{
                                            backgroundColor:
                                                selectedActionPlanKey === item.actionPlanKey
                                                    ? theme.palette.primary.main
                                                    : "#fff",
                                            td: {
                                                color:
                                                    selectedActionPlanKey === item.actionPlanKey
                                                        ? "#fff"
                                                        : "#060229",
                                            },
                                            padding: 1
                                        }}
                                    >
                                        <TableCell
                                            sx={{
                                                cursor: "pointer",
                                                padding: .5,

                                            }}
                                            scope="row"
                                            align="center"
                                        >
                                            <StyledIconButton onClick={() => handleSelect(item.actionPlanKey)}>
                                                <StyledCheckbox
                                                    checked={selectedRows.includes(item.actionPlanKey)}
                                                    isChecked={selectedActionPlanKey === item.actionPlanKey}
                                                />
                                            </StyledIconButton>
                                        </TableCell>
                                        <TableCell align="center" scope="row" sx={{padding: 1}}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                {item.player.alias}
                                                <InfoIcon onClick={() => {
                                                    // Step 2: Set the selected player and open the modal
                                                    setSelectedPlayerTracker(item.playerTracker);  // Assuming `item.player` is your DTO
                                                    setOpenPlayerTrackerModel(true);
                                                }}/>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" scope="row"
                                                   sx={{padding: 1}}>
                                            {item.proxy ? item.proxy.alias : "Not Set"}
                                        </TableCell>
                                        <TableCell align="center" scope="row"
                                                   sx={{padding: 1}}>
                                            {item.plan ? item.plan.name : "Not Set"}
                                        </TableCell>

                                        <TableCell align="center" scope="row"
                                                   sx={{padding: 1}}>
                                            {item.message}
                                        </TableCell>
                                        {!bulkUpdateEnabled &&
                                            <TableCell
                                                sx={{cursor: "pointer", padding: .5}}
                                                scope="row"
                                                align="center"
                                            >
                                                <IconButton onClick={() => onClick(item.actionPlanKey)}
                                                            sx={{padding: .5}}>
                                                    <ExpandIcon/>
                                                </IconButton>
                                            </TableCell>
                                        }
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={sortedActionPlans.length} // Total number of data
                    rowsPerPage={rowsPerPage} // Number of rows per page
                    page={page} // Current page
                    onPageChange={handleChangePage} // Function to handle page changes
                    onRowsPerPageChange={handleChangeRowsPerPage} // Function to handle changes in rows per page
                    labelRowsPerPage="Rows per page" // Description text for "rows per page" selector
                />
            </Card>

            {openPlayerTrackerModel &&
                <PlayerTrackerModal
                    open={openPlayerTrackerModel}
                    closeModal={() => {
                        setOpenPlayerTrackerModel(!openPlayerTrackerModel)
                    }}
                    playerTrackerDTO={selectedPlayerTracker}


                />}
        </Container>
    );
}

export default ActionPlanCard;