import axios from "./axios";
import {getItem} from "../../utils/localStorage";


const setAxiosDefaults = (params: any) => {
    // let token = params.token || getItem("token");
    let apiFarmKey = getItem("apiFarmKey") as string;
    // axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    axios.defaults.headers.common["Content-Type"] = `application/json`;
    axios.defaults.headers.common["accept"] = `application/json`;
    axios.defaults.headers.common["apiFarmKey"] = `${apiFarmKey}`;
};

const handleResponse = (res: any) => {
    if (res.code === 200) {
        return res.data;
    }
    return res;
};

const handleError = (err: any) => {
    return err;
};

export const makeRequest = (type: any, url: string, params: any): Promise<any> => {
    setAxiosDefaults(params);

    switch (type) {
        case "get":
            return axios
                .get(url, {params: params})
                .then(handleResponse)
                .catch(handleError);
        case "post":
            return axios
                .post(url, params)
                .then(handleResponse)
                .catch(handleError);
        case "put":
            return axios
                .put(url, params)
                .then(handleResponse)
                .catch(handleError);
        case "delete":
            return axios
                .delete(url, {data: params})
                .then(handleResponse)
                .catch(handleError);
        case "patch":
            return axios
                .patch(url, params)
                .then(handleResponse)
                .catch(handleError);
        default:
            return Promise.reject();
    }
};