import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import BooleanComponent from "../../comp/BooleanComponent";
import RangeComponent from "../../comp/RangeComponent";


const MeleeTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <BooleanComponent
                    label="Balance Levels"
                    jsonName="meleeBalanceLevels"
                    defaultValue={settings.meleeBalanceLevels}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Bury bones"
                    jsonName="meleeEnableBuryBones"
                    defaultValue={settings.meleeEnableBuryBones}
                    changeState={updateSetting}
                />
            </Grid>   <Grid item>
                <BooleanComponent
                    label="Buy all item at one time"
                    jsonName="meleeBuyAllItemAtOneTime"
                    defaultValue={settings.meleeBuyAllItemAtOneTime}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Melee time (seconds)"
                    jsonName="meleeTimeRange"
                    defaultValue={settings.meleeTimeRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Melee food "
                    jsonName="meleeFoodRange"
                    defaultValue={settings.meleeFoodRange}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default MeleeTab;
