import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import BooleanComponent from "../../comp/BooleanComponent";
import RangeComponent from "../../comp/RangeComponent";


const CookingTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);


    return (
        <Grid container spacing={3}>
            <Grid item>
                <BooleanComponent
                    label="Sell raw high level fish"
                    jsonName="cookingSellRawHighLevelFish"
                    defaultValue={settings.cookingSellRawHighLevelFish}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Sell cooked high level fish"
                    jsonName="cookingSellCookedHighLevelFish"
                    defaultValue={settings.cookingSellCookedHighLevelFish}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Sardine Range"
                    jsonName="cookingSardineRange"
                    defaultValue={settings.cookingSardineRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Trout Range"
                    jsonName="cookingTroutRange"
                    defaultValue={settings.cookingTroutRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Tuna Range"
                    jsonName="cookingTunaRange"
                    defaultValue={settings.cookingTunaRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Swordfish Range"
                    jsonName="cookingSwordfishRange"
                    defaultValue={settings.cookingSwordfishRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Monkfish Range"
                    jsonName="cookingMonkfishRange"
                    defaultValue={settings.cookingMonkfishRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Shark Range"
                    jsonName="cookingSharkRange"
                    defaultValue={settings.cookingSharkRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Anglerfish Range"
                    jsonName="cookingAnglerfishRange"
                    defaultValue={settings.cookingAnglerfishRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Cooking time (seconds)"
                    jsonName="cookingTimeRange"
                    defaultValue={settings.cookingTimeRange}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}
export default CookingTab;
