import * as React from 'react';
import {ReactNode, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getAllAvailableManagers,
    getAllAvailableOsbotScript,
    getAllOverviewPlayerCards, UPDATE_MANAGER_STATE
} from "../../store/manager/actions";
import ManagerDropDown from "../../components/managerDropDown";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Typography, useTheme} from "@mui/material";
import DashboardTab from "../../components/page/dashboard/dashboardTab";
import ActionPlanTab from "../../components/page/actionplan/actionTab";
import PlanTab from "../../components/page/plan/planTab";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {State} from "../../store/reducers";
import ProfileTab from "../../components/page/profile/profileTab";
import {ActionPlanProvider} from "../../components/page/actionplan/hooks/actionPlanContext";
import P2pAIO from "../../components/page/p2paio/tab";
import F2pAIO from '../../components/page/f2paio/tab';


interface TabPanelProps {
    children: ReactNode;
    value: number;
    index: number;

    [key: string]: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 1}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}


const Manager = () => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const [tabLocation, setTabLocation] = React.useState(0);
    const navigate = useNavigate();
    const currentLocation = useLocation();

    const handleChange = (event: React.SyntheticEvent, location: number) => {
        const currentPath = currentLocation.pathname;
        setTabLocation(location);
        if (location === 0) {
            navigate("/manager/" + managerName + "/dashboard")
        }
        if (location === 1) {
            navigate("/manager/" + managerName + "/actionplan")
        }
        if (location === 2) {
            navigate("/manager/" + managerName + "/plan")
        }
        if (location === 3) {
            navigate("/manager/" + managerName + "/profile")
        }
        if (location === 4) {
            navigate("/manager/" + managerName + "/p2paio")
        }
        if (location === 5) {
            navigate("/manager/" + managerName + "/f2paio")
        }
    };

    const {managerKeyList, selectedManagerKey} = useSelector((state: State) => ({
        managerKeyList: state.manager.managers,
        selectedManagerKey: state.manager.managerKey
    }))

    useEffect(() => {
        dispatch(getAllAvailableOsbotScript());
        dispatch(getAllOverviewPlayerCards())
    }, [dispatch])

    const {managerName, tab} = useParams();

    useEffect(() => {
        if (managerKeyList.length > 0) {
            const selected = managerKeyList.find(item => item.name === managerName);
            if (selected) {
                if (selectedManagerKey !== selected.managerKey) {
                    dispatch({
                        type: UPDATE_MANAGER_STATE,
                        payload: {
                            property: 'managerKey',
                            value: selected.managerKey,
                        }
                    })
                }
            }
        }
    }, [managerName, selectedManagerKey, dispatch, managerKeyList])

    if (tab) {
        if (tab === "dashboard" && tabLocation != 0) {
            setTabLocation(0);
        }
        if (tab === "actionplan" && tabLocation != 1) {
            setTabLocation(1);
        }
        if (tab === "plan" && tabLocation != 2) {
            setTabLocation(2);
        }
        if (tab === "profile" && tabLocation != 3) {
            setTabLocation(3);
        }
        if (tab === "p2paio" && tabLocation != 4) {
            setTabLocation(4);
        }
        if (tab === "f2paio" && tabLocation != 5) {
            setTabLocation(5);
        }
    }


    return (
        <>
            <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                <Tabs
                    textColor="inherit"
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: theme.palette.primary.main,
                        },
                    }}
                    variant="fullWidth"
                    value={tabLocation}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab label="Dashboard" {...a11yProps(0)} />
                    <Tab label="Action Plan" {...a11yProps(1)} />
                    <Tab label="Plan" {...a11yProps(2)} />
                    <Tab label="Profile" {...a11yProps(3)} />
                    <Tab label="P2P AIO" {...a11yProps(4)} />
                    <Tab label="F2P AIO" {...a11yProps(5)} />
                </Tabs>
            </Box>

            <TabPanel value={tabLocation} index={0}>
                <DashboardTab/>
            </TabPanel>
            <TabPanel value={tabLocation} index={1}>
                <ActionPlanProvider>
                    <ActionPlanTab/>
                </ActionPlanProvider>
            </TabPanel>
            <TabPanel value={tabLocation} index={2}>
                <PlanTab/>
            </TabPanel>
            <TabPanel value={tabLocation} index={3}>
                <ProfileTab/>
            </TabPanel>
            <TabPanel value={tabLocation} index={4}>
                <P2pAIO/>
            </TabPanel>
            <TabPanel value={tabLocation} index={5}>
                <F2pAIO/>
            </TabPanel>


        </>
    )
};

export default Manager;