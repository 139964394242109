import {AppDispatch} from "../index";
import {makeRequest} from "../utils/api";
import {ProfileDTO} from "../manager/types";

export const FETCH_PROFILES_STARTED = 'FETCH_PROFILES_STARTED';
export const FETCH_PROFILES_SUCCESS = 'FETCH_PROFILES_SUCCESS';
export const FETCH_PROFILES_FAILURE = 'FETCH_PROFILES_FAILURE';
export const FETCH_PROFILES_API_ENDED = 'FETCH_PROFILES_API_ENDED';
export const UPDATE_PROFILES_STATE = 'UPDATE_PROFILES_STATE';
export const UPDATE_PROFILES_TEXT = 'UPDATE_PROFILES_TEXT';


export const fetchApiStarted = () => ({
    type: FETCH_PROFILES_STARTED,
});

export const fetchApiEnded = () => ({
    type: FETCH_PROFILES_API_ENDED,
});
export const fetchApiError = () => ({
    type: FETCH_PROFILES_FAILURE,
});

export const fetchProfilesSuccess = (payload: ProfileDTO[]) => ({
    type: FETCH_PROFILES_SUCCESS,
    payload: payload,
});

export const updateProfileText = (payload: ProfileDTO) => ({
    type: UPDATE_PROFILES_TEXT,
    payload: payload,
});


export const getAllProfiles = (): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted());
        const response = await makeRequest(
            'post',
            '/api/v1/profile/get/all', {}
        );
        if (response.status !== 200) {
            dispatch(fetchApiError());
            return;
        }
        dispatch(fetchProfilesSuccess(response.data));
        dispatch(fetchApiEnded());
    };
};

export const createNewProfile = (name: string, p2pAio: boolean, f2pAio: boolean): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted());
        const response = await makeRequest(
            'post',
            '/api/v1/profile', {
                "name": name,
                "p2pAio": p2pAio,
                "f2pAio": f2pAio,

            }
        );
        if (response.status !== 200) {
            dispatch(fetchApiError());
            return;
        } else {
            dispatch(getAllProfiles());
        }
    };
};

export const deleteProfile = (profileKey: string): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted());
        const response = await makeRequest(
            'post',
            '/api/v1/profile/delete', {
                "profileKey": profileKey
            }
        );
        if (response.status !== 200) {
            dispatch(fetchApiError());
            return;
        } else {
            dispatch(getAllProfiles());
        }
    };
};

export const updateProfile = (profileDTO: ProfileDTO): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted());
        const response = await makeRequest(
            'put',
            '/api/v1/profile', {
                ...profileDTO
            }
        );
        if (response.status !== 200) {
            dispatch(fetchApiError());
            return;
        } else {
            dispatch(updateProfileText(profileDTO));
        }
    }
}
