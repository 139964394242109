import {AppDispatch} from "../index";
import {makeRequest} from "../utils/api";
import {NoteDTO} from "../manager/types";

export const FETCH_NOTES_STARTED = 'FETCH_NOTES_STARTED';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';
export const FETCH_NOTES_FAILURE = 'FETCH_NOTES_FAILURE';
export const FETCH_NOTES_API_ENDED = 'FETCH_NOTES_API_ENDED';
export const UPDATE_NOTES_STATE = 'UPDATE_NOTES_STATE';
export const UPDATE_NOTE_TEXT = 'UPDATE_NOTE_TEXT';


export const fetchApiStarted = () => ({
    type: FETCH_NOTES_STARTED,
});

export const fetchApiEnded = () => ({
    type: FETCH_NOTES_API_ENDED,
});
export const fetchApiError = () => ({
    type: FETCH_NOTES_FAILURE,
});

export const fetchNotesSuccess = (payload: NoteDTO[]) => ({
    type: FETCH_NOTES_SUCCESS,
    payload: payload,
});

export const updateNoteText = (payload: NoteDTO) => ({
    type: UPDATE_NOTE_TEXT,
    payload: payload,
});


export const getAllNotes = (): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted());
        const response = await makeRequest(
            'post',
            '/api/v1/note/get/all', {}
        );
        if (response.status !== 200) {
            dispatch(fetchApiError());
            return;
        }
        dispatch(fetchNotesSuccess(response.data));
        dispatch(fetchApiEnded());
    };
};

export const createNewNote = (name: string): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted());
        const response = await makeRequest(
            'post',
            '/api/v1/note', {
                "name": name
            }
        );
        if (response.status !== 200) {
            dispatch(fetchApiError());
            return;
        } else {
            dispatch(getAllNotes());
        }
    };
};

export const deleteNote = (noteKey: string): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted());
        const response = await makeRequest(
            'post',
            '/api/v1/note/delete', {
                "noteKey": noteKey
            }
        );
        if (response.status !== 200) {
            dispatch(fetchApiError());
            return;
        } else {
            dispatch(getAllNotes());
        }
    };
};

export const updateNote = (note: NoteDTO): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted());
        const response = await makeRequest(
            'put',
            '/api/v1/note', {
                ...note
            }
        );
        if (response.status !== 200) {
            dispatch(fetchApiError());
            return;
        } else {
            dispatch(updateNoteText(note));
        }
    }
}
