import React, {createContext, useContext, useState} from 'react';
import {ActionPlanDTO} from "../../../../../store/manager/types";

// Define the shape of the context data
interface ActionPlanContextState {
    bulkUpdateEnabled: boolean;
    setBulkUpdate: (value: boolean) => void;
    archivedActionPlanCardEnabled: boolean;
    setArchivedActionPlanCard: (value: boolean) => void;
    bulkUpdateActionPlans: ActionPlanDTO[];
    setBulkUpdateActionPlans: React.Dispatch<React.SetStateAction<ActionPlanDTO[]>>;
}

// Define the UserContext with default values
export const ActionPlanContext = createContext<ActionPlanContextState>({
    bulkUpdateEnabled: false,
    setBulkUpdate: () => {
    },
    archivedActionPlanCardEnabled: false,
    setArchivedActionPlanCard: () => {
    },
    bulkUpdateActionPlans: [],
    setBulkUpdateActionPlans: () => {
    },
});

interface ActionPlanProviderProps {
    children: React.ReactNode;
}

export const ActionPlanProvider: React.FC<ActionPlanProviderProps> = ({children}) => {
    const [bulkUpdateEnabled, setBulkUpdate] = useState<boolean>(false);
    const [archivedActionPlanCardEnabled, setArchivedActionPlanCard] = useState<boolean>(false);
    const [bulkUpdateActionPlans, setBulkUpdateActionPlans] = useState<ActionPlanDTO[]>([]);


    return (
        <ActionPlanContext.Provider value={{
            bulkUpdateEnabled,
            setBulkUpdate,
            archivedActionPlanCardEnabled,
            setArchivedActionPlanCard,
            bulkUpdateActionPlans,
            setBulkUpdateActionPlans
        }}>
            {children}
        </ActionPlanContext.Provider>
    );
};