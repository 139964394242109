import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import RangeComponent from "../../comp/RangeComponent";


const FishingTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <RangeComponent
                    label="Feather low level range"
                    jsonName="fishingFeatherLowLevelRange"
                    defaultValue={settings.fishingFeatherLowLevelRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Feather barb range"
                    jsonName="fishingFeatherBarbRange"
                    defaultValue={settings.fishingFeatherBarbRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Fishing time low level(seconds) "
                    jsonName="fishingTimeLowLevelRange"
                    defaultValue={settings.fishingTimeLowLevelRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Fishing time high level (seconds)"
                    jsonName="fishingTimeBarbLevelRange"
                    defaultValue={settings.fishingTimeBarbLevelRange}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default FishingTab;
