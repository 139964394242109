import * as React from "react";
import {Box, Card, CardContent, Grid, Typography,} from "@mui/material";
import {useSelector} from "react-redux";
import {State} from "../../store/reducers";

function StatsOverview() {

    const {
        totalActiveAccount24,
        totalGold24,
        totalActiveAccount72,
        totalGold72
    } = useSelector((state: State) => ({
        totalActiveAccount24: state.overview.panel.totalActiveAccount24,
        totalGold24: state.overview.panel.totalGold24,
        totalActiveAccount72: state.overview.panel.totalActiveAccount72,
        totalGold72: state.overview.panel.totalGold72
    }));

    return (
        <Box marginTop={"10px"}>
            <Grid
                container
                columnSpacing={{xs: 1}}
                rowSpacing={{xs: 1}}
                direction="row"
                alignItems="center"
                justifyContent="space-evenly"
            >
                <Grid item xs={12} md={6} lg={3} xl={3}>
                    <Card>
                        <Typography
                            sx={{fontSize: "16px", margin: 1}}
                            style={{display: "flex", justifyContent: "left"}}
                        >
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    Active accounts (24H)
                                </Typography>
                                <Typography variant="body2">
                                    {totalActiveAccount24}
                                </Typography>
                            </CardContent>
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3} xl={3}>
                    <Card>
                        <Typography
                            sx={{fontSize: "16px", margin: 1}}
                            style={{display: "flex", justifyContent: "left"}}
                        >
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    Total gold (24H)
                                </Typography>
                                <Typography variant="body2">
                                    {totalGold24}
                                </Typography>
                            </CardContent>
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3} xl={3}>
                    <Card>
                        <Typography
                            sx={{fontSize: "16px", margin: 1}}
                            style={{display: "flex", justifyContent: "left"}}
                        >
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    Active accounts (72H)
                                </Typography>
                                <Typography variant="body2">
                                    {totalActiveAccount72}
                                </Typography>
                            </CardContent>
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} lg={3} xl={3}>
                    <Card>
                        <Typography
                            sx={{fontSize: "16px", margin: 1}}
                            style={{display: "flex", justifyContent: "left"}}
                        >
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    Total gold (72H)
                                </Typography>
                                <Typography variant="body2">
                                    {totalGold72}
                                </Typography>
                            </CardContent>
                        </Typography>
                    </Card>
                </Grid>

            </Grid>
        </Box>
    );
}

export default StatsOverview;
