import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {Card, Grid} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../store/reducers";
import {getAllProfiles} from "../../../../store/profiles/actions";
import ProfileCardList from "../../profile/profileCard";
import P2pAioEditor from "../editableTab";
import {SettingContextProvider} from "../hooks/settingContext";
import F2pAioEditor from '../editableTab';


const F2pAIO = () => {

    const dispatch = useDispatch();
    const [selectedProfileKey, setSelectedProfileKey] = useState<string>('');

    const {profilesList} = useSelector((state: State) => ({
        profilesList: state.profiles.profiles
    }));

    useEffect(() => {
        dispatch(getAllProfiles());
    }, [dispatch])



    // Using useMemo to create a filtered version of profilesList
    const filteredProfiles = useMemo(() => {
        return profilesList.filter(o => o.f2pAio);
    }, [profilesList]);


    // Find the selected profile
    const selectedProfile = useMemo(() => {
        return profilesList.find(profile => profile.profileKey === selectedProfileKey);
    }, [profilesList, selectedProfileKey]);


    return (
        <>
            <Grid container direction={{xs: "column", md: "row"}}>
                <Grid item xs={6} md={4}>
                    <ProfileCardList
                        title={"Profiles"}
                        p2pAio={false}
                        f2pAio={true}
                        onClick={setSelectedProfileKey}
                        profileList={filteredProfiles}
                        selectedProfileKey={selectedProfileKey}/>
                </Grid>

                <Grid item xs={6} md={8} key={selectedProfileKey}>
                    {(selectedProfileKey.length > 0) &&
                        <Card sx={{p: .5}}>
                            <SettingContextProvider profile={selectedProfile}>
                                <F2pAioEditor/>
                            </SettingContextProvider>
                        </Card>
                    }
                </Grid>

            </Grid>
        </>
    );
}

export default F2pAIO;