import * as React from 'react';
import {useEffect, useMemo, useState} from 'react';
import {Grid, Box, Typography, Switch} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../store/reducers";
import {getAllProfiles} from "../../../../store/profiles/actions";
import ProfileCardList from "../profileCard";
import ProfilesEditableSection from "../profileEditableSection";


const Profiles = () => {

    const dispatch = useDispatch();
    const [selectedProfileKey, setSelectedProfileKey] = useState<string>('');

    const {profilesList} = useSelector((state: State) => ({
        profilesList: state.profiles.profiles
    }));

    useEffect(() => {
        dispatch(getAllProfiles());
    }, [dispatch])

// Using useMemo to create a filtered version of profilesList
    const filteredProfiles = useMemo(() => {
        return profilesList.filter(o => !o.p2pAio).filter(o => !o.f2pAio);
    }, [profilesList]);


    return (
        <>
            <Grid container direction={{xs: "column", md: "row"}}>
                <Grid item xs={6} md={4}>
                    <ProfileCardList
                        title={"Profiles"}
                        p2pAio={false}
                        f2pAio={false}
                        onClick={setSelectedProfileKey}
                        profileList={filteredProfiles}
                        selectedProfileKey={selectedProfileKey}/>
                </Grid>

                <Grid item xs={6} md={8}>
                    <ProfilesEditableSection profileKey={selectedProfileKey}/>
                </Grid>
            </Grid>
        </>
    );
}

export default Profiles;