import * as React from 'react';
import {ChangeEvent, useState} from 'react';
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Button, styled, Typography} from "@mui/material";
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';


const IconButton = styled('button')({
    height: '24px',
    width: '24px',
    padding: 0,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
});

type Options = {
    text: string,
    value: string ,
    onClick: (playerKey: string ) => void;
}

type Props = {
    options?: Options[];
};


const BasicDropDown = (props: Props) => {
    const {
        options
    } = props;
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <Box>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertOutlinedIcon/>
            </IconButton>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {
                    options?.map((item, index) => {
                        return (
                            <MenuItem onClick={() => item.onClick(item.value)} key={index}>
                                <Typography textAlign="center">
                                    {item.text}
                                </Typography>
                            </MenuItem>
                        )
                    })

                }
            </Menu>
        </Box>
    )
}


export default BasicDropDown;