import { createStore, applyMiddleware, compose, Store, CombinedState, Middleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer, { State } from './reducers';

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares: Middleware[] = [thunk];

const store: Store<CombinedState<State>> = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares)),
);

export type AppDispatch = typeof store.dispatch;
export default store;