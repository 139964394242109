import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import NumberComponent from "../../comp/NumberComponent";


const BreakingTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <NumberComponent
                    label="Start play time in seconds"
                    jsonName="startPlayTime"
                    defaultValue={settings.startPlayTime}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <NumberComponent
                    label="End play time in seconds"
                    jsonName="endPlayTime"
                    defaultValue={settings.endPlayTime}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <NumberComponent
                    label=" Start break time in seconds"
                    jsonName="startBreakTime"
                    defaultValue={settings.startBreakTime}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <NumberComponent
                    label="End break time in seconds"
                    jsonName="endBreakTime"
                    defaultValue={settings.endBreakTime}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default BreakingTab;
