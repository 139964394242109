import * as React from 'react';
import {ReactNode, useContext} from 'react';
import {Box, Container, Typography} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import {SettingContext} from "../hooks/settingContext";
import LevelTab from "../minitabs/levels";
import SystemTab from "../minitabs/system";
import CraftingTab from "../minitabs/crafting";
import MiningTab from "../minitabs/mining";
import CookingTab from "../minitabs/cooking";
import FishingTab from "../minitabs/fishing";
import FiremakingTab from "../minitabs/firemaking";
import WoodcuttingTab from "../minitabs/woodcutting";
import BreakingTab from "../minitabs/breaking";
import QuestTab from "../minitabs/quests";
import MeleeTab from "../minitabs/combat";
import RangeTab from "../minitabs/range";
import MagicTab from "../minitabs/magic";
import SmithingTab from "../minitabs/smithing";


interface TabPanelProps {
    children: ReactNode;
    value: number;
    index: number;

    [key: string]: any;
}


function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,

    };
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 1}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </Box>
    );
}


const F2pAioEditor = () => {
    const [tabLocation, setTabLocation] = React.useState(0);
    const {settings, saveSettings, updateSetting} = useContext(SettingContext);


    const handleChange = (event: any, newValue: number) => {
        setTabLocation(newValue);
    };

    const copyToClipboard = () => {
        const settingsString = JSON.stringify(settings, null, 2);
        navigator.clipboard.writeText(settingsString)
            .then(() => {
                console.log('Settings copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy settings: ', err);
            });
    };

    const pasteAndUpdateSettings = () => {
        // Attempt to read from clipboard
        navigator.clipboard.readText()
            .then(text => {
                const settings = JSON.parse(text);
                Object.entries(settings).forEach(([key, value]) => {
                    console.log(key, value)
                    updateSetting(key, value);
                });
                console.log('Settings updated from clipboard data');
            })
            .catch(err => {
                console.error('Failed to paste settings: ', err);
            });
    };


    return (
        <Container sx={{p: 4}}>
            <Box display="flex" gap="10px" flexDirection="column">
                <Button variant="contained" color="primary" fullWidth onClick={saveSettings}>
                    Save
                </Button>
                <Box display="flex"  gap="20px">
                <Button variant="contained" color="secondary" fullWidth onClick={copyToClipboard}>
                    Copy profile
                </Button>
                <Button variant="contained" color="secondary" fullWidth onClick={pasteAndUpdateSettings}>
                    Paste profile
                </Button>
            </Box>
                <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                    <Tabs
                        textColor="inherit"
                        // variant="fullWidth"
                        value={tabLocation}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        TabIndicatorProps={{sx: {display: 'none'}}}
                        sx={{
                            '& .MuiTabs-flexContainer': {
                                flexWrap: 'wrap',
                            },
                            '& .MuiTab-root.Mui-selected': { // Targeting the selected tab
                                bgcolor: 'primary.dark', // Or any color you prefer for the background
                                color: 'white', // If you want a lighter text on a dark background
                                // Add any other styles you want for the selected tab
                            },
                        }}
                    >
                        <Tab label="Levels" {...a11yProps(0)} />
                        <Tab label="System" {...a11yProps(1)} />
                        <Tab label="Breaking" {...a11yProps(2)} />
                        <Tab label="Cooking" {...a11yProps(3)} />
                        <Tab label="Crafting" {...a11yProps(4)} />
                        <Tab label="Fishing" {...a11yProps(5)} />
                        <Tab label="Firemaking" {...a11yProps(6)} />
                        <Tab label="Magic" {...a11yProps(7)} />
                        <Tab label="Melee" {...a11yProps(8)} />
                        <Tab label="Mining" {...a11yProps(8)} />
                        <Tab label="Quests" {...a11yProps(10)} />
                        <Tab label="Range" {...a11yProps(11)} />
                        <Tab label="Smithing" {...a11yProps(12)} />
                        <Tab label="Woodcutting" {...a11yProps(13)} />


                    </Tabs>
                </Box>

                <TabPanel value={tabLocation} index={0}>
                    <LevelTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={1}>
                    <SystemTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={2}>
                    <BreakingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={3}>
                    <CookingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={4}>
                    <CraftingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={5}>
                    <FishingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={6}>
                    <FiremakingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={7}>
                    <MagicTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={8}>
                    <MeleeTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={9}>
                    <MiningTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={10}>
                    <QuestTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={11}>
                    <RangeTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={12}>
                    <SmithingTab/>
                </TabPanel>
                <TabPanel value={tabLocation} index={13}>
                    <WoodcuttingTab/>
                </TabPanel>

            </Box>
        </Container>

    );

}

export default F2pAioEditor;