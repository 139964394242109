import {useState} from "react";


type SortConfig = {
    key: 'player.alias' | 'proxy.alias' | 'plan.name' | 'message';
    direction: 'ascending' | 'descending';
} | null;


export const useActionPlanCard = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [sortColumnValue, setSortColumnValue] = useState(1);
    const [sortConfig, setSortConfig] = useState<SortConfig>(
        {
            key: "player.alias",
            direction: "ascending"
        }
    );

    const requestColumnSort = (key: 'player.alias' | 'proxy.alias' | 'plan.name' | 'message') => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const handleSortColumnChange = (event: any) => {
        setSortColumnValue(event.target.value);
    };

    return {
        searchTerm,
        setSearchTerm,
        sortColumnValue,
        setSortColumnValue,
        sortConfig,
        setSortConfig,
        requestColumnSort,
        handleSortColumnChange
    }
}
