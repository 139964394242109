import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid, Typography} from "@mui/material";
import BooleanComponent from "../../comp/BooleanComponent";
import RangeComponent from "../../comp/RangeComponent";


const MiningTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);


    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h6" gutterBottom>
                        Low level mining
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Skip early mining level using quest"
                    jsonName="useQuestToSkipEarlyMiningLevels"
                    defaultValue={settings.useQuestToSkipEarlyMiningLevels}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Typography variant="h6" gutterBottom>
                        Motherload Mine
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Mlm bank gems"
                    jsonName="miningMlmBankGems"
                    defaultValue={settings.miningMlmBankGems}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Mlm deposit box"
                    jsonName="miningMlmDepositBox"
                    defaultValue={settings.miningMlmDepositBox}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Mlm buy coal bag and stop"
                    jsonName="miningMlmBuyCoalBagAndStop"
                    defaultValue={settings.miningMlmBuyCoalBagAndStop}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Mlm buy gear"
                    jsonName="miningMlmBuyGear"
                    defaultValue={settings.miningMlmBuyGear}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Mlm unlock upstairs"
                    jsonName="miningMlmUnlockUpstairs"
                    defaultValue={settings.miningMlmUnlockUpstairs}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Mlm unlock upstairs before gear"
                    jsonName="miningMlmUnlockUpstairsBeforeGear"
                    defaultValue={settings.miningMlmUnlockUpstairsBeforeGear}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Enable dragon pickaxe"
                    jsonName="miningEnableDragonPickaxe"
                    defaultValue={settings.miningEnableDragonPickaxe}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Mining time (seconds)"
                    jsonName="miningTimeRange"
                    defaultValue={settings.miningTimeRange}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}


export default MiningTab;
