import * as React from 'react';
import {useState} from 'react';
import {
    Button,
    Card,
    Container,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";

import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import {useTablePagination} from "../../../hooks/tablePaginationHook";
import {useTableSelectAll} from "../../../hooks/tableSelectAllHook";
import {ActionPlanDTO, PlayerTrackerDTO} from "../../../../store/manager/types";
import InfoIcon from '@mui/icons-material/Info';
import NewTaskModal from "../../plan/planCardEditTable/modals/newTaskModal";
import PlayerTrackerModal from "../../../playerTrackerModal";

type Props = {
    actionPlans: ActionPlanDTO[],
    title: string,
    onClick: (key: string) => void,
    selectedRow?: string,
    onActionPlanActionClick: (key: string[]) => void
    activeDashboardCard: boolean
}

type SortConfig = {
    key: 'player.alias' | 'proxy.alias' | 'plan.name' | 'message';
    direction: 'ascending' | 'descending';
} | null;


const DashboardCard = (props: Props) => {
    const {title, actionPlans, onClick, selectedRow, onActionPlanActionClick, activeDashboardCard} = props;
    const theme = useTheme();

    const [openPlayerTrackerModel, setOpenPlayerTrackerModel] = useState(false);
    const [selectedPlayerTracker, setSelectedPlayerTracker] = useState<PlayerTrackerDTO | undefined>(undefined);

    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTablePagination();

    const {
        handleSelect,
        handleSelectAllClicked,
        selectedRows,
        setSelectedRows,
        selectAll,
    } = useTableSelectAll();


    const [searchTerm, setSearchTerm] = useState<string>('');

    const [sortFeature, setSortFeature] = useState(1);
    const handleChange = (event: any) => {
        setSortFeature(event.target.value);
    };


    const searchFilteredActionPlans = actionPlans.filter((item) => {
        switch (sortFeature) {
            case 1:
                return item.player.alias
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase());
            case 2:
                if (item.proxy == null) {
                    return false
                }
                return item.proxy.alias
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase());
            case 3:
                if (item.plan == null) {
                    return false
                }
                return item.plan.name
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase());
            case 4:
                if (item.currentTaskName == null) {
                    return false
                }
                return item.currentTaskName
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase());
            case 5:
                if (item.message == null) {
                    return false
                }
                return item.message
                    .toLowerCase()
                    .startsWith(searchTerm.toLowerCase());
        }
    })


    const handleButtonLaunchOrStop = () => {
        onActionPlanActionClick(selectedRows)
        setSelectedRows([]);
    };


    const [sortConfig, setSortConfig] = useState<SortConfig>(
        {
            key: "player.alias",
            direction: "ascending"
        }
    );
    const requestSort = (key: 'player.alias' | 'proxy.alias' | 'plan.name' | 'message') => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({key, direction});
    };

    const sortedActionPlans = React.useMemo(() => {
        let sortableItems = [...searchFilteredActionPlans];
        if (sortConfig !== null) {
            sortableItems.sort((a: ActionPlanDTO, b: ActionPlanDTO) => {
                const aValue = sortConfig.key.split('.').reduce((o: any, i: string) => o ? o[i] : null, a);
                const bValue = sortConfig.key.split('.').reduce((o: any, i: string) => o ? o[i] : null, b);
                if (aValue < bValue || bValue === null) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (aValue > bValue || aValue === null) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [searchFilteredActionPlans, sortConfig]);


    return (
        <Container sx={{p: {xs: 0, md: 4}}}>
            <Typography
                sx={{marginTop: {xs: "1rem", md: "0rem"}}}
                variant="h6"
                textAlign={"center"}
                mb={2}>
                {title}
            </Typography>
            <Card sx={{p: 2}}>

                <Box display="flex" flexDirection="row" gap="8px">
                    <TextField
                        sx={{fontSize: 14, width: "100%"}}
                        id="outlined-basic"
                        value={searchTerm}
                        label={<SearchIcon/>}
                        variant="outlined"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Select
                        value={sortFeature}
                        onChange={handleChange}
                    >
                        <MenuItem value={1}>Name</MenuItem>
                        <MenuItem value={2}>Proxy Name</MenuItem>
                        <MenuItem value={3}>Plan Name</MenuItem>
                        {activeDashboardCard && <MenuItem value={4}>Current Task</MenuItem>}
                        {!activeDashboardCard && <MenuItem value={5}>message</MenuItem>}
                    </Select>

                    <Button variant="contained" onClick={handleButtonLaunchOrStop} size="large">
                        {activeDashboardCard ? "Stop" : "Launch"}
                    </Button>
                </Box>


                <TableContainer>
                    <Table aria-label="a dense table">
                        <TableHead sx={{th: {fontWeight: "700"}}}>
                            <TableRow>
                                <TableCell align="center">
                                    <IconButton onClick={() => handleSelectAllClicked(sortedActionPlans)}
                                                sx={{padding: .5}}>
                                        <Checkbox checked={selectAll}
                                                  sx={{padding: .5}}/>
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center" onClick={() => requestSort('player.alias')}
                                           sx={{cursor: "pointer"}}>
                                    Name
                                    {sortConfig && sortConfig.key === 'player.alias' && (sortConfig.direction === 'ascending' ? ' 🔽' : ' 🔼')}
                                </TableCell>
                                <TableCell align="center" onClick={() => requestSort('proxy.alias')}
                                           sx={{cursor: "pointer"}}>
                                    Proxy Name
                                    {sortConfig && sortConfig.key === 'proxy.alias' && (sortConfig.direction === 'ascending' ? ' 🔽' : ' 🔼')}
                                </TableCell>
                                <TableCell align="center" onClick={() => requestSort('plan.name')}
                                           sx={{cursor: "pointer"}}>
                                    Plan Name
                                    {sortConfig && sortConfig.key === 'plan.name' && (sortConfig.direction === 'ascending' ? ' 🔽' : ' 🔼')}
                                </TableCell>
                                {activeDashboardCard && <TableCell align="center">Current Task</TableCell>}
                                {activeDashboardCard && <TableCell align="center">Time left</TableCell>}
                                {!activeDashboardCard &&
                                    <TableCell align="center"
                                               onClick={() => requestSort('message')}
                                               sx={{cursor: "pointer"}}>
                                        message
                                        {sortConfig && sortConfig.key === 'message' && (sortConfig.direction === 'ascending' ? ' 🔽' : ' 🔼')}
                                    </TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {sortedActionPlans.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index: number) => {
                                return (
                                    <TableRow
                                        key={item.actionPlanKey}
                                        onClick={() => {
                                            onClick(item.actionPlanKey);
                                        }}

                                    >
                                        <TableCell
                                            sx={{cursor: "pointer", padding: .5}}
                                            scope="row"
                                            align="center"
                                        >
                                            <IconButton onClick={() => handleSelect(item.actionPlanKey)}
                                                        sx={{padding: .5}}>
                                                <Checkbox checked={selectedRows.includes(item.actionPlanKey)}
                                                          sx={{padding: .5}}/>
                                            </IconButton>
                                        </TableCell>

                                        <TableCell align="center" scope="row" sx={{padding: 0.5}}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                {item.player.alias}
                                                <InfoIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                    // Step 2: Set the selected player and open the modal
                                                    setSelectedPlayerTracker(item.playerTracker);  // Assuming `item.player` is your DTO
                                                    setOpenPlayerTrackerModel(true);
                                                }}/>
                                            </div>
                                        </TableCell>
                                        <TableCell align="center" scope="row" sx={{padding: .5}}>
                                            {item.proxy ? item.proxy.alias : "Not Set"}
                                        </TableCell>
                                        <TableCell align="center" scope="row" sx={{padding: .5}}>
                                            {item.plan ? item.plan.name : "Not Set"}
                                        </TableCell>
                                        {activeDashboardCard &&
                                            <TableCell align="center" scope="row" sx={{padding: .5}}>
                                                {item.currentTaskName ?? "Not sure"}
                                            </TableCell>
                                        }
                                        {activeDashboardCard &&
                                            <TableCell align="center" scope="row" sx={{padding: .5}}>
                                                {item.timeLeftOnTask === -2147483648 ? "Until stop" : item.timeLeftOnTask ?? "Not sure"}
                                            </TableCell>
                                        }
                                        {!activeDashboardCard &&
                                            <TableCell align="center" scope="row" sx={{padding: .5}}>
                                                {item.message}
                                            </TableCell>
                                        }

                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={sortedActionPlans.length} // Total number of data
                    rowsPerPage={rowsPerPage} // Number of rows per page
                    page={page} // Current page
                    onPageChange={handleChangePage} // Function to handle page changes
                    onRowsPerPageChange={handleChangeRowsPerPage} // Function to handle changes in rows per page
                    labelRowsPerPage="Rows per page" // Description text for "rows per page" selector
                />
            </Card>

            {openPlayerTrackerModel &&
                <PlayerTrackerModal
                    open={openPlayerTrackerModel}
                    closeModal={() => {
                        setOpenPlayerTrackerModel(!openPlayerTrackerModel)
                    }}
                    playerTrackerDTO={selectedPlayerTracker}


                />}
        </Container>
    )
}

export default DashboardCard;