import {FETCH_LOGIN_FAILURE, FETCH_LOGIN_STARTED, FETCH_LOGIN_SUCCESS,} from './actions';

export type LoginState = {
    loading: boolean,
    error: boolean | null,
    data: {
        success: boolean
    } | null
}


const initialState: LoginState = {
    loading: false,
    error: null,
    data: null
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_LOGIN_STARTED:
            return {
                ...state,
                loading: true,
                data: null
            };
        case FETCH_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case FETCH_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                data: null,
                error: true,
            };
        default:
            return state;
    }
};

export default reducer;