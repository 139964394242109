import * as React from 'react';
import {Box, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../store/reducers";
import {styled} from "@mui/system";
import {fetchSearchPayload} from "../../../../store/overview/actions";

const StyledPaginationWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "30px",
});

const StyledPaginationButton = styled(Button)({
    // backgroundColor: "#fe9933",
    // color: "#fff",
});

const PagePagination = () => {

    const dispatch = useDispatch();

    const {overviewPage,} = useSelector((state: State) => ({
        overviewPage: state.overview.overviewPage,
    }));


    const handleBack = () => {
        if (!overviewPage) {
            return;
        }
        if (overviewPage.currentPage == 0) {
            return;
        }
        dispatch(fetchSearchPayload('page', overviewPage.currentPage - 1));
    }

    const handleForward = () => {
        if (!overviewPage) {
            return;
        }
        if (overviewPage.currentPage == overviewPage.totalPages - 1) {
            return;
        }
        dispatch(fetchSearchPayload('page', overviewPage.currentPage + 1));
    }

    return (
        <>
            <StyledPaginationWrapper>
                <Stack
                    direction={{sm: "row", md: "row"}}
                    spacing={3}
                    alignItems="center"
                >
                    <StyledPaginationButton
                        variant="contained"
                        onClick={handleBack}
                    >
                        <KeyboardArrowLeft/>
                    </StyledPaginationButton>
                    <Typography>
                        Page {overviewPage?.currentPage || "0"} out of &nbsp;
                        {(overviewPage?.totalPages ? overviewPage?.totalPages - 1 : "0")}
                    </Typography>
                    <StyledPaginationButton
                        variant="contained"
                        onClick={handleForward}
                    >
                        <KeyboardArrowRight/>
                    </StyledPaginationButton>
                </Stack>
            </StyledPaginationWrapper>
        </>
    );
}

export default PagePagination;