import * as React from 'react';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../store/reducers";
import {getAllPlans} from "../../../../store/manager/actions";
import PlanCard from "../planCard";
import PlanCardEditTable from "../planCardEditTable";
import {Box, Grid} from "@mui/material";

const PlanTab = () => {

    const [selectedPlanKey, setSelectedPlanKey] = useState<string>('');

    const dispatch = useDispatch();
    const {plans, managerKey} = useSelector((state: State) => ({
        plans: state.manager.plans,
        managerKey: state.manager.managerKey

    }))
    const handleSelectPlan = (key: string) => {
        setSelectedPlanKey(key)
    }
    useEffect(() => {
        if (managerKey != null && managerKey.length > 0) {
            // make api call to get all action plans
            dispatch(getAllPlans(managerKey))
        }
    }, [managerKey, dispatch])

    if (!selectedPlanKey) {
        return (<Box
            sx={{
                display: "flex",
                flexDirection: {xs: "column", md: "row"},
                justifyContent: "space-around",
                p: 2,
                m: 2,
            }}
        >
            <PlanCard
                title={"Plans"}
                planList={plans}
                onClick={handleSelectPlan}
                selectedPlanKey={selectedPlanKey}/>
        </Box>)
    }
    ;


    return (
        <Grid container direction={{xs: "column", md: "row"}}>
            <Grid item xs={6} md={4}>
                <PlanCard
                    title={"plans"}
                    planList={plans}
                    onClick={handleSelectPlan}
                    selectedPlanKey={selectedPlanKey}/>
            </Grid>
            {selectedPlanKey && <Grid item xs={6} md={8}>
                <PlanCardEditTable
                    selectedPlanKey={selectedPlanKey}/>
            </Grid>
            }
        </Grid>
    )
}
export default PlanTab;