import * as React from 'react';
import {useState} from 'react';
import {v4 as uuidv4} from "uuid";
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Modal,
    Switch,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField/TextField";
import {useSelector} from "react-redux";
import {State} from "../../../../../../store/reducers";
import {OsbotSdnScriptDTO, TaskDTO} from "../../../../../../store/manager/types";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    p: 4,
};


type Props = {
    open: boolean,
    closeModal: () => void,
    handleSave: (task: TaskDTO) => void
    editMode: boolean,
    editModeTask?: TaskDTO | null
}


const TaskModal = (props: Props) => {
    const {open, closeModal, handleSave, editMode, editModeTask} = props;

    const {osbotScripts} = useSelector((state: State) => ({
        osbotScripts: state.manager.osbotScripts
    }))

    const [error, setError] = useState<boolean>(false);

    const [local, setLocal] =
        useState<boolean>((editMode && editModeTask) ? editModeTask?.local : false);
    const [taskName, setTaskName] =
        useState<string>(editMode && editModeTask ? editModeTask.taskName : '');
    const [localJarName, setLocalJarName] =
        useState<string>((editMode && editModeTask && editModeTask.localJarName) ? editModeTask.localJarName : '');

    const [runUntilStop, setRunUntilStop] = useState<boolean>(
        (editMode && editModeTask && editModeTask.runTillStop) ? true : false
    );

    const [minAmountOfTime, setMinAmountOfTime] =
        useState<number>(editMode && editModeTask && editModeTask.minAmountOfTime ? editModeTask.minAmountOfTime : 0);
    const [maxAmountOfTime, setMaxAmountOfTime] =
        useState<number>(editMode && editModeTask && editModeTask.maxAmountOfTime ? editModeTask.maxAmountOfTime : 0);
    const [cli, setCli] =
        useState<string>(editMode && editModeTask && editModeTask.cli ? editModeTask.cli : '');
    const [osbotScript, setOsbotScript] =
        useState<OsbotSdnScriptDTO | null>(editMode && editModeTask && editModeTask.osbotSdnScript ? editModeTask.osbotSdnScript : null);
    const [breakTask, setBreakTask] =
        useState<boolean>(!!(editMode && editModeTask && editModeTask?.taskName === "Break"));

    const handleSubmitTask = () => {
        if (local) {
            if (localJarName.length == 0) {
                setError(true);
                return
            }
        } else {
            if (osbotScript == null) {
                setError(true);
                return
            }
        }
        if (taskName.length == 0) {
            setError(true);
            return
        }
        if (!runUntilStop && (minAmountOfTime == 0 || maxAmountOfTime == 0)) {
            setError(true);
            return
        }
        setError(false);
        const newUUID = uuidv4();

        const task: TaskDTO = {
            "cli": cli,
            "local": local,
            "taskKey": editMode ? editModeTask?.taskKey : newUUID,
            "localJarName": localJarName,
            "maxAmountOfTime": maxAmountOfTime,
            "minAmountOfTime": minAmountOfTime,
            "osbotSdnScript": osbotScript,
            "taskName": taskName,
            "runTillStop": runUntilStop,
        }
        handleSave(task);
        closeModal();
    }

    if (local && osbotScript != null) {
        setOsbotScript(null);
    }
    if (runUntilStop && minAmountOfTime !== 0) {
        setMinAmountOfTime(0)
    }
    if (runUntilStop && maxAmountOfTime !== 0) {
        setMaxAmountOfTime(0)
    }

    const handleBreakTask = () => {
        setBreakTask(true);
        setLocal(true);
        setTaskName("Break");
        setLocalJarName("Break");
        setRunUntilStop(false);
    }

    return (<>
        <Modal
            open={open}
            onClose={closeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        pb: 2,
                    }}
                >
                    <Box>
                        <Typography sx={{fontSize: "20px"}}>Add new task</Typography>
                    </Box>
                    <Box>
                        <IconButton
                            aria-label="close"
                            onClick={closeModal}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon/>
                        </IconButton>
                    </Box>
                </Box>
                <Divider/>

                <Grid container spacing={2} sx={{pt: 4}}>
                    <>
                        {error &&
                            <Grid item xs={12} md={12}>
                                <Typography sx={{fontSize: "20px", color: "red"}}>Please fill out all required
                                    fields</Typography>
                            </Grid>}
                        <Grid item xs={12} md={12}>
                            <Button
                                variant="contained"
                                onClick={handleBreakTask}
                                fullWidth
                            >
                                Break task
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <FormLabel id="local-label">Local</FormLabel>
                            <Switch
                                inputProps={{"aria-label": "controlled"}}
                                id="local"
                                checked={local}
                                disabled={breakTask}
                                required={true}
                                onChange={(e) => setLocal(e.target.checked)}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <FormLabel id="run-unit-stop">Run until stop</FormLabel>
                            <Switch
                                inputProps={{"aria-label": "controlled"}}
                                id="run-unit-stop"
                                checked={runUntilStop}
                                disabled={breakTask}
                                required={true}
                                onChange={(e) => setRunUntilStop(e.target.checked)}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                id="newTaskName"
                                label="Task Name"
                                variant="outlined"
                                name="newTaskName"
                                type="string"
                                value={taskName}
                                required={true}
                                disabled={breakTask}
                                onChange={(e) => setTaskName(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                label="Local osbot script name"
                                variant="outlined"
                                type="string"
                                value={localJarName}
                                onChange={(e) => setLocalJarName(e.target.value)}
                                disabled={!local || breakTask}
                                required={local}
                            />
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                label="Min Amount Of Time"
                                variant="outlined"
                                type="number"
                                required={true}
                                disabled={runUntilStop}
                                value={minAmountOfTime == 0 ? "" : minAmountOfTime}
                                onChange={(e) => setMinAmountOfTime(Number(e.target.value))}
                            />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                fullWidth
                                label="Max Amount Of Time"
                                variant="outlined"
                                type="number"
                                required={true}
                                disabled={runUntilStop}
                                value={maxAmountOfTime == 0 ? "" : maxAmountOfTime}
                                onChange={(e) => setMaxAmountOfTime(Number(e.target.value))}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                fullWidth
                                label="CLI"
                                variant="outlined"
                                type="string"
                                multiline
                                value={cli}
                                disabled={breakTask}
                                onChange={(e) => setCli(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormControl sx={{width: '100%'}}>
                                <Autocomplete
                                    fullWidth
                                    id='proxy-autocomplete'
                                    options={osbotScripts}
                                    disabled={local || breakTask}
                                    getOptionLabel={(option) => option.scriptName + " - " + option.author}
                                    value={osbotScript}
                                    onChange={(event, newValue) => {
                                        setOsbotScript(newValue)
                                        // Your onChange code for the newValue
                                    }}
                                    isOptionEqualToValue={(option, value) => option.osbotSdnScriptKey === value.osbotSdnScriptKey} // Add this prop
                                    renderInput={(params) => <TextField {...params} label='Osbot script'/>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Button
                                variant="contained"
                                onClick={handleSubmitTask}
                                fullWidth
                                sx={{marginTop: "2rem", marginBottom: "1rem"}}
                            >
                                Save
                            </Button>
                        </Grid>
                    </>
                </Grid>
            </Box>
        </Modal>
    </>)
}
export default TaskModal;