import * as React from 'react';
import {useContext} from "react";
import {SettingContext} from "../../hooks/settingContext";
import NumberComponent from "../../comp/NumberComponent";
import {Grid} from "@mui/material";


const LevelTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <NumberComponent
                    label="Fishing Level"
                    jsonName="fishingLevel"
                    defaultValue={settings.fishingLevel}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <NumberComponent
                    label="Firemaking Level"
                    jsonName="firemakingLevel"
                    defaultValue={settings.firemakingLevel}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <NumberComponent
                    label="Cooking Level"
                    jsonName="cookingLevel"
                    defaultValue={settings.cookingLevel}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <NumberComponent
                    label="Crafting Level"
                    jsonName="craftingLevel"
                    defaultValue={settings.craftingLevel}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <NumberComponent
                    label="Woodcutting Level"
                    jsonName="woodcuttingLevel"
                    defaultValue={settings.woodcuttingLevel}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <NumberComponent
                    label="Mining Level"
                    jsonName="miningLevel"
                    defaultValue={settings.miningLevel}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <NumberComponent
                    label="Strength Level"
                    jsonName="strengthLevel"
                    defaultValue={settings.strengthLevel}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <NumberComponent
                    label="Attack Level"
                    jsonName="attackLevel"
                    defaultValue={settings.attackLevel}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <NumberComponent
                    label="Defence Level"
                    jsonName="defenceLevel"
                    defaultValue={settings.defenceLevel}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <NumberComponent
                    label="Range Level"
                    jsonName="rangeLevel"
                    defaultValue={settings.rangeLevel}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <NumberComponent
                    label="Magic Level"
                    jsonName="magicLevel"
                    defaultValue={settings.magicLevel}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <NumberComponent
                    label="Smithing Level"
                    jsonName="smithingLevel"
                    defaultValue={settings.smithingLevel}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default LevelTab;
