import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid, Typography} from "@mui/material";
import BooleanComponent from "../../comp/BooleanComponent";
import RangeComponent from "../../comp/RangeComponent";


const SmithingTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);


    return (
        <Grid container spacing={3}>
            <Grid item>
                <BooleanComponent
                    label="Use quest to skip early smithing levels"
                    jsonName="useQuestToSkipEarlySmithingLevels"
                    defaultValue={settings.useQuestToSkipEarlySmithingLevels}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Smithing time (seconds)"
                    jsonName="smithingTimeRange"
                    defaultValue={settings.smithingTimeRange}
                    changeState={updateSetting}
                />
            </Grid>

            <Grid item>
                <RangeComponent
                    label="Bronze Bar range"
                    jsonName="smithingBronzeBarRange"
                    defaultValue={settings.smithingBronzeBarRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Iron Bar range"
                    jsonName="smithingIronBarRange"
                    defaultValue={settings.smithingIronBarRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Steel Bar range"
                    jsonName="smithingSteelBarRange"
                    defaultValue={settings.smithingSteelBarRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Mithril Bar range"
                    jsonName="smithingMithrilBarRange"
                    defaultValue={settings.smithingMithrilBarRange}
                    changeState={updateSetting}
                />
            </Grid>

        </Grid>
    )
}

export default SmithingTab;
