export const mockApiData = [{
    name: "test",
    playerKey: "123",
    attack: "1",
    strength: "1",
    defense: "1",
    range: "1",
    magic: "1",
    firemaking: "1",
    prayer: "1",
    hitpoints: "1",
    agility: "1",
    herblore: "1",
    thieving: "1",
    crafting: "1",
    fletching: "1",
    slayer: "1",
    construction: "1",
    mining: "1",
    smithing: "1",
    fishing: "1",
    cooking: "1",
    woodcutting: "1",
    runecrafting: "1",
    farming: "1",
    hunter: "1",
    questPoints: "1",
    ban: false,
    string: "1",
    activity: "1",
    lastCheckIn: new Date(),
    coins: "1",
    membershipDays: "1",
    lastHiscoreSync: new Date()
},
    {
        name: "test1",
        playerKey: "1234",
        attack: "1",
        strength: "1",
        defense: "1",
        range: "1",
        magic: "1",
        firemaking: "1",
        prayer: "1",
        hitpoints: "1",
        agility: "1",
        herblore: "1",
        thieving: "1",
        crafting: "1",
        fletching: "1",
        slayer: "1",
        construction: "1",
        mining: "1",
        smithing: "1",
        fishing: "1",
        cooking: "1",
        woodcutting: "1",
        runecrafting: "1",
        farming: "1",
        hunter: "1",
        questPoints: "1",
        ban: false,
        string: "1",
        activity: "1",
        lastCheckIn: new Date(),
        coins: "1",
        membershipDays: "1",
        lastHiscoreSync: new Date()
    }];


export const mockOverviewPanelData = {
    totalActiveAccount24: 100,
    totalGold24: 88000000,
    totalActiveAccount72: 300,
    totalGold72: 880000000,
}