import React, {useEffect, useState} from 'react';
import {AppBar, Box, Toolbar, Typography} from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import {getItem, removeItem} from "../../utils/localStorage";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAllAvailableManagers} from "../../store/manager/actions";
import {State} from "../../store/reducers";
import {ManagerDTO} from "../../store/manager/types";

const handleDiscordClick = () => {
    window.location.href = "https://discord.gg/y9NGNwT8Ed";
};

const WebsiteName = () => {
    return (
        <Typography variant='h6' sx={{flexGrow: 1}}>
            <Box display="flex" alignItems="center" gap="10px">


                <img width="45" height="45"
                     src={"/assets/icons/Logo.png"}
                     alt="Image"/>
                InfinityFarmer
            </Box>
        </Typography>
    )

}

const ManagerDropDown = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [tryCount, setTryCount] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    const {managerKeyList} = useSelector((state: State) => ({
        managerKeyList: state.manager.managers
    }))


    useEffect(() => {
        if (managerKeyList.length === 0 && tryCount < 3) {
            console.log("Getting all managers")
            dispatch(getAllAvailableManagers());
            setTryCount(prevTryCount => prevTryCount + 1);
        }
    }, [dispatch, managerKeyList]);

    const handleManagerItemClick = (name: string) => {
        navigate("/manager/" + name + "/dashboard");
        handleClose()
    }


    return (
        <Box>
            <Button
                sx={{
                    backgroundColor: '#fe9933',
                    '&:hover': {
                        backgroundColor: '#fe9933',
                    },
                }}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained">
                Manager
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {managerKeyList.length > 0 && managerKeyList.map((item: ManagerDTO) => (
                    <MenuItem sx={{p: 2}} onClick={() => handleManagerItemClick(item.name)}>
                        <Typography textAlign="center">
                            {item.name}
                        </Typography>
                    </MenuItem>
                ))}
                {managerKeyList.length === 0 &&
                    <MenuItem sx={{p: 2}}>
                        <Typography textAlign="center">
                            No managers setup
                        </Typography>
                    </MenuItem>}
            </Menu>
        </Box>
    )


}

const DropDown = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    return (
        <Box>
            <MenuIcon
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                color="inherit"
                fontSize='large'
            />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem sx={{p: 2}} onClick={handleDiscordClick}>
                    <Typography textAlign="center">
                        Join Discord
                    </Typography>
                </MenuItem>
                <MenuItem sx={{p: 2}} onClick={handleClose}>
                    <Typography textAlign="center">
                        Settings
                    </Typography>
                </MenuItem>
            </Menu>
        </Box>
    )
}


const RightNav = () => {
    const navigate = useNavigate();

    const handleMangerClick = () => {
        navigate("/manager");
    };

    const handleOverviewClick = () => {
        navigate("/overview");
    };

    const handleNotesClick = () => {
        navigate("/notes");
    };

    const logout = () => {
        removeItem("apiFarmKey")
        navigate("/");
    }
    const loggedIn = getItem("apiFarmKey");
    return (
        <Box display="flex" gap="15px">
            {loggedIn ? null : <Button
                sx={{
                    backgroundColor: '#fe9933',
                    '&:hover': {
                        backgroundColor: '#fe9933',
                    },
                }}
                onClick={handleDiscordClick}
                variant="contained">
                Join Discord
            </Button>}

            {loggedIn ? (<Button
                sx={{
                    backgroundColor: '#fe9933',
                    '&:hover': {
                        backgroundColor: '#fe9933',
                    },
                }}
                onClick={handleOverviewClick}
                variant="contained">
                Overview
            </Button>) : null}

            {loggedIn ? (<ManagerDropDown/>) : null}

            {/*{loggedIn ? (<Button*/}
            {/*    sx={{*/}
            {/*        backgroundColor: '#fe9933',*/}
            {/*        '&:hover': {*/}
            {/*            backgroundColor: '#fe9933',*/}
            {/*        },*/}
            {/*    }}*/}
            {/*    onClick={handleMangerClick}*/}
            {/*    variant="contained">*/}
            {/*    Manager*/}
            {/*</Button>) : null}*/}

            {loggedIn ? <Button
                sx={{
                    backgroundColor: '#fe9933',
                    '&:hover': {
                        backgroundColor: '#fe9933',
                    },
                }}
                onClick={handleNotesClick}
                variant="contained">
                Notes
            </Button> : null}

            {loggedIn ? <Button
                sx={{
                    backgroundColor: '#fe9933',
                    '&:hover': {
                        backgroundColor: '#fe9933',
                    },
                }}
                onClick={logout}
                variant="contained">
                Logout
            </Button> : null}
            <DropDown/>
        </Box>
    )
}


const NavBar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const loggedIn = getItem("apiFarmKey");
        if (location.pathname !== "/" && loggedIn == null) {
            navigate("/")
        }
    }, [location]);
    return (
        <AppBar position="static" sx={{backgroundColor: "#060229"}}>
            <Toolbar>
                <WebsiteName/>
                <RightNav/>
            </Toolbar>
        </AppBar>
    );
};

export default NavBar;