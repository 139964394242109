import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import BooleanComponent from "../../comp/BooleanComponent";


const SystemTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <BooleanComponent
                    label="Prioritize Farm Runs"
                    jsonName="prioritizeFarmRuns"
                    defaultValue={settings.prioritizeFarmRuns}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Pause task timer during breaks"
                    jsonName="pauseTimerDuringBreaks"
                    defaultValue={settings.pauseTimerDuringBreaks}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Enable player tracking"
                    jsonName="enablePlayerTracking"
                    defaultValue={settings.enablePlayerTracking}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default SystemTab;
