import * as React from 'react';
import {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import NoteEditableSection from "../../components/page/notes/noteEditableSection";
import PlanCard from "../../components/page/notes/noteCardList";
import {useDispatch, useSelector} from "react-redux";
import {getAllNotes} from "../../store/notes/actions";
import {State} from "../../store/reducers";


const Notes = () => {

    const dispatch = useDispatch();
    const [selectedNoteKey, setSelectedNoteKey] = useState<string>('');

    const {noteList} = useSelector((state: State) => ({
        noteList: state.notes.notes
    }));

    useEffect(() => {
        dispatch(getAllNotes());
    }, [dispatch])


    return (
        <>
            <Grid container direction={{xs: "column", md: "row"}}>
                <Grid item xs={6} md={3}>
                    <PlanCard onClick={setSelectedNoteKey} noteList={noteList} selectedNoteKey={selectedNoteKey}/>
                </Grid>
                <Grid item xs={6} md={9}>
                    <NoteEditableSection noteKey={selectedNoteKey}/>
                </Grid>
            </Grid>
        </>
    );
}

export default Notes;