import * as React from 'react';
import {useEffect, useReducer} from 'react';
import {makeRequest} from "../../../../store/utils/api";
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel,
    Paper,
    Switch,
    TextField
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../store/reducers";
import {getAllActionPlans} from "../../../../store/manager/actions";
import {toast} from "react-toastify";
import {ActionPlanDTO} from "../../../../store/manager/types";
import OverviewCard from "../../overview/overviewCards";


type Props = {
    actionPlanKey: string

}
type LocalState = {
    actionPlan: ActionPlanDTO | null,
    loading: boolean,
    error: boolean
}

const initialState = {
    actionPlan: null,
    loading: false,
    error: false
};

const UPDATE_ACTION_CARD_DETAILS = "UPDATE_ACTION_CARD_DETAILS";
const UPDATE_ACTION_CARD_STATE = "UPDATE_ACTION_CARD_STATE";

const reducer = (state: LocalState, action: any) => {
        switch (action.type) {
            case 'FETCH_DATA_START':
                return {...state, loading: true, error: false};
            case 'FETCH_DATA_SUCCESS':
                return {...state, actionPlan: action.payload, loading: false};
            case 'FETCH_DATA_ERROR':
                return {...state, loading: false, error: action.payload};
            case 'FETCH_DATA_END':
                return {...state, loading: false};
            case UPDATE_ACTION_CARD_DETAILS:
                return {
                    ...state,
                    actionPlan: {
                        ...state.actionPlan,
                        [action.payload.property]: action.payload.value
                    }
                };
            case UPDATE_ACTION_CARD_STATE:
                return {
                    ...state,
                    [action.payload.property]: action.payload.value
                };
            default:
                return state;
        }
    }
;

const handleSave = async (reduxDispatch: any, dispatch: any, action: ActionPlanDTO, managerKey: string) => {
    dispatch({type: 'FETCH_DATA_START'});
    const response =
        await makeRequest("put",
            "/api/v1/actionplan", {
                ...action
            }
        );
    if (response.status !== 200) {
        dispatch({type: 'FETCH_DATA_ERROR'});
        return
    } else {
        reduxDispatch(getAllActionPlans(managerKey));
    }
    dispatch({type: 'FETCH_DATA_END'});

    toast.success("Successfully updated action plan!", {
        position: toast.POSITION.TOP_CENTER
    });
}

const fetchData = async (dispatch: any, actionPlanKey: string) => {
    if (actionPlanKey.length > 0) {
        dispatch({type: 'FETCH_DATA_START'});
        const response =
            await makeRequest("post",
                "/api/v1/actionplan/get", {
                    "actionPlanKey":
                    actionPlanKey
                }
            );
        if (response.status !== 200) {
            dispatch({type: 'FETCH_DATA_ERROR'});
            return
        } else {
            dispatch({type: 'FETCH_DATA_SUCCESS', payload: response.data});
        }
    }
}

const changeStateWrapper = (dispatch: any) => {
    return (key: string, value: any) => {
        dispatch({
            "type": UPDATE_ACTION_CARD_DETAILS,
            "payload": {
                "property": key,
                "value": value
            }
        })
    }
}


const ActionCardEditable = (props: Props) => {
    const reduxDispatch = useDispatch();
    const {actionPlanKey} = props;
    const [state, dispatch] = useReducer(reducer, initialState);
    const changeState = changeStateWrapper(dispatch);
    const {proxyList, planList, managerKey, overviewPlayerCards} = useSelector((state: State) => ({
        proxyList: state.manager.proxys,
        planList: state.manager.plans,
        managerKey: state.manager.managerKey,
        overviewPlayerCards: state.manager.overviewPlayerCards
    }))


    const {
        fps = 20,
        isMirror = false,
        world = 335,
        newMouse = false,
        memory = 512,
        reflection = false,
        lowCpu = false,
        lowResource = false,
        noRandoms = false,
        noInterface = false,
        noRender = false,
        randomF2pWorld = false,
        randomP2pWorld = false,
        message = null
    } = state.actionPlan ?? {};


    useEffect(() => {
        fetchData(dispatch, actionPlanKey);
    }, [actionPlanKey, dispatch])

    if (state.loading) {
        return (<Box></Box>)
    }
    console.log("action plan", state.actionPlan)


    return (<Container component={Paper} sx={{p: 4, marginTop: "5rem"}}>
        <FormControl fullWidth>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                    gap: "15px",
                }}
            >

                <FormControl sx={{width: '50%'}}>
                    <Autocomplete
                        id='proxy-autocomplete'
                        options={proxyList}
                        disabled={!state?.actionPlan}
                        getOptionLabel={(option) => option.alias + " - " + option.totalConnections}
                        value={state.actionPlan?.proxy}
                        onChange={(event, newValue) => {
                            dispatch({
                                "type": UPDATE_ACTION_CARD_DETAILS,
                                "payload": {
                                    "property": "proxy",
                                    "value": newValue
                                }
                            })
                        }}
                        isOptionEqualToValue={(option, value) => option.proxyKey === value.proxyKey} // Add this prop
                        renderInput={(params) => <TextField {...params} label='Proxy'/>}
                    />
                </FormControl>

                <FormControl sx={{width: '50%'}}>
                    <Autocomplete
                        id='plan-autocomplete'
                        options={planList}
                        disabled={!state?.actionPlan}
                        getOptionLabel={(option) => option.name}
                        value={state.actionPlan?.plan}
                        onChange={(event, newValue) => {
                            dispatch({
                                "type": UPDATE_ACTION_CARD_DETAILS,
                                "payload": {
                                    "property": "plan",
                                    "value": newValue
                                }
                            })
                            // Your onChange code for the newValue
                        }}
                        isOptionEqualToValue={(option, value) => option.planKey === value.planKey} // Add this prop
                        renderInput={(params) => <TextField {...params} label='Plan'/>}
                    />
                </FormControl>
            </Box>


            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                    gap: "15px",
                }}
            >
                <Box sx={{width: "50%"}}>
                    <FormLabel id="local-label">reflection</FormLabel>
                    <Switch
                        inputProps={{"aria-label": "controlled"}}
                        checked={reflection}
                        name="reflection"
                        onChange={(e) => changeState(e.target.name, e.target.checked)}
                    />
                </Box>
                <Box sx={{width: "50%"}}>
                    <FormLabel id="local-label">lowCpu</FormLabel>
                    <Switch
                        inputProps={{"aria-label": "controlled"}}
                        checked={lowCpu}
                        name="lowCpu"
                        onChange={(e) => changeState(e.target.name, e.target.checked)}
                    />
                </Box>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                    gap: "15px",
                }}
            >
                <Box sx={{width: "50%"}}>
                    <FormLabel id="local-label">lowResource</FormLabel>
                    <Switch
                        inputProps={{"aria-label": "controlled"}}
                        checked={lowResource}
                        disabled={true}
                        name="lowResource"
                        onChange={(e) => changeState(e.target.name, e.target.checked)}
                    />
                </Box>
                <Box sx={{width: "50%"}}>
                    <FormLabel id="local-label">noRandoms</FormLabel>
                    <Switch
                        inputProps={{"aria-label": "controlled"}}
                        checked={noRandoms}
                        disabled={true}
                        name="noRandoms"
                        onChange={(e) => changeState(e.target.name, e.target.checked)}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                    gap: "15px",
                }}
            >
                <Box sx={{width: "50%"}}>
                    <FormLabel id="local-label">noInterface</FormLabel>
                    <Switch
                        inputProps={{"aria-label": "controlled"}}
                        checked={noInterface}
                        disabled={true}
                        name="noInterface"
                        onChange={(e) => changeState(e.target.name, e.target.checked)}
                    />
                </Box>
                <Box sx={{width: "50%"}}>
                    <FormLabel id="local-label">noRender</FormLabel>
                    <Switch
                        inputProps={{"aria-label": "controlled"}}
                        checked={noRender}
                        name="noRender"
                        onChange={(e) => changeState(e.target.name, e.target.checked)}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                    gap: "15px",
                }}
            >
                <Box sx={{width: "50%"}}>
                    <FormLabel id="local-label">isMirror</FormLabel>
                    <Switch
                        inputProps={{"aria-label": "controlled"}}
                        checked={isMirror}
                        name="isMirror"
                        onChange={(e) => changeState(e.target.name, e.target.checked)}
                    />
                </Box>
                <Box sx={{width: "50%"}}>
                    <FormLabel id="local-label">newMouse</FormLabel>
                    <Switch
                        inputProps={{"aria-label": "controlled"}}
                        checked={newMouse}
                        name="newMouse"
                        onChange={(e) => changeState(e.target.name, e.target.checked)}
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                    gap: "15px",
                }}
            >
                <TextField
                    sx={{width: "50%"}}
                    type="number"
                    name="fps"
                    id="fps"
                    label="FPS"
                    variant="standard"
                    value={fps}
                    onChange={(e) => changeState(e.target.name, e.target.value)}
                />
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1rem",
                        gap: "15px",
                    }}
                >

                    <TextField
                        sx={{width: "50%"}}
                        type="number"
                        name="world"
                        id="world"
                        label="World"
                        variant="standard"
                        value={world}
                        onChange={(e) => changeState(e.target.name, e.target.value)}
                        disabled={randomF2pWorld || randomP2pWorld}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={randomF2pWorld}
                                onChange={(e) => changeState("randomF2pWorld", e.target.checked)}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        }
                        label="F2P"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={randomP2pWorld}
                                onChange={(e) => changeState("randomP2pWorld", e.target.checked)}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        }
                        label="P2P"
                    />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "1rem",
                    gap: "15px",
                }}
            >
                <TextField
                    sx={{width: "50%"}}
                    type="number"
                    name="memory"
                    id="memory"
                    label="Memory"
                    variant="standard"
                    value={memory}
                    onChange={(e) => changeState(e.target.name, e.target.value)}
                />

                <FormControl sx={{width: '50%'}}>
                    <Autocomplete
                        id='overview-player-card-autocomplete'
                        options={overviewPlayerCards}
                        getOptionLabel={(option) => option.name}
                        value={state.actionPlan?.overviewPlayer}
                        onChange={(event, newValue) => {
                            dispatch({
                                "type": UPDATE_ACTION_CARD_DETAILS,
                                "payload": {
                                    "property": "overviewPlayer",
                                    "value": newValue
                                }
                            })
                            // Your onChange code for the newValue
                        }}
                        isOptionEqualToValue={(option, value) => option.playerKey === value.playerKey} // Add this prop
                        renderInput={(params) => <TextField {...params} label='Overview Player Card'/>}
                    />
                </FormControl>
            </Box>

            <Button
                sx={{marginTop: "2rem"}}
                variant="contained"
                onClick={() => handleSave(reduxDispatch, dispatch, state.actionPlan, managerKey)}
            >
                Save
            </Button>
            {state.actionPlan?.overviewPlayer &&
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "1rem",
                        gap: "15px",
                    }}
                >
                    <OverviewCard itemData={state.actionPlan.overviewPlayer} hide={false} disableDots={true}/>
                </Box>
            }


        </FormControl>
    </Container>)

}

export default ActionCardEditable;