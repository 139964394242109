import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {FormLabel} from "@mui/material";

interface RangeComponentProps {
    label: string;
    defaultValue: string; // Format: 'number1-number2'
    jsonName: string; // JSON name to be used when saving
    changeState: (name: string, value: string) => void; // Function to save the value
}

function RangeComponent({defaultValue, jsonName, label, changeState}: RangeComponentProps) {
    const [string1, setString1] = useState('0');
    const [string2, setString2] = useState('0');
    const [error, setError] = useState({string1: false, string2: false});

    useEffect(() => {
        if (defaultValue) {
            const parts = defaultValue.split('-');
            if (parts.length === 2 && parts.every(part => !isNaN(parseFloat(part)))) {
                setString1(parts[0]);
                setString2(parts[1]);
            }
        }
    }, [defaultValue]);

    const handleBlur = () => {
        // Save the value using the provided saveValue function when the input loses focus
        changeState(jsonName, `${string1}-${string2}`);
    };

    const handleInputChange = (value: string, field: 'string1' | 'string2') => {
        // Check if the input is a valid number
        if (/^-?\d*\.?\d+$/.test(value)) {
            field === 'string1' ? setString1(value) : setString2(value);
        }
    };

    return (
        <div>
            <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap="10px"
            >
                <FormLabel id="local-label"
                           style={{fontSize: '15px', color: 'black', fontWeight: 'normal'}}>{label}</FormLabel>
                <TextField
                    value={string1}
                    size="small"
                    onBlur={handleBlur} // Use onBlur to save when the input loses focus
                    onChange={(e) => handleInputChange(e.target.value, 'string1')} // Use onChange to handle input changes
                    inputProps={{
                        'aria-label': 'Number 1',
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        style: {textAlign: 'center'},
                    }}
                />
                -
                <TextField
                    value={string2}
                    size="small"
                    onBlur={handleBlur} // Use onBlur to save when the input loses focus
                    onChange={(e) => handleInputChange(e.target.value, 'string2')} // Use onChange to handle input changes
                    inputProps={{
                        'aria-label': 'Number 2',
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        style: {textAlign: 'center'},
                    }}
                />
            </Box>
        </div>
    );
}

export default RangeComponent;
