import * as React from "react";
import {
    Box,
    Card,
    CardActions,
    CardContent,
    Typography,
    Grid, styled,
} from "@mui/material";
import BasicDropDown from "../../../kebabDropDown";
import {useDispatch} from "react-redux";
import {OverViewSkillProps} from "../../../commonProps";
import {deletePlayer, forceUpdatePlayerHiscore} from "../../../../store/overview/actions";


const StyledCard = styled(Card)(({theme}) => ({
    borderRadius: "13px",
    width: "388px",
}))

const StyledContent = styled(CardContent)(({theme}) => ({
    borderBottom: "3px solid #edf0f7",
    width: "388px",
    padding: "8px",
    paddingTop: "12px",

}))

const StyledHeader = styled(Box)(({theme}) => ({
    fontSize: theme.typography.h6.fontSize,
    display: "flex",
    justifyContent: "center",
    paddingRight: "15px",

}));

const StyledCardActions = styled(CardActions)(({theme}) => ({
    padding: 0,
}));


const BoxIcon = () => {
    return (
        <Box className="botsicon" style={{margin: "-7px 10px"}}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30.753"
                height="29.795"
                viewBox="0 0 30.753 29.795"
            >
                <g id="bot" transform="translate(0 0)">
                    <g id="Page-1_9_" transform="translate(0 0)">
                        <g id="_x30_10---Chat-Bot-Head" transform="translate(0 0)">
                            <path
                                id="Shape_60_"
                                d="M0,248.437v3.075a1.538,1.538,0,0,0,1.538,1.538H2.05V246.9H1.538A1.538,1.538,0,0,0,0,248.437Z"
                                transform="translate(0 -232.548)"
                                fill="#fe9933"
                            />
                            <path
                                id="Shape_59_"
                                d="M478.379,246.9h-.513v6.151h.513a1.538,1.538,0,0,0,1.538-1.538v-3.075A1.538,1.538,0,0,0,478.379,246.9Z"
                                transform="translate(-449.164 -232.548)"
                                fill="#fe9933"
                            />
                            <path
                                id="Shape_58_"
                                d="M218.455,10.535a2.571,2.571,0,0,1-2.05,2.511v2.614H215.38V13.047a2.563,2.563,0,1,1,3.075-2.511Z"
                                transform="translate(-200.516 -7.973)"
                                fill="#fe9933"
                            />
                            <ellipse
                                id="Oval_17_"
                                cx="1.538"
                                cy="1.538"
                                rx="1.538"
                                ry="1.538"
                                transform="translate(19.989 14.351)"
                                fill="#fe9933"
                            />
                            <ellipse
                                id="Oval_16_"
                                cx="1.538"
                                cy="1.538"
                                rx="1.538"
                                ry="1.538"
                                transform="translate(7.688 14.351)"
                                fill="#fe9933"
                            />
                            <path
                                id="Shape_57_"
                                d="M72.727,153.033H54.275a3.075,3.075,0,0,0-3.075,3.075v11.276a3.075,3.075,0,0,0,3.065,3.075.517.517,0,0,1,.523.513v2.63a.513.513,0,0,0,.8.427l5.1-3.4a1.025,1.025,0,0,1,.566-.172H72.727a3.075,3.075,0,0,0,3.075-3.075V156.108A3.075,3.075,0,0,0,72.727,153.033Zm-15.376,9.738a2.563,2.563,0,1,1,2.563-2.563A2.563,2.563,0,0,1,57.351,162.771Zm9.148,1.809a3.425,3.425,0,0,1-3,1.266,3.425,3.425,0,0,1-3-1.266.513.513,0,0,1,.865-.549,3.27,3.27,0,0,0,4.265,0,.517.517,0,0,1,.707-.147A.509.509,0,0,1,66.5,164.581Zm3.153-1.809a2.563,2.563,0,1,1,2.563-2.563A2.563,2.563,0,0,1,69.652,162.771Z"
                                transform="translate(-48.125 -144.32)"
                                fill="#fe9933"
                            />
                        </g>
                    </g>
                </g>
            </svg>
        </Box>
    )
}

const StyledIcon = styled('img')({
    width: '16px',
    margin: '3px 2px',
    height: '16px',
});

type Props = {
    itemData: OverViewSkillProps,
    hide: boolean,
    disableDots?: boolean
}

const OverviewCard = (props: Props) => {
    const {itemData, hide, disableDots} = props;
    const dispatch = useDispatch();
    let IconData = [
        {
            iconimage: "/assets/icons/skill_icon_Attack.png",
            values: itemData?.attack || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Strength.png",
            values: itemData?.strength || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Defence.png",
            values: itemData?.defense || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Ranged.png",
            values: itemData?.range || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Magic.png",
            values: itemData?.magic || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Firemaking.png",
            values: itemData?.firemaking || "0",
        },
        // 2nd row
        {
            iconimage: "/assets/icons/skill_icon_Hitpoints.png",
            values: itemData?.hitpoints || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Agility.png",
            values: itemData?.agility || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Herblore.png",
            values: itemData?.herblore || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Thieving.png",
            values: itemData?.thieving || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Crafting.png",
            values: itemData?.crafting || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Woodcutting.png",
            values: itemData?.woodcutting || "0",
        },
        // 3rd row
        {
            iconimage: "/assets/icons/skill_icon_Hunter.png",
            values: itemData?.hunter || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Mining.png",
            values: itemData?.mining || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Smithing.png",
            values: itemData?.smithing || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Fishing.png",
            values: itemData?.fishing || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Cooking.png",
            values: itemData?.cooking || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Farming.png",
            values: itemData?.farming || "0",
        },
        // 4th
        {
            iconimage: "/assets/icons/skill_icon_Runecraft.png",
            values: itemData?.runecrafting || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Construction.png",
            values: itemData?.construction || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Prayer.png",
            values: itemData?.prayer || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Fletching.png",
            values: itemData?.fletching || "0",
        },
        {
            iconimage: "/assets/icons/skill_icon_Slayer.png",
            values: itemData?.slayer || "0",
        },
        {
            iconimage: "/assets/icons/Quests_Points.webp",
            values: itemData?.questPoints || "0",
        },
    ];

    const convertToLocalTime = (utcTimestamp: Date): string => {
        const localDate = new Date(utcTimestamp);
        const date = localDate.toLocaleDateString([], {year: undefined, month: '2-digit', day: '2-digit'});
        const time = localDate.toLocaleTimeString([], {hour12: false});
        return ` ${date} ${time}`;
    };

    const handleDeleteClick = (playerKey: string) => {
        dispatch(deletePlayer(playerKey));
    }

    const handleForceUpdate = (playerKey: string) => {
        dispatch(forceUpdatePlayerHiscore(playerKey));
    }

    const optionList = disableDots ? [] : [
        {
            text: "Delete bot",
            value: props?.itemData?.playerKey || "",
            onClick: (playerKey: string) => {
                handleDeleteClick(playerKey)
            },
        },
        {
            text: "Force HS update",
            value: props?.itemData?.playerKey || "",
            onClick: (playerKey: string) => {
                handleForceUpdate(playerKey)
            },
        }
    ]

    return (
        <StyledCard>
            <StyledContent>
                <StyledHeader>
                    <Box display="flex" flexGrow="1" justifyContent="center">
                        <BoxIcon/>
                        {hide ? "**********" : itemData?.name}
                    </Box>
                    <BasicDropDown options={optionList}/>
                </StyledHeader>

                <StyledCardActions>
                    <Grid container columns={{xs: 12, sm: 12, md: 12}}>
                        <Grid item xs={4}>
                            <Typography component="h1">
                                Ban?:{" "}
                                <b>
                                    {itemData?.ban ? (
                                        <b style={{color: "red"}}>Ban</b>
                                    ) : (
                                        <b style={{color: "#35C21C"}}>Not Ban</b>
                                    )}
                                </b>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>
                                Membership:{" "}
                                <b>
                                    {itemData?.membershipDays
                                        ? itemData.membershipDays
                                        : "Unknown" || ""}
                                </b>
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>
                                Gold: <b>{itemData?.coins || ""}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography>
                                Last Check-In:{" "}
                                {/* <b>{(itemdata?.lastCheckIn).substring(11, 19) || ""}  {(itemdata?.lastCheckIn).substring(0,9) || ""}</b> */}
                                <b>
                                    {itemData?.lastCheckIn
                                        ? convertToLocalTime(itemData.lastCheckIn)
                                        : ""}
                                </b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>
                                Activity:{" "}
                                <b>
                                    {itemData?.activity
                                        ? itemData?.activity
                                        : "Unknown"}
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                </StyledCardActions>
            </StyledContent>


            <CardActions>
                <Grid
                    container
                    columns={{xs: 6, sm: 6, md: 12}}
                    style={{textAlign: "center", marginTop: "-14px"}}
                >
                    {IconData.map((item, index) => {
                        return (
                            <Grid style={{display: "flex", paddingTop: "10px"}} item xs={1} md={2} key={index}>
                                <StyledIcon
                                    alt={item.values}
                                    src={item.iconimage}
                                />
                                <Typography sx={{marginLeft: "3px"}}>
                                    <b>{item.values}</b>
                                </Typography>
                            </Grid>
                        );
                    })}
                    <Grid style={{display: "flex", marginLeft: "3px", fontSize: "12px"}}
                          item xs={12}
                          md={12} key={"info"}>
                        HS: {convertToLocalTime(itemData?.lastHiscoreSync)} UTC
                    </Grid>
                </Grid>
            </CardActions>
        </StyledCard>
    );
}

export default OverviewCard;