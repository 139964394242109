import * as React from "react";

export default function AscIcon() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.983"
        height="19.145"
        viewBox="0 0 25.983 19.145"
      >
        <g id="sort-ascending" transform="translate(-3 -6)">
          <path
            id="Path_42259"
            data-name="Path 42259"
            d="M3,17.368A1.368,1.368,0,0,1,4.368,16h8.205a1.368,1.368,0,1,1,0,2.735H4.368A1.368,1.368,0,0,1,3,17.368Z"
            transform="translate(0 3.675)"
            fill="#fff"
            fill-rule="evenodd"
          />
          <path
            id="Path_42260"
            data-name="Path 42260"
            d="M3,12.368A1.368,1.368,0,0,1,4.368,11H16.675a1.368,1.368,0,1,1,0,2.735H4.368A1.368,1.368,0,0,1,3,12.368Z"
            transform="translate(0 1.838)"
            fill="#fff"
            fill-rule="evenodd"
          />
          <path
            id="Path_42261"
            data-name="Path 42261"
            d="M18.368,10a1.368,1.368,0,0,1,1.368,1.368v10.94a1.368,1.368,0,0,1-2.735,0V11.368A1.368,1.368,0,0,1,18.368,10Z"
            transform="translate(5.145 1.47)"
            fill="#fff"
            fill-rule="evenodd"
          />
          <path
            id="Path_42262"
            data-name="Path 42262"
            d="M14.4,15.4a1.368,1.368,0,0,1,1.934,0l3.136,3.136L22.606,15.4a1.367,1.367,0,1,1,1.934,1.934l-4.1,4.1a1.368,1.368,0,0,1-1.934,0l-4.1-4.1a1.368,1.368,0,0,1,0-1.934Z"
            transform="translate(4.043 3.308)"
            fill="#fff"
            fill-rule="evenodd"
          />
          <path
            id="Path_42263"
            data-name="Path 42263"
            d="M3,7.368A1.368,1.368,0,0,1,4.368,6h16.41a1.368,1.368,0,0,1,0,2.735H4.368A1.368,1.368,0,0,1,3,7.368Z"
            fill="#fff"
            fill-rule="evenodd"
          />
        </g>
      </svg>
    </>
  );
}
