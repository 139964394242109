import React, {useState} from "react";
import {
    Box,
    Button,
    FormControl,
    MenuItem,
    Modal,
    Select,
    SelectChangeEvent,
    styled,
    TextField,
    Typography
} from "@mui/material";
import Switch from '@mui/material/Switch';
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../store/reducers";
import {addPlayerManual, fetchSearchPayload, performSearch, UPDATE_OVERVIEW_STATE} from "../../store/overview/actions";
import DecendingIcon from "../../components/svgIcons/DecendingIcon";
import AscIcon from "../../components/svgIcons/AscIcon";
import {Refresh} from "@mui/icons-material";

const StyledContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    marginTop: "10px",
    flexWrap: "wrap",
    gap: "20px",
})


const StyledBox = styled(Box)({
    display: "flex",
    gap: "20px",
    width: "100%",
    maxWidth: "700px",
    flexWrap: "wrap",
})

const StyledSwitchSection = styled(Box)({
    display: "flex",
    alignItems: "center",
    fontSize: "18px",
})


const StyledDropDown = styled(FormControl)({
    maxWidth: "350px",
    width: "100%",
    color: "black"

})

const StyledModalContainer = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
    display: "flex",
    gap: "20px",
    flexDirection: "column"
};

const ControlsSection = () => {

    const [open, setOpen] = React.useState(false);
    const [botName, setBotName] = useState('');

    const dispatch = useDispatch();

    const {hideBotName, dropDownValue, sortingDirection, searchPayload} = useSelector((state: State) => ({
        hideBotName: state.overview.hideBotName,
        dropDownValue: state.overview.searchPayload.sortFilter,
        sortingDirection: state.overview.searchPayload.sorting,
        searchPayload: state.overview.searchPayload,
    }));

    const onHideBotNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const property = 'hideBotName';
        dispatch({
            type: UPDATE_OVERVIEW_STATE,
            payload: {
                property: property,
                value: event.target.checked,
            }
        })
    }

    const handleDropDownChange = (event: SelectChangeEvent<string>) => {
        dispatch(fetchSearchPayload('sortFilter', event.target.value));
    };

    const handleRefresh = () => {
        dispatch(performSearch(searchPayload));
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setBotName('')
    };

    const handleSortingDirection = (value: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
        dispatch(fetchSearchPayload('sorting', value));
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBotName(event.target.value);
    };

    const handleSave = () => {
        console.log('Bot name:', botName);
        if(botName.length > 0){
            dispatch(addPlayerManual(botName))
        }
        handleClose()
    };


    return (
        <StyledContainer>
            {/*<CenteredStyledBox>*/}
            <StyledSwitchSection>
                Hide Bot name
                <Switch
                    checked={hideBotName}
                    onChange={onHideBotNameChange}
                />
            </StyledSwitchSection>
            <StyledBox>
                <StyledDropDown>
                    <Select
                        value={dropDownValue}
                        onChange={handleDropDownChange}
                        defaultValue={"BOT_NAME"}
                        inputProps={{'aria-label': 'Without label'}}
                    >
                        <MenuItem value="BOT_NAME">Bot Name</MenuItem>
                        <MenuItem value="MEMBERSHIP">Membership</MenuItem>
                        <MenuItem value="BAN_STATUS">Ban status</MenuItem>
                        <MenuItem value="LAST_CHECK_IN">Last check-in</MenuItem>

                    </Select>
                </StyledDropDown>

                {sortingDirection === 'DESC' ?
                    <Button
                        variant="contained"
                        onClick={handleSortingDirection("ASC")}
                        size={"large"}
                    >
                        <DecendingIcon/>
                    </Button>
                    :
                    <Button
                        variant="contained"
                        onClick={handleSortingDirection("DESC")}
                        size={"large"}
                        // size={"large"}
                    >
                        <AscIcon/>
                    </Button>}
                <Button
                    variant="contained"
                    onClick={handleRefresh}
                    endIcon={<Refresh style={{color: 'white'}}/>}
                    size={"large"}
                >
                    Refresh
                </Button>
            </StyledBox>
            <Button
                variant="contained"
                size={"large"}
                onClick={handleOpen}
            >
                Add bot
            </Button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={StyledModalContainer}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" textAlign={"center"}>
                        Enter bot name
                    </Typography>

                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Bot name"
                        variant="outlined"
                        value={botName}
                        onChange={handleInputChange}
                    />
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleSave}
                    >
                        Save
                    </Button>
                </Box>
            </Modal>
        </StyledContainer>
    )
}

export default ControlsSection;