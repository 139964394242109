import * as React from 'react';
import {useState} from 'react';
import {
    Box,
    Button,
    Card,
    Container,
    IconButton,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    TextField,
    useTheme
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Swal from "sweetalert2";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../../store/reducers";
import {ProfileDTO} from "../../../../store/manager/types";
import {createNewProfile, deleteProfile} from "../../../../store/profiles/actions";
import {LoadingImage, StyledLoadingScreen} from "../../../loading/LoadingImage";
import {useTablePagination} from "../../../hooks/tablePaginationHook";


type Props = {
    selectedProfileKey?: string,
    title: string,
    profileList: ProfileDTO[],
    onClick: (key: string) => void
    p2pAio: boolean,
    f2pAio: boolean,

}

const ProfileCardList = (props: Props) => {
    const {profileList, onClick, selectedProfileKey, p2pAio, f2pAio} = props;

    const theme = useTheme();
    const dispatch = useDispatch();

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [open, setOpen] = useState(false);
    const [profileName, setProfileName] = useState<string>('');


    const searchFilteredProfileList = profileList.filter((item) => {
        return item.name
            .toLowerCase()
            .startsWith(searchTerm.toLowerCase());
    })

    const {loading} = useSelector((state: State) => ({
        loading: state.manager.loading
    }))

    const handleSweetAlert = (key: string) => {
        Swal.fire({
            title: "Are you sure to delete this profile",
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            icon: "warning",
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteProfile(key));
            }
        });
    }

    const handleCreatePlanModelClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        const newProfileName = profileName.replace(/\s+/g, '');
        dispatch(createNewProfile(newProfileName, p2pAio, f2pAio));
        setOpen(false);
        setProfileName("");
    };

    const {
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage
    } = useTablePagination();

    if (loading) {
        return (
            <StyledLoadingScreen>
                <LoadingImage/>
            </StyledLoadingScreen>)
    }

    return (
        <Container sx={{p: {xs: 0, md: 4}}}>

            <Card sx={{p: 2}}>
                <Box display="flex" gap="20px" justifyContent="space-between">
                    <TextField
                        sx={{fontSize: 14, width: "80%"}}
                        id="outlined-basic"
                        value={searchTerm}
                        label={<SearchIcon/>}
                        variant="outlined"
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Button variant="contained" onClick={() => setOpen(true)}>
                        Create Profile
                    </Button>
                </Box>

                <TableContainer>
                    <Table aria-label="a dense table">
                        <TableHead sx={{th: {fontWeight: "700"}}}>
                            <TableRow>
                                <TableCell align="center">Name</TableCell>
                                <TableCell align="center">delete</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {searchFilteredProfileList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index: number) => {
                                return (
                                    <TableRow
                                        key={item.profileKey}
                                        onClick={() => {
                                            onClick(item.profileKey);
                                        }}
                                        sx={{
                                            cursor: "pointer",
                                            backgroundColor:
                                                selectedProfileKey === item.profileKey
                                                    ? theme.palette.primary.main
                                                    : "#fff",
                                            td: {
                                                color:
                                                    selectedProfileKey === item.profileKey
                                                        ? "#fff"
                                                        : "#060229",
                                            },
                                        }}
                                    >
                                        <TableCell align="center" scope="row">
                                            {item.name}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                onClick={() => handleSweetAlert(item.profileKey)}
                                                sx={{padding: 0}}
                                            >
                                                <DeleteOutlineIcon/>
                                            </IconButton>

                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={searchFilteredProfileList.length} // Total number of data
                    rowsPerPage={rowsPerPage} // Number of rows per page
                    page={page} // Current page
                    onPageChange={handleChangePage} // Function to handle page changes
                    onRowsPerPageChange={handleChangeRowsPerPage} // Function to handle changes in rows per page
                    labelRowsPerPage="Rows per page" // Description text for "rows per page" selector
                />
            </Card>

            <Modal
                open={open}
                onClose={handleCreatePlanModelClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <TextField
                        label="Profile Name"
                        value={profileName}
                        onChange={(e) => setProfileName(e.target.value)}
                    />
                    <Button variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                </Box>
            </Modal>
        </Container>
    );
}

export default ProfileCardList;