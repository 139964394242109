import {makeRequest} from "../utils/api";
import {getItem} from "../../utils/localStorage";
import {AppDispatch} from "../index";
import {ActionPlanDTO, ManagerDTO, PlanDTO, ProxyDTO} from "./types";

export const FETCH_API_STARTED = 'FETCH_API_STARTED';
export const FETCH_API_ENDED = 'FETCH_API_ENDED';
export const FETCH_ALL_MANAGERS_SUCCESS = 'FETCH_ALL_MANAGERS_SUCCESS';
export const FETCH_ALL_ACTION_PLANS_SUCCESS = 'FETCH_ALL_ACTION_PLANS_SUCCESS';
export const FETCH_ALL_OVERVIEW_PLAYER_CARDS_SUCCESS = 'FETCH_ALL_OVERVIEW_PLAYER_CARDS_SUCCESS';
export const FETCH_ALL_OSBOT_SCRIPT_SUCCESS = 'FETCH_ALL_OSBOT_SCRIPT_SUCCESS';
export const FETCH_ALL_PLANS_SUCCESS = 'FETCH_ALL_PLANS_SUCCESS';
export const FETCH_ALL_PROXYS_SUCCESS = 'FETCH_ALL_PROXYS_SUCCESS';
export const FETCH_API_FAILURE = 'FETCH_API_FAILURE';
export const UPDATE_MANAGER_STATE = 'UPDATE_MANAGER_STATE';


export const fetchAllManagersSuccess = (payload: ManagerDTO[]) => ({
    type: FETCH_ALL_MANAGERS_SUCCESS,
    payload: payload,
});

export const fetchAllPlansSuccess = (payload: PlanDTO[]) => ({
    type: FETCH_ALL_PLANS_SUCCESS,
    payload: payload,
});


export const fetchAllProxysSuccess = (payload: ProxyDTO[]) => ({
    type: FETCH_ALL_PROXYS_SUCCESS,
    payload: payload,
});

export const fetchAllActionPlansSuccess = (payload: ActionPlanDTO[]) => ({
    type: FETCH_ALL_ACTION_PLANS_SUCCESS,
    payload: payload,
});
export const fetchAllOverviewPlayerCardsSuccess = (payload: ActionPlanDTO[]) => ({
    type: FETCH_ALL_OVERVIEW_PLAYER_CARDS_SUCCESS,
    payload: payload,
});

export const fetchAllOsbotScriptsSuccess = (payload: ManagerDTO[]) => ({
    type: FETCH_ALL_OSBOT_SCRIPT_SUCCESS,
    payload: payload,
});

export const fetchApiStarted = () => ({
    type: FETCH_API_STARTED,
});

export const fetchApiEnded = () => ({
    type: FETCH_API_ENDED,
});
export const fetchApiError = () => ({
    type: FETCH_API_FAILURE,
});


const payload = (apiFarmKey: string) => {
    return ({
        apiFarmKey: apiFarmKey
    })
};

export const getAllAvailableManagers = (): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted());
        const response = await makeRequest(
            'post',
            '/api/v1/farm/manager/all',
            payload(getItem('apiFarmKey') as string),
        );
        if (response.status !== 200) {
            dispatch(fetchApiError());
            return;
        }
        dispatch(fetchAllManagersSuccess(response.data));
        dispatch(fetchApiEnded());
    };
};

export const getAllProxys = (managerKey: string): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted())
        const response =
            await makeRequest("post",
                "/api/v1/proxy/get/all", {
                    "managerKey": managerKey
                });

        if (response.status !== 200) {
            dispatch(fetchApiError());
            return
        }
        dispatch(fetchAllProxysSuccess(response.data))
        dispatch(fetchApiEnded())
    }
}

export const getAllOverviewPlayerCards = (): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted())
        const response =
            await makeRequest("post",
                "/api/v1/player/get/all", null);

        if (response.status !== 200) {
            dispatch(fetchApiError());
            return
        }
        dispatch(fetchAllOverviewPlayerCardsSuccess(response.data))
        dispatch(fetchApiEnded())
    }
}
export const getAllPlans = (managerKey: string): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted())
        const response =
            await makeRequest("post",
                "/api/v1/plan/get/all", {
                    "managerKey": managerKey
                });

        if (response.status !== 200) {
            dispatch(fetchApiError());
            return
        }
        dispatch(fetchAllPlansSuccess(response.data))
        dispatch(fetchApiEnded())
    }
}


export const getAllAvailableOsbotScript = (): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted())
        const response =
            await makeRequest("get",
                "/api/v1/script/osbot/sdn/get/all", {});
        if (response.status !== 200) {
            dispatch(fetchApiError());
            return
        }
        dispatch(fetchAllOsbotScriptsSuccess(response.data))
        dispatch(fetchApiEnded())
    }
};

export const getAllActionPlans = (managerKey: string): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted())
        const response =
            await makeRequest("post",
                "/api/v1/actionplan/get/all", {
                    "managerKey": managerKey
                });

        if (response.status !== 200) {
            dispatch(fetchApiError());
            return
        }
        dispatch(fetchAllActionPlansSuccess(response.data))
        dispatch(fetchApiEnded())
    }
}

export const turnOnActionPlan = (actionPlanKeys: string[], managerKey: string): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted())

        const payload = actionPlanKeys.map((item) => (
                {
                    "actionPlanKey": item
                }
            )
        )

        const response =
            await makeRequest("post",
                "/api/v1/actionplan/turn/online", payload
            );

        if (response.status !== 200) {
            dispatch(fetchApiError());
            return
        } else {
            dispatch(getAllActionPlans(managerKey));
        }
    }
}

export const turnOffActionPlan = (actionPlanKeys: string[], managerKey: string): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted())

        const payload = actionPlanKeys.map((item) => (
                {
                    "actionPlanKey": item
                }
            )
        )
        const response =
            await makeRequest("post",
                "/api/v1/actionplan/turn/offline", payload);

        if (response.status !== 200) {
            dispatch(fetchApiError());
            console.log("made it here");
            return
        } else {
            dispatch(getAllActionPlans(managerKey));
        }
    }
}

export const turnArchiveActionPlan = (actionPlanKeys: string[], managerKey: string): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted())

        const payload = actionPlanKeys.map((item) => (
                {
                    "actionPlanKey": item
                }
            )
        )

        const response =
            await makeRequest("post",
                "/api/v1/actionplan/turn/archive", payload
            );

        if (response.status !== 200) {
            dispatch(fetchApiError());
            return
        } else {
            dispatch(getAllActionPlans(managerKey));
            dispatch(getAllProxys(managerKey))
        }
    }
}
export const turnUnArchiveActionPlan = (actionPlanKeys: string[], managerKey: string): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted())

        const payload = actionPlanKeys.map((item) => (
                {
                    "actionPlanKey": item
                }
            )
        )

        const response =
            await makeRequest("post",
                "/api/v1/actionplan/turn/unarchive", payload
            );

        if (response.status !== 200) {
            dispatch(fetchApiError());
            return
        } else {
            dispatch(getAllActionPlans(managerKey));
            dispatch(getAllProxys(managerKey))
        }
    }
}

export const deletePlan = (planKey: string, managerKey: string): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted())
        const response =
            await makeRequest("post",
                "/api/v1/plan/delete", {
                    "planKey": planKey,
                    "managerKey": managerKey
                });

        if (response.status !== 204) {
            dispatch(fetchApiError());
            return
        } else {
            dispatch(getAllPlans(managerKey));
        }
    }
}

export const createPlan = (planName: string, managerKey: string, loop: boolean): any => {
    return async (dispatch: AppDispatch) => {
        dispatch(fetchApiStarted())
        const response =
            await makeRequest("post",
                "/api/v1/plan", {
                    "name": planName,
                    "managerKey": managerKey,
                    "loop": loop,
                });

        if (response.status !== 200) {
            dispatch(fetchApiError());
            return
        } else {
            dispatch(getAllPlans(managerKey));
        }
    }
}







