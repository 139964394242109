import * as React from 'react';
import {Box, Button, Select, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuItem from "@mui/material/MenuItem";
import {useContext} from "react";
import {ActionPlanContext} from "../../hooks/actionPlanContext";

type Props = {
    searchTerm: string,
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    sortColumnValue: number,
    archivedPlan: boolean,
    handleChangeOnSortColumn: (event: any) => void,
    handleButtonArchiveOrUnarchive: () => void,
}


const ActionCardHeader = (props: Props) => {
    const {
        searchTerm,
        setSearchTerm,
        archivedPlan,
        sortColumnValue,
        handleChangeOnSortColumn,
        handleButtonArchiveOrUnarchive
    } = props;

    const context = useContext(ActionPlanContext);
    const {bulkUpdateEnabled, setBulkUpdate, archivedActionPlanCardEnabled} = context;


    return (
        <Box display="flex" flexDirection="row" gap="8px">
            <TextField

                sx={{fontSize: 14, flexGrow: 1}}
                id="outlined-basic"
                value={searchTerm}
                label={<SearchIcon/>}
                variant="outlined"
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            <Select
                value={sortColumnValue}
                onChange={handleChangeOnSortColumn}
            >
                <MenuItem value={1}>Name</MenuItem>
                <MenuItem value={2}>Proxy Name</MenuItem>
                <MenuItem value={3}>Plan Name</MenuItem>
                <MenuItem value={4}>message</MenuItem>
            </Select>

            <Button variant={"contained"}
                    onClick={() => {
                        setBulkUpdate(!bulkUpdateEnabled);
                    }}
                    size="large"
                    color={bulkUpdateEnabled ? "error" : "primary"}
                    disabled={archivedActionPlanCardEnabled}>
                {bulkUpdateEnabled ? "Cancel update" : "Bulk update"}
            </Button>
            <Button variant={"contained"} onClick={handleButtonArchiveOrUnarchive} size="large"
                    disabled={bulkUpdateEnabled}>
                {archivedPlan ? "unarchive" : "archive"}
            </Button>

        </Box>
    )
}


export default ActionCardHeader;
