import React, {createContext, useContext, useEffect, useState} from 'react';
import {ActionPlanDTO, ProfileDTO} from "../../../../../store/manager/types";
import {updateProfile} from "../../../../../store/profiles/actions";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {LoadingImage, StyledLoadingScreen} from "../../../../loading/LoadingImage";


export interface Settings {

    // system level
    prioritizeFarmRuns: boolean
    pauseTimerDuringBreaks: boolean
    enablePlayerTracking: boolean


    // breaking
    startPlayTime: number
    endPlayTime: number
    startBreakTime: number
    endBreakTime: number

    // quests
    doricsQuest: boolean
    blackKnightFortressQuest: boolean
    cooksAssistantQuest: boolean
    druidicRitualQuest: boolean
    goblinDiplomacyQuest: boolean
    plagueCityQuest: boolean
    priestAndPerilQuest: boolean
    restlessGhostQuest: boolean
    sheepShearerQuest: boolean
    impCatcherQuest: boolean
    ernestTheChickenQuest: boolean
    theKnightsSwordQuest: boolean


    // levels
    agilityLevel: number
    fishingLevel: number
    firemakingLevel: number
    cookingLevel: number
    craftingLevel: number
    woodcuttingLevel: number
    miningLevel: number
    farmingLevel: number
    fletchingLevel: number
    strengthLevel: number
    attackLevel: number
    defenceLevel: number
    thievingLevel: number
    rangeLevel: number


    // crafting
    craftingLeatherRange: string
    craftingGemRange: string
    craftingMoltenGlassRange: string
    craftingTimeRange: string

    // farming
    farmingAllotmentRunRange: string
    farmingHerbRunRange: string
    farmingFlowerRunRange: string
    farmingCompostRange: string
    farmingTeleportTabRange: string

    // mining
    useQuestToSkipEarlyMiningLevels: boolean
    miningMlmBankGems: boolean
    miningMlmDepositBox: boolean
    miningMlmBuyCoalBagAndStop: boolean
    miningMlmBuyGear: boolean
    miningMlmUnlockUpstairs: boolean
    miningMlmUnlockUpstairsBeforeGear: boolean
    miningEnableDragonPickaxe: boolean
    miningTimeRange: string

    // cooking
    cookingSardineRange: string
    cookingTroutRange: string
    cookingTunaRange: string
    cookingSwordfishRange: string
    cookingMonkfishRange: string
    cookingSharkRange: string
    cookingAnglerfishRange: string
    cookingSellRawHighLevelFish: boolean
    cookingSellCookedHighLevelFish: boolean
    cookingTimeRange: string

    // fishing
    fishingFeatherLowLevelRange: string
    fishingFeatherBarbRange: string
    fishingTimeLowLevelRange: string
    fishingTimeBarbLevelRange: string

    // firemaking
    firemakingNormalLogRange: string
    firemakingOakLogRange: string
    firemakingWillowLogRange: string
    firemakingMapleLogRange: string
    firemakingRedwoodLogRange: string
    firemakingTimeRange: string

    // combat
    meleeBuyAllItemAtOneTime: boolean
    meleeTimeRange: string
    meleeBalanceLevels: boolean

    // woodcutting
    woodcuttingTimeRange: string
    woodcuttingEnableDragonAxe: boolean
    woodcuttingBuyAllAxesAtOneTime: boolean


    // thieving
    thievingTimeRange: string
    thievingFoodRange: string
    thievingEnableDodgyNeckless: boolean
    thievingDodgyNecklessRange: string
    thievingEnableGetRougeSet: boolean
    thievingEnableEquipRougeSet: boolean
    thievingStopUntilRougeSet: boolean

    // range
    rangeTimeRange: string
    rangeLowLevelFoodRange: string
    rangeLowLevelIronArrowRange: string
    rangeLowLevelSteelArrowRange: string
    rangeLowLevelMithrilArrowRange: string
    rangeLowLevelBuyAllItemAtOneTime: boolean
    rangeEnableDefenseTraining: boolean
    rangeMidLevelFoodRange: string
    rangeMidLevelBoneBoltsRange: string


}

const defaultSettings: Settings = {

    // System
    prioritizeFarmRuns: false,
    pauseTimerDuringBreaks: false,
    enablePlayerTracking: true,

    // breaking
    startPlayTime: 600,
    endPlayTime: 1200,
    startBreakTime: 300,
    endBreakTime: 600,

    // quests
    doricsQuest: false,
    blackKnightFortressQuest: false,
    cooksAssistantQuest: false,
    druidicRitualQuest: false,
    goblinDiplomacyQuest: false,
    plagueCityQuest: false,
    priestAndPerilQuest: false,
    restlessGhostQuest: false,
    sheepShearerQuest: false,
    impCatcherQuest: false,
    ernestTheChickenQuest: false,
    theKnightsSwordQuest: false,


    // level
    agilityLevel: 0,
    fishingLevel: 0,
    firemakingLevel: 0,
    cookingLevel: 0,
    craftingLevel: 0,
    woodcuttingLevel: 0,
    miningLevel: 0,
    farmingLevel: 0,
    fletchingLevel: 0,
    strengthLevel: 0,
    attackLevel: 0,
    defenceLevel: 0,
    thievingLevel: 0,
    rangeLevel: 0,

    // crafting
    craftingLeatherRange: "300-400",
    craftingGemRange: "300-400",
    craftingMoltenGlassRange: "1000-2000",
    craftingTimeRange: "3600-7200",

    // farming
    farmingAllotmentRunRange: "3-5",
    farmingHerbRunRange: "3-5",
    farmingFlowerRunRange: "3-5",
    farmingCompostRange: "250-500",
    farmingTeleportTabRange: "25-50",

    // mining
    useQuestToSkipEarlyMiningLevels: false,
    miningMlmBankGems: false,
    miningMlmDepositBox: false,
    miningMlmBuyCoalBagAndStop: false,
    miningMlmBuyGear: true,
    miningMlmUnlockUpstairs: true,
    miningMlmUnlockUpstairsBeforeGear: false,
    miningEnableDragonPickaxe: true,
    miningTimeRange: "3600-7200",

    // cooking
    cookingSardineRange: "300-400",
    cookingTroutRange: "300-400",
    cookingTunaRange: "700-800",
    cookingSwordfishRange: "1000-2000",
    cookingMonkfishRange: "1000-2000",
    cookingSharkRange: "1000-2000",
    cookingAnglerfishRange: "1000-2000",
    cookingSellRawHighLevelFish: false,
    cookingSellCookedHighLevelFish: false,
    cookingTimeRange: "3600-7200",

    // fishing
    fishingFeatherLowLevelRange: "1000-2000",
    fishingFeatherBarbRange: "5000-10000",
    fishingTimeLowLevelRange: "3600-7200",
    fishingTimeBarbLevelRange: "3600-7200",

    // firemaking
    firemakingNormalLogRange: "500-1000",
    firemakingOakLogRange: "500-1000",
    firemakingWillowLogRange: "500-1000",
    firemakingMapleLogRange: "500-1000",
    firemakingRedwoodLogRange: "500-1000",
    firemakingTimeRange: "3600-7200",

    // melee
    meleeBuyAllItemAtOneTime: true,
    meleeTimeRange: "3600-7200",
    meleeBalanceLevels: true,

    // woodcutting
    woodcuttingTimeRange: "3600-7200",
    woodcuttingEnableDragonAxe: true,
    woodcuttingBuyAllAxesAtOneTime: true,

    // thieving
    thievingTimeRange: "3600-7200",
    thievingFoodRange: "450-550",
    thievingEnableDodgyNeckless: true,
    thievingDodgyNecklessRange: "50-100",
    thievingEnableGetRougeSet: false,
    thievingEnableEquipRougeSet: false,
    thievingStopUntilRougeSet: false,

    // range
    rangeTimeRange: "3600-7200",
    rangeLowLevelFoodRange: "50-100",
    rangeLowLevelIronArrowRange: "500-1000",
    rangeLowLevelSteelArrowRange: "1000-2000",
    rangeLowLevelMithrilArrowRange: "1000-2000",
    rangeLowLevelBuyAllItemAtOneTime: true,
    rangeEnableDefenseTraining: false,
    rangeMidLevelBoneBoltsRange: "3000-5000",
    rangeMidLevelFoodRange: "50-100"
}


// Define the shape of the context data
interface SettingContextState {
    settings: Settings;
    saveSettings: () => void;
    updateSetting: (key: string, value: any) => void;
}

// Update the SettingContext
export const SettingContext = createContext<SettingContextState>({
    settings: defaultSettings,
    saveSettings: () => {
    }, // Placeholder function
    updateSetting: () => {
    }, // Placeholder function
});


interface SettingProviderProps {
    children: React.ReactNode;
    profile?: ProfileDTO
}

export const SettingContextProvider: React.FC<SettingProviderProps> = ({children, profile}) => {

    // i want to make an axios call which getting the setting cfrom the DB and merges the defaultSettings with the setting that came back from teh db

    const dispatch = useDispatch();
    const [settings, setSettings] = useState<Settings>(defaultSettings);
    const [isSettingsLoaded, setIsSettingsLoaded] = useState(false); // New state to track if settings are loaded

    // Effect to merge profile settings with default settings
    useEffect(() => {
        if (profile && profile.text) {
            try {
                const profileSettings = JSON.parse(profile.text);
                setSettings(prevSettings => ({...defaultSettings, ...profileSettings}));
                setIsSettingsLoaded(true); // Set to true once the settings are loaded
            } catch (error) {
                console.error('Error parsing profile settings:', error);
            }
        } else {
            setIsSettingsLoaded(true); // Set to true if there's no profile to merge
        }
    }, [profile]); // Dependency array includes 'profile' to run effect when it changes


    const saveSettings = () => {
        if (!profile) {
            toast.error('Error saving data', {
                position: toast.POSITION.TOP_CENTER
            });
            return
        }
        console.log(JSON.stringify(settings))
        dispatch(updateProfile({
            "profileKey": profile?.profileKey ?? "",
            "name": profile?.name ?? "",
            "text": JSON.stringify(settings)
        }));
        toast.success('Successful save data', {
            position: toast.POSITION.TOP_CENTER
        });
    }

    // Updated updateSetting function
    const updateSetting = (key: string, value: any) => {
        if (key in settings) {
            setSettings(prevSettings => ({...prevSettings, [key]: value}));
        } else {
            console.warn(`Invalid setting key: ${key}`);
        }
    };

    if (!isSettingsLoaded) {
        return (
            <StyledLoadingScreen>
                <LoadingImage/>
            </StyledLoadingScreen>)
    }

    return (
        <SettingContext.Provider value={{settings, saveSettings, updateSetting}}>
            {children}
        </SettingContext.Provider>
    );
};