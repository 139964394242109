import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import BooleanComponent from "../../comp/BooleanComponent";
import RangeComponent from "../../comp/RangeComponent";


const ThievingTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <BooleanComponent
                    label="Enable Dodgy Neckless"
                    jsonName="thievingEnableDodgyNeckless"
                    defaultValue={settings.thievingEnableDodgyNeckless}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Enable getting rouge set"
                    jsonName="thievingEnableGetRougeSet"
                    defaultValue={settings.thievingEnableGetRougeSet}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Enable equip rouge set"
                    jsonName="thievingEnableEquipRougeSet"
                    defaultValue={settings.thievingEnableEquipRougeSet}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Stop thieving after rouge set"
                    jsonName="thievingStopUntilRougeSet"
                    defaultValue={settings.thievingStopUntilRougeSet}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Dodgy Neckless Range"
                    jsonName="thievingDodgyNecklessRange"
                    defaultValue={settings.thievingDodgyNecklessRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Food range"
                    jsonName="thievingFoodRange"
                    defaultValue={settings.thievingFoodRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Thieving time (seconds)"
                    jsonName="thievingTimeRange"
                    defaultValue={settings.thievingTimeRange}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default ThievingTab;
