import {Dispatch} from "redux";
import {OverViewSkillProps} from "../../components/commonProps";
import {mockApiData, mockOverviewPanelData} from "./Data";
import {makeRequest} from "../utils/api";
import {FETCH_API_ENDED, fetchApiError} from "../manager/actions";


let mockdata = mockApiData;

export const FETCH_OVERVIEW_STARTED = 'FETCH_OVERVIEW_STARTED';
export const FETCH_OVERVIEW_SUCCESS = 'FETCH_OVERVIEW_SUCCESS';
export const FETCH_OVERVIEW_FAILURE = 'FETCH_OVERVIEW_FAILURE';
export const UPDATE_SEARCH_PAYLOAD = 'UPDATE_SEARCH_PAYLOAD';
export const UPDATE_OVERVIEW_STATE = 'UPDATE_OVERVIEW_STATE';
export const REMOVE_PLAYER_CARD = 'REMOVE_PLAYER_CARD';

type OverviewResponse = OverViewSkillProps[];

export type OverviewSearchPayload = {
    sorting: "DESC" | "ASC",
    page: number,
    size: number,
    sortFilter: "MEMBERSHIP" | "BOT_NAME" | "BAN_STATUS" | "LAST_CHECK_IN",
}

export const fetchOverviewStarted = () => ({
    type: FETCH_OVERVIEW_STARTED,
});

export const fetchOverviewSuccess = (payload: OverviewResponse) => ({
    type: FETCH_OVERVIEW_SUCCESS,
    payload: payload,
});

export const fetchOverviewFailure = () => ({
    type: FETCH_OVERVIEW_FAILURE
});

export const fetchApiEnded = () => ({
    type: FETCH_API_ENDED,
});

export const fetchSearchPayload = (property: keyof OverviewSearchPayload, value: any) => ({
    type: UPDATE_SEARCH_PAYLOAD,
    payload: {
        property: property,
        value: value
    }
})

export const performSearch = (payload: OverviewSearchPayload): any => {
    return async (dispatch: Dispatch) => {
        dispatch(fetchOverviewStarted());
        try {
            const response = await makeRequest(
                'post',
                '/api/v1/player/info',
                {
                    ...payload
                }
            );
            if (response.status !== 200) {
                dispatch(fetchApiError());
                return;
            }
            dispatch(fetchOverviewSuccess(response.data))
        } catch (error) {
            dispatch(fetchApiError());
        }
    }
}

export const addPlayerManual = (payload: string): any => {
    return async (dispatch: Dispatch) => {
        dispatch(fetchOverviewStarted());
        try {
            const response = await makeRequest(
                'post',
                '/api/v1/player',
                {
                    "name": payload
                }
            );
            if (response.status !== 200) {
                dispatch(fetchApiEnded());
                return;
            } else {
                dispatch({
                    "type": UPDATE_OVERVIEW_STATE,
                    "payload": {
                        "property": "hasChanged",
                        "value": true
                    }
                });
                dispatch(fetchApiEnded());
            }
        } catch (error) {
            dispatch(fetchApiEnded());
        }
    }
}

export const deletePlayer = (playerKey: string): any => {
    return async (dispatch: Dispatch) => {
        dispatch(fetchOverviewStarted());
        try {
            const response = await makeRequest(
                'post',
                '/api/v1/player/delete',
                {
                    "playerKey": playerKey
                }
            );
            if (response.status !== 200) {
                dispatch(fetchApiEnded());
                return;
            } else {
                dispatch({
                    "type": REMOVE_PLAYER_CARD,
                    "payload": playerKey,
                });
                dispatch(fetchApiEnded());
            }
        } catch (error) {
            dispatch(fetchApiEnded());
        }
    }
}

export const forceUpdatePlayerHiscore = (playerKey: string): any => {
    return async (dispatch: Dispatch) => {
        dispatch(fetchOverviewStarted());
        try {
            const response = await makeRequest(
                'post',
                '/api/v1/player/forceHiscoreUpdate',
                {
                    "playerKey": playerKey
                }
            );
            if (response.status !== 200) {
                dispatch(fetchApiEnded());
                return;
            } else {
                dispatch({
                    "type": UPDATE_OVERVIEW_STATE,
                    "payload": {
                        "property": "hasChanged",
                        "value": true
                    }
                });
                dispatch(fetchApiEnded());
            }
        } catch (error) {
            dispatch(fetchApiEnded());
        }
    }
}

export const getOverviewPanelData = (): any => {
    return async (dispatch: Dispatch) => {
        dispatch(fetchOverviewStarted());
        dispatch({
            type: UPDATE_OVERVIEW_STATE,
            payload: {
                property: "panel",
                value: mockOverviewPanelData,
            }
        })
        dispatch(fetchOverviewSuccess(mockdata));
    }
}


const fakeApiCall = (playerKey: string) => {
    mockdata = mockdata.filter((item) => {
        return item.playerKey !== playerKey
    })
    return ({
        success: true
    })
}


