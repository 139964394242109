import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import BooleanComponent from "../../comp/BooleanComponent";
import RangeComponent from "../../comp/RangeComponent";


const WoodcuttingTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <BooleanComponent
                    label="Buy all axes at one time"
                    jsonName="woodcuttingBuyAllAxesAtOneTime"
                    defaultValue={settings.woodcuttingBuyAllAxesAtOneTime}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Enable dragon axe"
                    jsonName="woodcuttingEnableDragonAxe"
                    defaultValue={settings.woodcuttingEnableDragonAxe}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Woodcutting time (seconds)"
                    jsonName="woodcuttingTimeRange"
                    defaultValue={settings.woodcuttingTimeRange}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default WoodcuttingTab;
