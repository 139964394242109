import * as React from 'react';
import {ChangeEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/material';

import {validateLoginApi} from "../../store/login/actions";

import {LoadingImage, StyledLoadingScreen} from '../../components/loading/LoadingImage';
import LoginSection from "./LoginSection";
import {useLocation, useNavigate} from "react-router-dom";
import {getItem} from "../../utils/localStorage";
import {styled} from "@mui/system";
import {State} from "../../store/reducers";


const StyledHomePageContainer = styled(Box)({
    minHeight: "90vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
});

const errorBoxStyles = {
    color: "red", marginBottom: "10px"
};

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const {loading, error, data} = useSelector((state: State) => ({
        loading: state.login.loading,
        error: state.login.error,
        data: state?.login?.data
    }));

    const [inputValue, setInputValue] = useState("");

    const handleInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value)
    }

    const handleSubmitButton = () => {
        dispatch(validateLoginApi(inputValue));
    }

    useEffect(() => {
        const apiFarmKey = getItem("apiFarmKey");
        if (data?.success && apiFarmKey != null) {
            navigate("/overview");
        }
    }, [data])

    useEffect(() => {
        const apiFarmKey = getItem("apiFarmKey");
        if (location.pathname == "/" && apiFarmKey != null) {
            navigate("/overview");
        }

    }, [location])

    if (loading) {
        return (
            <StyledLoadingScreen>
                <LoadingImage/>
            </StyledLoadingScreen>
        )
    }

    return (
        <StyledHomePageContainer>
            {error ? <ErrorMessage/> : null}
            <LoginSection
                inputValue={inputValue}
                handleInputValueChange={handleInputValueChange}
                handleSubmitButton={handleSubmitButton}/>

        </StyledHomePageContainer>
    );
};

const ErrorMessage = () => {
    return (
        <Box sx={errorBoxStyles}>
            Invalid Api Farm Key
        </Box>
    )
}

export default Home;