import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import RangeComponent from "../../comp/RangeComponent";


const FarmingTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <RangeComponent
                    label="Allotment Run range"
                    jsonName="farmingAllotmentRunRange"
                    defaultValue={settings.farmingAllotmentRunRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Herb run range"
                    jsonName="farmingHerbRunRange"
                    defaultValue={settings.farmingHerbRunRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Flower Run Range"
                    jsonName="farmingFlowerRunRange"
                    defaultValue={settings.farmingFlowerRunRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Compost Range"
                    jsonName="farmingCompostRange"
                    defaultValue={settings.farmingCompostRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Teleport Tab Range"
                    jsonName="farmingTeleportTabRange"
                    defaultValue={settings.farmingTeleportTabRange}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default FarmingTab;
