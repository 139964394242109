import * as React from "react";
import {useEffect} from "react";
import {Box} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import StatsOverview from './StatsOverview';
import CardContainer from "../../components/page/overview/cardContainer";
import {State} from "../../store/reducers";
import {getOverviewPanelData, performSearch} from "../../store/overview/actions";
import {LoadingImage, StyledLoadingScreen} from "../../components/loading/LoadingImage";
import ControlsSection from "./ControlsSection";
import PagePagination from "../../components/page/overview/pagePagination";


const Overview = () => {
    const dispatch = useDispatch();
    const {overviewPage, loading, searchPayload, hideBotName, hasChanged} = useSelector((state: State) => ({
        overviewPage: state.overview.overviewPage,
        loading: state.overview.loading,
        searchPayload: state.overview.searchPayload,
        hideBotName: state.overview.hideBotName,
        hasChanged: state.overview.hasChanged
    }));


    useEffect(() => {
        dispatch(performSearch(searchPayload));
        dispatch(getOverviewPanelData());
    }, [dispatch, searchPayload, hasChanged]);

    let cardList: any[] = [];
    if (overviewPage != null) {
        cardList = overviewPage.players;
    }

    if (loading) {
        return (
            <StyledLoadingScreen>
                <LoadingImage/>
            </StyledLoadingScreen>)
    }
    return (
        <Box>
            <StatsOverview/>
            <ControlsSection/>
            <CardContainer itemCards={cardList} hide={hideBotName}/>
            <PagePagination/>

        </Box>
    )
}


export default Overview;