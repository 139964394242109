import * as React from 'react';
import {Reducer, useEffect, useReducer, useState} from 'react';
import {
    Box,
    Button,
    Container,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import {useSelector} from "react-redux";
import {State} from "../../../../store/reducers";
import {LoadingImage, StyledLoadingScreen} from "../../../loading/LoadingImage";
import {makeRequest} from "../../../../store/utils/api";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {Action} from "redux";
import Swal from "sweetalert2";
import NewTaskModal from "./modals/newTaskModal";
import {toast} from "react-toastify";
import {PlanDTO, TaskDTO} from "../../../../store/manager/types";

type LocalState = {
    plan: PlanDTO | null,
    hasChange: boolean,
    loading: boolean,
    error: boolean
}

const initialState: LocalState = {
    plan: null,
    loading: false,
    hasChange: false,
    error: false
};

const reducer = (state: LocalState, action: any) => {
    console.log("Action", action);
    switch (action.type) {
        case 'FETCH_DATA_START':
            return {...state, loading: true, error: false};
        case 'FETCH_DATA_SUCCESS':
            return {...state, plan: action.payload, hasChange: false};
        case 'FETCH_DATA_ERROR':
            return {...state, loading: false, error: action.payload};
        case 'FETCH_DATA_END':
            return {...state, loading: false};
        case 'REMOVE_TASK':
            return {
                ...state,
                hasChange: true,
                plan: {
                    ...state.plan,
                    tasks: state.plan?.tasks.filter(task => task.taskKey !== action.payload)
                }
            };
        case 'ADD_TASK':
            let maxLocationId = 0;

            if (state.plan && state.plan.tasks.length > 0) {
                maxLocationId = Math.max(...state.plan.tasks.map(task => task.location ?? 0));
            }

            let newTask = {
                ...action.payload,
                location: maxLocationId + 1,
            };

            return {
                ...state,
                hasChange: true,
                plan: {
                    ...state.plan,
                    tasks: state.plan ? [...state.plan.tasks, newTask] : [newTask]
                }
            };
        case 'UPDATE_TASK':
            return {
                ...state,
                hasChange: true,
                plan: {
                    ...state.plan,
                    tasks: state.plan?.tasks.map(task => {
                        if (task.taskKey === action.payload.taskKey) {
                            return {...task, ...action.payload};
                        }
                        return task;
                    })
                }
            };
        default:
            return state;
    }
};


const fetchDataWrapper = (dispatch: any, planKey: string, managerKey: string) => {
    return async () => {
        console.log("reload");
        if (planKey.length > 0) {
            dispatch({type: 'FETCH_DATA_START'});
            const response =
                await makeRequest("post",
                    "/api/v1/plan/get", {
                        "planKey": planKey,
                        "managerKey": managerKey,
                    });

            if (response.status !== 200) {
                dispatch({type: 'FETCH_DATA_ERROR'});
                return
            } else {
                dispatch({type: 'FETCH_DATA_SUCCESS', payload: response.data});
            }
            dispatch({type: 'FETCH_DATA_END'});
        }
    }
}

type Props = {
    selectedPlanKey: string,
}

const savePlanWrapper = (dispatch: any, state: LocalState, reload: any): any => {
    return async () => {
        console.log("updatePlan", state);
        dispatch({type: 'FETCH_DATA_START'});
        const response =
            await makeRequest("put",
                "/api/v1/plan", {
                    ...state.plan
                });
        if (response.status !== 200) {
            dispatch({type: 'FETCH_DATA_ERROR'});
            return
        } else {
            console.log(response.status);
            toast.success("Successfully updated plan!", {
                position: toast.POSITION.TOP_CENTER
            });
            reload();
        }
    }
}


const removeTaskByKey = (key: string, dispatch: any) => {
    Swal.fire({
        title: "Are you sure to delete this task.",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        icon: "warning",
    }).then((result) => {
        if (result.isConfirmed) {
            dispatch({
                "type": 'REMOVE_TASK',
                "payload": key,
            });
        }
    })
};
const saveNewTaskWrapper = (dispatch: any, state: LocalState) => {
    return (task: TaskDTO) => {
        let updateTask: TaskDTO = {
            ...task,
            // "location": state.plan?.tasks.length
        }
        console.log("new task to be added " + updateTask);
        dispatch({
            "type": 'ADD_TASK',
            "payload": updateTask,
        });
    }
}

const updateNewTaskWrapper = (dispatch: any) => {
    return (task: TaskDTO) => {
        console.log("update task", task);
        dispatch({
            "type": 'UPDATE_TASK',
            "payload": task,
        });
    }
}


const PlanCardEditTable = (props: Props) => {


    const {selectedPlanKey} = props;
    const [state, dispatch] = useReducer<Reducer<LocalState, Action>>(reducer, initialState);

    console.log("here " + JSON.stringify(state.plan));

    const {managerKey} = useSelector((state: State) => ({
        managerKey: state.manager.managerKey
    }))

    const fetchData = fetchDataWrapper(dispatch, selectedPlanKey, managerKey);
    const savePlan = savePlanWrapper(dispatch, state, fetchData);
    const addTask = saveNewTaskWrapper(dispatch, state);
    const updateTask = updateNewTaskWrapper(dispatch);
    const [taskModel, setTaskModal] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [editableTask, setEditableTask] = useState<TaskDTO | null>(null);


    useEffect(() => {
        fetchData();
    }, [selectedPlanKey, dispatch])


    if (state.loading) {
        return (
            <StyledLoadingScreen>
                <LoadingImage/>
            </StyledLoadingScreen>)
    }

    return (
        <Container
            component={Paper}
            sx={{
                p: 3,
                marginTop: "5rem",
                paddingLeft: "2rem",
                paddingRight: "2rem",
            }}
        >
            <TableContainer>
                <Table>
                    <TableHead sx={{th: {fontWeight: "700"}}}>
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Box display="flex" justifyContent="space-between">
                                    <Box>
                                        <Button
                                            variant="contained"
                                            disabled={!state.hasChange}
                                            color={"warning"}
                                            onClick={() => {
                                                savePlan();
                                            }}>
                                            Save
                                        </Button>
                                    </Box>
                                    <Box display="flex" gap="20px">
                                        <Button variant="contained" disabled={!selectedPlanKey}
                                                onClick={() => {
                                                    setTaskModal(true);
                                                    setEditMode(false);
                                                    setEditableTask(null);
                                                }}>
                                            Add New Task
                                        </Button>
                                        {/*<Button variant="contained" disabled={!selectedPlanKey}>*/}
                                        {/*    Break*/}
                                        {/*</Button>*/}
                                    </Box>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">Task Name</TableCell>
                            <TableCell align="center">Min Amount Of Time</TableCell>
                            <TableCell align="center">Max Amount Of Time</TableCell>
                            <TableCell align="center">Edit</TableCell>
                            <TableCell align="center">Action</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state?.plan?.tasks && state.plan.tasks.map((item) => {
                            return (
                                <TableRow key={item.taskKey}>
                                    <TableCell align="center">{item.taskName}</TableCell>
                                    <TableCell align="center">{item.minAmountOfTime}</TableCell>
                                    <TableCell align="center">{item.maxAmountOfTime}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            onClick={() => {
                                                setEditMode(true);
                                                setEditableTask(item);
                                                setTaskModal(true);
                                            }}
                                            sx={{color: "#060229", padding: 0}}
                                        >
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            onClick={() => removeTaskByKey(
                                                item.taskKey ? item.taskKey : "",
                                                dispatch)}
                                            sx={{color: "#060229", padding: 0}}
                                        >
                                            <DeleteOutlineIcon/>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {taskModel &&
                <NewTaskModal open={taskModel}
                              handleSave={editMode ? updateTask : addTask}
                              closeModal={() => {
                                  setTaskModal(!taskModel)
                              }}
                              editMode={editMode}
                              editModeTask={editableTask}


                />}
        </Container>
    )
}

export default PlanCardEditTable;