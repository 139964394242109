import * as React from 'react';
import {useEffect, useState} from 'react';
import MUIRichTextEditor from 'mui-rte';
import {Box} from '@mui/material';
import {toast} from "react-toastify";
import {makeRequest} from "../../../../store/utils/api";
import {useDispatch} from "react-redux";
import {updateNote} from "../../../../store/notes/actions";
import {LoadingImage, StyledLoadingScreen} from "../../../loading/LoadingImage";
import {NoteDTO} from "../../../../store/manager/types";

const getNote = async (noteKey: string | undefined, loadingSetter: any, noteSetter: any) => {
    if (noteKey == null) {
        return;
    }
    loadingSetter(true);
    const response = await makeRequest(
        'post',
        '/api/v1/note/get', {
            "noteKey": noteKey
        }
    );
    if (response.status === 200) {
        noteSetter(response.data)
    }
    loadingSetter(false)
}

type Props = {
    noteKey?: string
}

const NoteEditableSection = (props: Props) => {
    const {noteKey} = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [note, setNote] = useState<NoteDTO | null>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!noteKey) {
            return;
        }
        getNote(noteKey, setLoading, setNote);
    }, [noteKey, setLoading, setNote])


    const handleSave = (data: string) => {
        dispatch(updateNote({
            "noteKey": noteKey ?? "",
            "name": note?.name ?? "",
            "text": data
        }));
        toast.success('Successful save data ', {
            position: toast.POSITION.TOP_CENTER
        });
    }
    if (loading) {
        return (
            <StyledLoadingScreen>
                <LoadingImage/>
            </StyledLoadingScreen>)
    }

    if (!note) {
        return (
            <>
            </>
        );
    }

    return (
        <>
            <Box sx={{p: 4}}>
                <Box bgcolor="#ffffff"
                     sx={{
                         minHeight: "60vh",
                         height: "100%",
                         p: 2,
                         wordBreak: "break-all"
                     }}
                >
                    <MUIRichTextEditor
                        id="my-editor"
                        label="Click here to start typing..."
                        controls={["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo",
                            "redo", "numberList", "bulletList", "quote", "code", "clear", "save"]}
                        onSave={handleSave}
                        defaultValue={note?.text}
                    />
                </Box>
            </Box>
        </>
    );
}
export default NoteEditableSection;