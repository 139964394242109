import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import BooleanComponent from "../../comp/BooleanComponent";


const QuestTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <BooleanComponent
                    label="Dorics Quest"
                    jsonName="doricsQuest"
                    defaultValue={settings.doricsQuest}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Black Knight Fortress Quest"
                    jsonName="blackKnightFortressQuest"
                    defaultValue={settings.blackKnightFortressQuest}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Cooks Assistant Quest"
                    jsonName="cooksAssistantQuest"
                    defaultValue={settings.cooksAssistantQuest}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Goblin Diplomacy Quest"
                    jsonName="goblinDiplomacyQuest"
                    defaultValue={settings.goblinDiplomacyQuest}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Restless Ghost Quest"
                    jsonName="restlessGhostQuest"
                    defaultValue={settings.restlessGhostQuest}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <BooleanComponent
                    label="Sheep Shearer Quest"
                    jsonName="sheepShearerQuest"
                    defaultValue={settings.sheepShearerQuest}
                    changeState={updateSetting}
                />
            </Grid>

            <Grid item>
                <BooleanComponent
                    label="Imp Catcher Quest"
                    jsonName="impCatcherQuest"
                    defaultValue={settings.impCatcherQuest}
                    changeState={updateSetting}
                />
            </Grid>

            <Grid item>
                <BooleanComponent
                    label="Ernest The Chicken Quest"
                    jsonName="ernestTheChickenQuest"
                    defaultValue={settings.ernestTheChickenQuest}
                    changeState={updateSetting}
                />
            </Grid>

            <Grid item>
                <BooleanComponent
                    label="The Knights Sword Quest"
                    jsonName="theKnightsSwordQuest"
                    defaultValue={settings.theKnightsSwordQuest}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default QuestTab;
