import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import RangeComponent from "../../comp/RangeComponent";


const MagicTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <RangeComponent
                    label="Magic time (seconds)"
                    jsonName="magicTimeRange"
                    defaultValue={settings.magicTimeRange}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default MagicTab;
