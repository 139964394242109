import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import RangeComponent from "../../comp/RangeComponent";


const FiremakingTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <RangeComponent
                    label="Normal log tange"
                    jsonName="firemakingNormalLogRange"
                    defaultValue={settings.firemakingNormalLogRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Oak log range"
                    jsonName="firemakingOakLogRange"
                    defaultValue={settings.firemakingOakLogRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Willow log range"
                    jsonName="firemakingWillowLogRange"
                    defaultValue={settings.firemakingWillowLogRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Maple log range"
                    jsonName="firemakingMapleLogRange"
                    defaultValue={settings.firemakingMapleLogRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Redwood log range"
                    jsonName="firemakingRedwoodLogRange"
                    defaultValue={settings.firemakingRedwoodLogRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Firemaking time (seconds)"
                    jsonName="firemakingTimeRange"
                    defaultValue={settings.firemakingTimeRange}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default FiremakingTab;
