import * as React from "react";
import {Box, styled} from "@mui/material";
import {OverViewSkillProps} from "../../../commonProps";
import OverviewCard from "../overviewCards";


const StyledContainer = styled(Box)(({theme}) => ({
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "10px",
    marginTop: "20px",
}))


type Props = {
    itemCards: OverViewSkillProps[],
    hide: boolean,

}

const CardContainer = (props: Props) => {
    const {itemCards, hide} = props;
    return (
        <StyledContainer>
            {
                itemCards?.map((item, index) => {
                    return (
                        <OverviewCard hide={hide} itemData={item} key={item.playerKey}/>
                    )
                })
            }

        </StyledContainer>
    )
}

export default CardContainer;