import {OverviewPage, OverViewSkillProps} from "../../components/commonProps";

import {
    FETCH_OVERVIEW_STARTED,
    FETCH_OVERVIEW_SUCCESS,
    FETCH_OVERVIEW_FAILURE, OverviewSearchPayload, UPDATE_SEARCH_PAYLOAD, UPDATE_OVERVIEW_STATE, REMOVE_PLAYER_CARD
} from "./actions";
import {FETCH_API_ENDED} from "../manager/actions";

export type OverviewState = {
    loading: boolean,
    error: boolean,
    overviewPage: OverviewPage | null,
    searchPayload: OverviewSearchPayload,
    hideBotName: boolean,
    panel: Panel,
    hasChanged: boolean
}

type Panel = {
    totalActiveAccount24: number,
    totalGold24: number,
    totalActiveAccount72: number,
    totalGold72: number,
}

const initialState: OverviewState = {
    loading: false,
    error: false,
    overviewPage: null,
    searchPayload: {
        sorting: "DESC",
        page: 0,
        size: 25,
        sortFilter: "BOT_NAME",
    },
    hideBotName: false,
    panel: {
        totalActiveAccount24: 0,
        totalGold24: 0,
        totalActiveAccount72: 0,
        totalGold72: 0,
    },
    hasChanged: false
};


const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_OVERVIEW_STARTED:
            return {
                ...state,
                loading: true,
                data: []
            };
        case FETCH_OVERVIEW_SUCCESS:
            return {
                ...state,
                loading: false,
                hasChanged: false,
                overviewPage: action.payload,
            };
        case FETCH_OVERVIEW_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: true,
            };
        case UPDATE_SEARCH_PAYLOAD:
            return {
                ...state,
                searchPayload: {
                    ...state.searchPayload,
                    [action.payload.property]: action.payload.value
                }
            };
        case UPDATE_OVERVIEW_STATE:
            return {
                ...state,
                [action.payload.property]: action.payload.value
            };
        case FETCH_API_ENDED:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case REMOVE_PLAYER_CARD:
            return {
                ...state,
                overviewPage: {
                    ...state.overviewPage,
                    players: state?.overviewPage?.players.filter(player => player.playerKey !== action.payload)
                }
            };
        default:
            return state;
    }
};
export default reducer;