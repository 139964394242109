import * as React from 'react';
import {useContext} from 'react';
import {SettingContext} from "../../hooks/settingContext";
import {Grid} from "@mui/material";
import BooleanComponent from "../../comp/BooleanComponent";
import RangeComponent from "../../comp/RangeComponent";


const CraftingTab = () => {
    const {settings, updateSetting} = useContext(SettingContext);

    return (
        <Grid container spacing={3} >
            <Grid item>
                <RangeComponent
                    label="Leather Range"
                    jsonName="craftingLeatherRange"
                    defaultValue={settings.craftingLeatherRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Gem Range"
                    jsonName="craftingGemRange"
                    defaultValue={settings.craftingGemRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Molten glass Range"
                    jsonName="craftingMoltenGlassRange"
                    defaultValue={settings.craftingMoltenGlassRange}
                    changeState={updateSetting}
                />
            </Grid>
            <Grid item>
                <RangeComponent
                    label="Crafting time (seconds)"
                    jsonName="craftingTimeRange"
                    defaultValue={settings.craftingTimeRange}
                    changeState={updateSetting}
                />
            </Grid>
        </Grid>
    )
}

export default CraftingTab;
